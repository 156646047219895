import styled from "styled-components";

import {
  etBlack,
  etBrightLightBlue,
  etGray,
  etGrayBold,
  etGrayDark,
  etWhite,
} from "../../shared/colors";
import { getDiamondImg } from "../../shared/helpers/getDiamondImg";
import { getEarthLines } from "../../shared/helpers/getEarthLines";
import { getImageUonUrl } from "../../shared/helpers/getImageUonUrl";
import { getUONColor } from "../../shared/helpers/getUONColor";
import transparentize from "../../shared/helpers/transparentize";
import { ReserveImageUrls } from "../../shared/models/Uon";

namespace sc {
  export const Wrapper = styled.div`
    box-shadow: 0px 10px 18px 0px rgba(42, 54, 66, 0.2);
  `;

  export const EarthLines = styled.div<{ uonCount: number }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 93%;
    background-image: url(${(props) =>
      getEarthLines(props.uonCount, props.theme.isWebpSupported)});
    z-index: 10;
  `;

  export const Container = styled.div`
    font-family: Roboto;
    width: 328px;
    height: 496px;
    max-width: 328px;
    max-height: 496px;
    background-color: ${etWhite};
    border-radius: 0.5rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    color: ${etGrayDark};
    position: relative;
    overflow: hidden;
    padding: 12px;
  `;

  export const GiftedStateOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${etWhite};
    opacity: 80%;
    padding: 12px;
    z-index: 17;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `;

  export const GiftedStateTop = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 95px;
  `;

  export const GiftedStateLogo = styled.img`
    display: block;
    width: 106px;
    height: 96px;
    max-width: 106px;
    max-height: 96px;
  `;

  export const GiftedStateAmount = styled.div`
    width: 100%;
    text-align: center;
    font-family: Roboto;
    font-weight: bold;
    color: ${etBlack};
    font-size: 30px;
    line-height: 30px;
    height: 30px;
    max-height: 30px;
    letter-spacing: 0;
    margin-top: 8px;
    margin-bottom: 2px;
  `;

  export const GiftedStateExpiry = styled.p`
    width: 100%;
    height: 20px;
    text-align: center;
    font-family: Roboto;
    font-stretch: condensed;
    color: ${etBlack};
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  `;

  export const GiftedStateBottom = styled.div`
    position: absolute;
    height: 32px;
    max-height: 32px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 12px 24px;
  `;

  export const CancelGiftBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 32px;
    max-width: 116px;
    max-height: 32px;
    padding: 4px 20px;
    border-radius: 16px;
    font-size: 14px;
    font-family: Roboto;
    font-stretch: condensed;
    text-transform: uppercase;

    background-color: ${etWhite};
    color: ${etGrayBold};
    border: 1px solid ${etGrayBold};

    &:hover:enabled {
      background-color: ${etBrightLightBlue};
      border-color: ${etBrightLightBlue};
      color: ${etWhite};
    }

    &:disabled {
      box-shadow: none;
      opacity: 0.5;
    }
  `;

  export const ReshareGiftBtn = styled(CancelGiftBtn)`
    background-color: ${etBrightLightBlue};
    color: ${etWhite};
    border: 1px solid ${etBrightLightBlue};

    &:hover:enabled {
      background-color: ${etWhite};
      color: ${etBrightLightBlue};
      border-color: ${etWhite};
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const TopOverLay = styled.div<{ uonCount: number }>`
    position: absolute;
    background-color: ${(props) => getUONColor(props.uonCount)};
    z-index: 2;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 180px;
    opacity: 0.2;
    pointer-events: none;
  `;
  export const MiddleOverLay2 = styled.div<{ uonCount: number }>`
    position: absolute;
    background-color: ${(props) => getUONColor(props.uonCount)};
    z-index: 3;
    left: 12px;
    right: 12px;
    bottom: 12px;
    opacity: 0.2;
    pointer-events: none;
    height: 168px;
  `;
  export const BottomOverLay = styled.div<{ uonCount: number }>`
    position: absolute;
    background-color: ${(props) => getUONColor(props.uonCount)};
    z-index: 3;
    left: 12px;
    right: 12px;
    bottom: 12px;
    opacity: 0.15;
    pointer-events: none;
    height: 84px;
  `;

  export const CardContentContainer = styled.div`
    position: relative;
    display: block;
    overflow: hidden;
  `;

  export const UonReserveContainer = styled.div`
    height: 304px;
  `;

  export const UonReserveBackground = styled.div<{
    backgroundImage: string | ReserveImageUrls;
  }>`
    ${(props) =>
      `background: url(${getImageUonUrl(
        props.backgroundImage,
        props.theme.isWebpSupported,
      )}) center center no-repeat;`}

    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-items: stretch;
    align-items: center;
    justify-content: center;
    background-color: ${etGray};
    height: 304px;
    margin-bottom: 0;
  `;

  export const Coordinate = styled.span`
    font-family: Roboto Condensed;
    font-size: 10px;
    line-height: 10px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  `;

  export const UonReserveTop = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: start;
    width: 125px;
    height: 76px;
    position: absolute;
    z-index: 4;
    top: 15px;
    left: 5px;
    color: ${etWhite};
  `;
  export const UonReserveCount = styled.span<{
    isEndWithOne: boolean;
    uonCount: number;
  }>`
    font-family: Roboto Condensed;
    height: 100%;
    line-height: 48px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    padding: 0px 7px 0 5px;
    font-size: 65px;
    margin-right: ${(props) =>
      props.isEndWithOne && props.uonCount < 1000 ? "-8px" : 0};
  `;
  export const UonReserveTitle = styled.div`
    font-family: Roboto Condensed;
    width: 100px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.03;
  `;
  export const UonReserveTitle1 = styled.span`
    font-family: Roboto Condensed;
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
    margin-top: -5px;

    display: block;
    text-transform: lowercase;
  `;

  export const CardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-family: Roboto Condensed;
    width: 100%;
    height: 112px;
    padding: 10px 12px 12px 12px;

    line-height: 16px;
    font-size: 12px;
    color: ${etGrayDark};
    overflow: hidden;

    z-index: 15;
  `;

  export const CardTitle = styled.span`
    font-weight: bold;
    display: block;
    overflow: hidden;
  `;

  export const CardFooter = styled.footer<{ centered?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
      props.centered ? "center" : "space-between"};
    background: none;
    border-top: 0;
    padding: 12px;
    height: 56px;
    width: 100%;

    z-index: 15;
  `;

  export const CardDonator = styled.div`
    height: 16px;
  `;

  export const CardDonatorHeader = styled.div`
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: inherit;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
  `;

  export const DonatorName = styled.span`
    text-transform: capitalize;
  `;

  export const VisitM2 = styled.a<{
    uonCount: number;
    pointerEvents: "none" | "auto";
  }>`
    cursor: pointer;
    pointer-events: ${(props) => props.pointerEvents};
    width: 134px;
    height: 32px;
    padding: 4px 20px;
    border-radius: 16px;
    z-index: 7;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-family: Roboto Condensed;
    line-height: 22px;
    color: ${etBlack};
    border: 1px solid ${(props) => getUONColor(props.uonCount)};
    background-color: ${(props) =>
      transparentize(getUONColor(props.uonCount), 0.05)};
    :hover {
      background-color: ${(props) => getUONColor(props.uonCount)};
      color: ${etWhite};
      text-decoration: none;
    }
  `;

  export const GiveM2 = styled.button<{
    uonCount: number;
    pointerEvents: "none" | "auto";
  }>`
    pointer-events: ${(props) => props.pointerEvents};
    width: 134px;
    max-width: 134px;
    height: 32px;
    max-height: 32px;
    padding: 4px 20px;
    border-radius: 16px;
    z-index: 7;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Roboto Condensed;
    color: ${(props) => (props.uonCount < 5 ? etWhite : etBlack)};
    background-color: ${(props) => getUONColor(props.uonCount)};

    border: none;
    &:hover:enabled {
      cursor: pointer;
      background-color: ${etWhite};
      color: ${(props) => getUONColor(props.uonCount)};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      box-shadow: none;
      opacity: 0.5;
    }
  `;

  export const VisitYourM2 = styled.a<{
    uonCount: number;
    pointerEvents: "none" | "auto";
  }>`
    font-family: Roboto Condensed;
    pointer-events: ${(props) => props.pointerEvents};
    height: 32px;
    max-height: 32px;
    padding: 4px 20px;
    border-radius: 16px;
    z-index: 7;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    line-height: 26px;
    color: ${(props) => (props.uonCount < 5 ? etWhite : etBlack)};
    background-color: ${(props) => getUONColor(props.uonCount)};

    width: 134px;
    max-width: 134px;

    border: none;
    &:hover {
      cursor: pointer;
      background-color: ${etWhite};
      color: ${(props) => getUONColor(props.uonCount)};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const VisitM2Mobile = styled(VisitM2)``;

  export const Square = styled.div<{ uonCount: number }>`
    display: ${(props) => (props.uonCount > 999 ? "block" : "none")};
    position: absolute;
    z-index: 11;
    top: 12px;
    left: 12px;
    background-image: url(${(props) => getDiamondImg(props.uonCount)});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 304px;
    height: 304px;
  `;

  export const SquareM2 = styled.a`
    width: 280px;
    height: 280px;
    max-width: 280px;
    max-height: 280px;
    position: relative;
    z-index: 11;
  `;

  export const TopRightStar = styled.img`
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    display: block;
    position: absolute;
    z-index: 4;
    bottom: 19px;
    right: 15px;
  `;

  export const TopRightText = styled.p`
    font-family: Roboto Condensed;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    text-transform: uppercase;
    color: ${etWhite};
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    bottom: 46px;
    right: 18px;
    margin: 0;
  `;

  export const IssuedAt = styled.p`
    margin: 0;
    color: ${etWhite};
    font-family: Roboto Condensed;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    position: absolute;
    bottom: 22px;
    right: 42px;
  `;

  export const UonM2Logo = styled.img`
    width: 280px;
    height: 280px;
    max-width: 280px;
    max-height: 280px;
  `;

  export const GiveBtn = styled.div`
    position: absolute;
    left: 126px;
    bottom: 0;
  `;

  export const UonInfoLink = styled.a`
    color: inherit;

    :hover {
      text-decoration: none;
      color: inherit;
    }
  `;
}

export default sc;
