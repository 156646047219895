/* eslint-disable unicorn/consistent-function-scoping */
import {
  ImageResponseDto,
  ImageSource,
  PromotionDto,
  PromotionDtoSeriesLimited,
  PromotionDtoSeriesUnlimited,
  PromotionImageShape,
  PromotionSeriesType,
} from "@earthtoday/contract";
import { action, computed, makeObservable, observable } from "mobx";
import { MouseEvent } from "react";

import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { buildResizedAwsImageRequest } from "../../shared/helpers/buildAwsImageRelated";
import { copyLinkToClipboard } from "../../shared/helpers/copyLinkToClipboard";
import { protectflowStarted } from "../../shared/snowplow";
import { Currency, DonateStore } from "../DonateStore/DonateStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { IModalStore } from "../ModalStore";
import { UserModel } from "../UserModel";

export const PROMOTION_IMAGE_ROUND_SHAPE_WIDTH = 192;
export const PROMOTION_IMAGE_ROUND_SHAPE_HEIGHT = 192;
export const PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH = 304;
export const PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT = 304;
export const UON_CARD_WIDTH = 328;
export const UON_CARD_HEIGHT = 496;
export enum CardItemPromotionLocation {
  PROFILE = "PROFILE",
  GALLERY = "GALLERY",
  DECK = "DECK",
  CARDS = "CARDS",
  HOME = "HOME",
}

export interface CardPromotionBaseModelDependencies {
  pageFeatureCardId?: string;
  onDeleteCard(cardId: string, promotionId?: string): void;
  onCardSizeChanged?(cardId: string, featured: boolean): void;
  onDeletePromotion?(id: string): void;
  onDraftingCardPromotionPublished(cardId: string): void;
}

export class CardPromotionBaseModel {
  @observable promotion: PromotionDto;
  constructor(
    promotionDto: PromotionDto,
    protected userSessionStore: IUserSessionStore,
    protected donateStore: DonateStore,
    public modalStore: IModalStore,
    protected userProfile: UserModel | null,
    public modalDeleteCardStore: IModalDeleteCardStore,
    protected featureFlagStore: FeatureFlaggingData,
    protected location: CardItemPromotionLocation,
  ) {
    this.promotion = promotionDto;
    makeObservable(this);
  }

  imageSource = typeof ImageSource.UON_IMAGE;

  @computed get shouldRenderContextMenu(): boolean {
    return true;
  }

  @computed get isCurator(): boolean {
    return this.userSessionStore.isCurator || false;
  }
  @observable isContextMenuActivated: boolean = false;
  @action.bound onToggleContextMenu(): void {
    this.isContextMenuActivated = !this.isContextMenuActivated;
  }
  @action.bound onCardOverlayClicked(): void {
    this.isContextMenuActivated = false;
  }

  @computed get isOwnerView(): boolean {
    if (
      !this.userSessionStore.user ||
      this.location === CardItemPromotionLocation.GALLERY
    ) {
      return false;
    }

    return this.userSessionStore.user.id === this.promotion.userId;
  }

  @computed get currency(): Currency {
    return this.donateStore.currency;
  }

  @computed get promotionId(): string {
    return this.promotion.id;
  }

  @computed get image(): ImageResponseDto {
    return this.promotion.image;
  }
  @computed get imageShape(): PromotionImageShape {
    return this.promotion.imageShape;
  }

  @observable counter = 0;
  @action.bound updateCounter(n: number) {
    this.counter = n;
  }

  @computed get isCollectButtonDisabled(): boolean {
    if (this.series.type === PromotionSeriesType.UNLIMITED) {
      return false;
    }
    return this.counter >= this.series.maxItems;
  }

  @action.bound onCollectButtonClicked(e: MouseEvent) {
    this.donateStore.prepareCollectableDonation(
      this.promotionId,
      this.series.type,
      this.promotionUonImageUrl,
    );

    protectflowStarted(e);
  }

  @action.bound onLearnMoreButtonClicked() {}

  topRightNote: string = "uon.earth";
  @computed get uonImageDimensions(): { width: number; height: number } {
    return this.imageShape === PromotionImageShape.ROUND
      ? {
          width: PROMOTION_IMAGE_ROUND_SHAPE_WIDTH,
          height: PROMOTION_IMAGE_ROUND_SHAPE_HEIGHT,
        }
      : {
          width: PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH,
          height: PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT,
        };
  }
  @computed get promotionUonImageUrl() {
    if (this.promotion?.image.awsBucket && this.promotion?.image.awsKey) {
      return buildResizedAwsImageRequest(
        this.promotion.image,
        this.uonImageDimensions,
      );
    }

    return this.promotion?.image.url || "";
  }
  @computed get promotionBgImageUrl() {
    if (this.promotion?.image.awsBucket && this.promotion?.image.awsKey) {
      const imageData = {
        ...this.promotion.image,
        extract: undefined, // use original image for background
      };
      return buildResizedAwsImageRequest(imageData, {
        width: UON_CARD_WIDTH,
        height: UON_CARD_HEIGHT,
      });
    }

    return this.promotion?.image.url || "";
  }

  numberOfUons: string = "1";
  @computed get collectionName(): string {
    if (this.promotion.collectionName) return this.promotion.collectionName;

    return this.userProfile
      ? this.userProfile?.fullName.replaceAll(" ", "") + "_uon"
      : "";
  }
  @computed get protectedBy(): string {
    return this.promotion.protectedBy;
  }
  @computed get isFeatured(): boolean {
    return this.promotion.isFeatured;
  }
  @action.bound updateIsFeatured(b: boolean) {
    this.promotion.isFeatured = b;
  }

  @computed get editionType(): PromotionSeriesType {
    return this.promotion.series.type;
  }

  @computed get maxItems(): number | null {
    if (this.promotion.series.type === PromotionSeriesType.UNLIMITED) {
      return null;
    }

    return this.promotion.series.maxItems;
  }
  @computed get series():
    | PromotionDtoSeriesUnlimited
    | PromotionDtoSeriesLimited {
    return this.promotion.series;
  }

  @computed get bottomRightNote(): string {
    if (this.series.type === PromotionSeriesType.UNLIMITED) {
      return "";
    }
    return this.counter + "/" + this.series.maxItems;
  }

  @action.bound updatePromotion(promotion: PromotionDto): void {
    this.promotion = promotion;
  }

  @computed get counterFlapEffectDuration(): number {
    return this.featureFlagStore.flags.counterFlapEffectDuration;
  }

  @observable shareActive = false;
  @action.bound toggleShareActive() {
    this.shareActive = !this.shareActive;
  }

  @action.bound onShareButtonClicked(): void {
    this.isContextMenuActivated = false;
    this.shareActive = true;
  }

  @computed get shareTitle() {
    return "card-general.sharing.title";
  }

  @observable cardSharingError = "";

  @action.bound updateCardSharingError(err: string): void {
    this.cardSharingError = err;
  }

  @action.bound copyCardLinkToClipboard(urlToCopy: string): void {
    copyLinkToClipboard(urlToCopy);
    this.toggleShareActive();
  }
}
