/* eslint-disable class-methods-use-this */
import {
  CardEditableResponseDto,
  ImageResponseDto,
} from "@earthtoday/contract";
import { action, computed, makeObservable, observable } from "mobx";
import { scroller } from "react-scroll";

import { CategoryPrincipleType } from "../../../__generated__/ts-gql/@schema";
import { CardItemLocation } from "../../components/CardItem/CardItem";
import { CardActionTypeOfAction } from "../../components/CardItemAction/CardItemAction";
import {
  ButtonLinkInterface,
  CardItemEditableDriver,
} from "../../components/CardItemEditable/CardItemEditable";
import {
  CARD_PROFILE_REPOST_AVATAR_HEIGHT,
  CARD_PROFILE_REPOST_AVATAR_WIDTH,
} from "../../components/CardProfileRepost/CardProfileRepost.styled";
import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { etIceBlue } from "../../shared/colors";
import {
  CARD_HEIGHT,
  CARD_WIDTH_DOUBLE,
  CARD_WIDTH_SINGLE,
  CARD_WIDTH_TRIPLE,
} from "../../shared/constants";
import { getResizeImageUrl } from "../../shared/helpers/getResizeImageUrl";
import { isBrowser } from "../../shared/helpers/isBrowser";
import {
  CardSize,
  CardState,
  CardTextAlignment,
  CardTextColor,
  CardType,
  MetaData,
} from "../../shared/models/Card";
import { PageStore } from "../CardActionModel/CardActionModel";
import DeckDetailStore from "../DeckDetailStore/DeckDetailStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { parseUrlToActionResource } from "../handleDeepLinkClicked";
import { IModalStore, ModalType } from "../ModalStore";
import { CardItemEditableContextMenuPresenter } from "./CardItemEditableContextMenuPresenter";

export class CardItemEditablePresenter implements CardItemEditableDriver {
  type = "editable";
  @observable public card: CardEditableResponseDto;
  @observable isContextMenuActive: boolean = false;
  constructor(
    card: CardEditableResponseDto,
    public modalStore: IModalStore,
    public deckDetailApi: IDeckDetailApi,
    private pageStore: PageStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    public modalDeleteCardStore: IModalDeleteCardStore,
    private featureFlagging: FeatureFlaggingData,
    private deckDetailStore: DeckDetailStore | null,
    private location?: CardItemLocation,
  ) {
    this.card = card;
    this.cardContextMenuDriver = new CardItemEditableContextMenuPresenter(
      this,
      this.modalStore,
      this.deckDetailApi,
      this.theMessageStore,
      this.userSessionStore,
      this.pageStore,
      this.modalDeleteCardStore,
      this.featureFlagging,
      this.deckDetailStore,
      this.location,
    );
    makeObservable(this);
  }
  toJSON(): CardEditableResponseDto {
    return this.card;
  }
  get layoutType(): undefined {
    return undefined;
  }
  @computed get isCardOwner(): boolean {
    if (!this.userSessionStore.user) return false;

    return this.userSessionStore.user.id === this.card.curator.id;
  }
  get contentType(): CardType.EDITABLE {
    return CardType.EDITABLE;
  }
  get isFeaturedDeck(): boolean {
    return false;
  }
  @computed get deckId(): string | undefined {
    return this.card.deck?.id;
  }
  @computed get id(): string {
    return this.card.id;
  }
  @computed get cardSize(): CardSize {
    return this.card.size.toLowerCase() as CardSize;
  }
  @computed get componentKey(): string {
    return this.id + this.contentType.toString();
  }
  @computed get isEnableOpenCardView(): boolean {
    return (
      this.card.state.toLowerCase() === CardState.PUBLISHED && !this.buttonUrl
    );
  }
  @computed get categoryPrinciple(): CategoryPrincipleType {
    return this.card.principleName;
  }
  @computed get deckName(): string {
    return this.card.deck?.name || "";
  }
  @computed get deckNameWithHashTag(): string {
    return this.card.deck ? `#${this.card.deck.name}` : "";
  }
  @computed get deckPath(): string {
    return this.card.deck ? `/${this.card.deck.path.join("/")}` : "";
  }
  cardContextMenuDriver: CardItemEditableContextMenuPresenter;
  @action.bound toggleContextMenu(): void {
    if (!this.isContextMenuActive) {
      this.cardContextMenuDriver.getCardOrder();
    }
    this.isContextMenuActive = !this.isContextMenuActive;
  }
  @action.bound closeContextMenu(): void {
    this.isContextMenuActive = false;
  }
  @action.bound onContextMenuEllipsisClicked(): void {
    this.toggleContextMenu();
  }
  @action.bound onContextMenuFullscreenOverlayClicked(): void {
    this.isContextMenuActive = false;
  }
  @computed get headline(): string {
    return this.card.headline || "";
  }
  @computed get subhead(): string {
    return this.card.subheadline || "";
  }
  @computed get summary(): string {
    return this.card.summary || "";
  }
  @computed get buttonText(): string {
    return this.card.buttonText || "";
  }
  @computed get buttonUrl(): string {
    return this.card.buttonUrl || "";
  }

  @computed.struct get buttonLinkDetails(): ButtonLinkInterface | null {
    if (!this.buttonUrl) {
      return null;
    }
    const actionResource = parseUrlToActionResource({
      url: this.buttonUrl,
    });

    if (actionResource.type === CardActionTypeOfAction.URL_EXTERNAL) {
      return {
        type: CardActionTypeOfAction.URL_EXTERNAL,
        nextjsHrefPath: actionResource.url,
      };
    }

    return {
      type: CardActionTypeOfAction.URL_INTERNAL,
      nextjsHrefPath: actionResource.nextjsHrefPath,
      nextjsAsPath: actionResource.nextjsAsPath,
    };
  }

  @computed get backgroundImage(): string {
    if (!this.card.bgImage) {
      console.warn("Missing card background image"); // prevent: https://urbn8.slack.com/archives/G0166VBKT18/p1696992582682119?thread_ts=1696990817.364419&cid=G0166VBKT18
      return "";
    }

    const width =
      this.cardSize === CardSize.SINGLE
        ? CARD_WIDTH_SINGLE
        : this.cardSize === CardSize.DOUBLE
        ? CARD_WIDTH_DOUBLE
        : CARD_WIDTH_TRIPLE;

    return getResizeImageUrl(this.card.bgImage as ImageResponseDto, {
      width,
      height: CARD_HEIGHT,
    });
  }
  @computed get backgroundColor(): string {
    return this.card.bgColor || etIceBlue;
  }
  @computed get textAlign(): CardTextAlignment {
    return (
      (this.card.textAlignment as CardTextAlignment) || CardTextAlignment.LEFT
    );
  }
  @computed get textColor(): CardTextColor {
    return (this.card.textColor as CardTextColor) || CardTextColor.BLACK;
  }
  @computed get metadata(): MetaData {
    return {
      starred: false,
    };
  }
  @computed get curatorAvatarUrl(): string {
    return getResizeImageUrl(
      (this.card.curator.image as ImageResponseDto) || null,
      {
        width: CARD_PROFILE_REPOST_AVATAR_WIDTH,
        height: CARD_PROFILE_REPOST_AVATAR_HEIGHT,
      },
    );
  }
  @action.bound updateStarCard(starred: boolean) {}

  @computed get publishedDate(): string {
    return this.card.published
      ? new Date(this.card.published).toISOString()
      : "";
  }

  getBaseUrl() {
    if (!isBrowser()) {
      return "";
    }

    const baseUrl = window.location.origin + window.location.pathname; // Combines origin and pathname to exclude query parameters
    return baseUrl;
  }

  @observable shareActive = false;
  @action.bound onShareButtonClicked(options?: {
    duration: number;
    smooth: boolean;
    offset: number;
  }): void {
    this.toggleShareActive();

    if (this.shareActive) {
      this.scrollToShareContext(options);
    }
  }
  @action.bound toggleShareActive(): void {
    this.shareActive = !this.shareActive;
  }
  @computed get pathSharing(): string {
    return `${this.deckPath}/${this.card.id}`;
  }
  get shareTitle(): string {
    return "card-general.sharing.title";
  }

  @computed get newestId(): string {
    return this.card.id || "";
  }

  scrollToShareContext(options?: {
    duration: number;
    smooth: boolean;
    offset: number;
  }): void {
    scroller.scrollTo("cardShare", options);
  }

  @action.bound openModal(modalType: ModalType): void {
    this.modalStore.openModal(modalType);
  }
}
