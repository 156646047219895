import { ImageResponseDto } from "@earthtoday/contract";

import { baseImageUrl } from "../env";
export function buildResizedAwsImageRequest(
  image: Pick<
    ImageResponseDto,
    "awsKey" | "awsBucket" | "isTransparent" | "extract"
  > & {
    url?: string;
  },
  resizeOptions?: {
    width: number;
    height: number;
    fit?: "cover" | "contain" | "fill" | "inside" | "outside";
    background?: object;
  },
  baseUrl?: string,
): string {
  const { awsBucket: bucket, awsKey: key, isTransparent, extract, url } = image;

  if (!bucket || !key) {
    return url || "";
  }

  const resize = {
    ...resizeOptions,
    fit: resizeOptions?.fit ?? "cover",
  };

  const urlKey = btoa(
    JSON.stringify({
      bucket,
      key,
      outputFormat: isTransparent === false ? "jpeg" : undefined,
      edits: {
        rotate: null, // Prevent wrong orientation
        extract: extract || undefined, // should not be null
        resize,
      },
    }),
  );

  return baseUrl ? `${baseUrl}/${urlKey}` : `${baseImageUrl}/${urlKey}`;
}
