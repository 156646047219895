/* eslint-disable class-methods-use-this */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { ImageResponseDto } from "@earthtoday/contract";
import { action, computed, flow, makeObservable } from "mobx";
import { CancellablePromise } from "mobx/dist/internal";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { IModalDeleteCardStore } from "../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import {
  PROFILE_AVATAR_HEIGHT_DEFAULT,
  PROFILE_AVATAR_WIDTH_DEFAULT,
} from "../components/TheNavbarControllers/TheNavbarControllers.styled";
import { IDeckDetailApi } from "../shared/apis/DeckDetailApi";
import { getResizeImageUrl } from "../shared/helpers/getResizeImageUrl";
import { showMessageEmailNotVerified } from "../shared/helpers/showMessageEmailNotVerified";
import { translateAPIError } from "../shared/helpers/translateApiError";
import {
  CardArticleLayoutType,
  CardRegular,
  CardSize,
  CardState,
  CardType,
  MasterLinkType,
  RepostContentData,
} from "../shared/models/Card";
import { Deck } from "../shared/models/Deck";
import { AutoPlayDeckStore, AutoplayStatus } from "./AutoplayDeckStore";
import { CardActionModel } from "./CardActionModel/CardActionModel";
import { CardBaseModel } from "./CardBaseModel";
import { CardDeckHeaderModel } from "./CardDeckHeaderModel";
import CardDeckOverviewModel, { DeckSize } from "./CardDeckOverviewModel";
import { CardInfoModel } from "./CardInfoModel/CardInfoModel";
import { CardItemEditablePresenter } from "./CardItemEditablePresenter/CardItemEditablePresenter";
import { CardItemEditableRepostPresenter } from "./CardItemEditablePresenter/CardItemEditableRepostPresenter";
import { CardProfilePageModel } from "./CardProfilePageModel";
import { CardProfileRepostModel } from "./CardProfileRepostModel";
import { CardPromotionRepostModel } from "./CardPromotionModel/CardPromotionRepostModel";
import { CardRegularModel } from "./CardRegularModel";
import { ICreateCardType } from "./CreateCardStore/CreateCardStore";
import { DeckDetailCardItem } from "./DeckDetailStore/DeckDetailStore";
import { IDeviceStore } from "./DeviceStore";
import { FeatureFlaggingData } from "./FeatureFlaggingStore";
import { IFollowingRequest } from "./FollowingStore";
import { IModalStore } from "./ModalStore";
import { CardDraftEditData } from "./ProfilePagePresenter";

export function isCardDeckRepostModel(
  item:
    | CardDeckRepostModel
    | CardRegularModel
    | CardProfilePageModel
    | CardProfileRepostModel
    | CardActionModel
    | CardInfoModel
    | CardPromotionRepostModel
    | CardItemEditablePresenter
    | CardItemEditableRepostPresenter,
): item is CardDeckRepostModel {
  return item.contentType === CardType.DECK_REPOST;
}

export class CardDeckRepostModel extends CardBaseModel {
  type = "deck_repost";
  constructor(
    card: CardRegular,
    public modalStore: IModalStore,
    public theMessageStore: ITheMessageStore,
    public userSessionStore: IUserSessionStore,
    public modalDeleteCardStore: IModalDeleteCardStore,
    private autoplayDeckStore: AutoPlayDeckStore,
    public pageStore:
      | ({
          cards: Array<DeckDetailCardItem>;
          updateStarCard(id: string, starred: boolean): void;
          onDeleteCard(
            cardId: string,
            promotionId?: string,
            isAutoplayedCard?: boolean,
          ): void;
          toggleFollowing(data: IFollowingRequest): void;
          onCardSizeChanged(cardId: string, size: CardSize): void;
          onChangeCardLayout(
            id: string,
            layoutType: CardArticleLayoutType,
          ): void;
          updateFeatureCard(cardId: string, featured: boolean): void;
          submitAutoplay(
            sourceDeckId: string,
            targetDeckId: string,
            numberOfCards: number,
            status: AutoplayStatus,
          ): void;
          deckDetail: CardDeckHeaderModel | null;
          isCurrentUser: boolean;
        } & {
          onEditCard?: (
            cardId: string,
            data: CardDraftEditData,
          ) => CancellablePromise<boolean>;
          isLoggedinUser?: boolean;
          updateOrderOfCards?: (cardIds: string[]) => void;
          onOpenLoginModal?: () => void;
        })
      | null,
    public featureFlagging: FeatureFlaggingData,
    public deviceStore: IDeviceStore,
    private deckDetailApi: IDeckDetailApi,
  ) {
    super(
      card,
      userSessionStore,
      featureFlagging,
      deviceStore,
      pageStore,
      modalStore,
    );
    makeObservable(this);
  }

  @action.bound
  onStarCard(): void {
    if (!this.isUserLoggedin) {
      if (
        this.featureFlagging.flags.enableLoginWithQRCode &&
        this.pageStore &&
        this.pageStore.onOpenLoginModal
      ) {
        this.pageStore.onOpenLoginModal();
      } else {
        this.modalStore.openModal("loginForm");
      }
      return;
    }

    if (!this.userSessionStore.user?.emailAddressVerified) {
      showMessageEmailNotVerified(this.theMessageStore, this.userSessionStore);
      return;
    }

    if (this.card && this.card.id) {
      this.pageStore?.updateStarCard(this.card.id, !this.metadata.starred);
    }
  }

  @action.bound onResizeCardDouble = flow(function* onResizeCardDouble(
    this: CardDeckRepostModel,
  ) {});

  @action.bound onResizeCardSingle = flow(function* onResizeCardSingle(
    this: CardDeckRepostModel,
  ) {});

  @action.bound onChangeCardLayoutEditorial = flow(
    function* onChangeCardLayoutEditorial(this: CardDeckRepostModel) {},
  );

  @action.bound onChangeCardLayoutPhoto = flow(
    function* onChangeCardLayoutPhoto(this: CardDeckRepostModel) {},
  );

  @action.bound onChangeCardLayoutText = flow(function* onChangeCardLayoutText(
    this: CardDeckRepostModel,
  ) {});

  @action.bound updateCardSize(): void {}

  @action.bound updateChageLayout(): void {}

  @action.bound onPinFeaturedCard = flow(function* onPinFeaturedCard(
    this: CardDeckRepostModel,
  ) {});

  @action.bound onOpenModalCardEdit(): void {
    if (!this.card) return;
    if (!this.cardContent) return;
    const principles = this.card.categoryPrinciples || [];

    this.modalStore.openModal({
      name: "cardCreate",
      driver: {
        cardWhy: this.card.comment || "",
        categoryName: principles.length > 0 ? principles[0] : "",
        deckId: this.card.deck?.id || null,
        url: this.cardContent?.content?.url || "",
        cardId: this.card.id || "",
        actionType:
          this.card.content?.type === MasterLinkType.LINK_REPOST
            ? CardType.LINK_REPOST
            : "draft",
        stateCard:
          this.card.state === CardState.DRAFTING ? CardState.DRAFTING : "",
        cardLayout: this.layoutType || CardArticleLayoutType.EDITORIAL,
        cardType: ICreateCardType.DECK_REPOST,
        deckRepost:
          this.card.contentType === CardType.DECK_REPOST
            ? this.card.deckRepost
            : undefined,
      },
    });
  }

  @action.bound cardRepostIdUpdate(): void {}

  @action.bound updateCardDelete(): void {
    this.modalDeleteCardStore.updateCardDelete(this);
  }

  @action.bound onDeleteCard(): void {
    this.pageStore?.onDeleteCard(
      this.card.id,
      undefined,
      this.isAutoplayedCard,
    );
  }

  @action.bound updateStarCard(starred: boolean): void {
    this.metadata.starred = starred;
  }

  @action.bound onEditCard(): void {
    if (!this.card) return;
    if (!this.pageStore?.onEditCard) return;

    const principles = this.card.categoryPrinciples || [];

    this.pageStore.onEditCard(this.card.id || "", {
      principleName: principles[0],
      comment: this.card.comment || "",
      content: {
        type: this.card.content?.type || CardType.DECK_REPOST.toLowerCase(),
        url: this.cardContent?.content?.url || "",
      },
      deck: this.card.deck?.id,
      state: "published",
    });
  }

  @computed get curatorName(): string {
    return this.isRepostCardByEditorInDeckCategory
      ? (this.card?.content as RepostContentData)?.original?.curator.name || ""
      : this.card.curator?.name || "";
  }

  @computed get vanityName(): string {
    if (this.card.contentType !== CardType.DECK_REPOST) {
      return "";
    }
    return this.card.deckRepost.data.curator.vanityName || "";
  }

  @computed get deckName(): string {
    return this.isRepostCardByEditorInDeckCategory
      ? (this.card?.content as RepostContentData)?.original?.deck?.name || ""
      : this.card.deck?.name || "";
  }

  @computed get deckPathString(): string {
    if (!this.isRepostCardByEditorInDeckCategory) {
      return this.card.deck?.path?.join("/") || "";
    }
    return (
      (this.card?.content as RepostContentData)?.original?.deck?.path?.join(
        "/",
      ) || ""
    );
  }

  @computed get isLoggedinUser(): boolean {
    return this.card.curator.currentUser;
  }

  @computed get isCardSingleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.SINGLE;
  }

  @computed get isCardDoubleOptionActive(): boolean {
    return !this.card.featured && this.card.size === CardSize.DOUBLE;
  }

  @computed get isCardHeaderOptionActive(): boolean {
    return this.card.featured || false;
  }

  @computed get newestId(): string {
    return this.card.id || "";
  }

  @computed get isFeaturedDeck(): boolean {
    return this.card.featured || false;
  }

  @computed get comment(): string {
    return this.card.comment || "";
  }

  @computed get deckPath(): string[] {
    return this.card.deck?.path || [];
  }

  @computed get pathSharing(): string {
    return `${this.deckPath.join("/")}/${this.card.id}`;
  }

  @computed get deck(): Deck | null {
    return this.card.deck || null;
  }

  @computed get isCardDrafting(): boolean {
    return this.card.state === CardState.DRAFTING;
  }

  @computed get id(): string {
    return this.card.id;
  }

  @computed get deckRepostModel(): CardDeckOverviewModel {
    const emptyFnc = () => {};

    if (this.card.contentType !== CardType.DECK_REPOST) {
      throw new Error("Expect content type is DECK_REPOST");
    }

    return new CardDeckOverviewModel(
      this.modalStore,
      {
        onClose: emptyFnc,
        onDelete: emptyFnc,
        updateDeckDelete: emptyFnc,
      }, // just display as a deck
      this.autoplayDeckStore,
      this.theMessageStore,
      this.userSessionStore,
      this.pageStore,
      this.card.deckRepost.data,
      this.featureFlagging,
      this.deckDetailApi,
      DeckSize.DisplayInDeck,
    );
  }

  @computed get logo(): string {
    if (this.card.contentType !== CardType.DECK_REPOST) {
      return "";
    }
    return getResizeImageUrl(
      this.card.deckRepost.data.curator.image as ImageResponseDto,
      {
        width: PROFILE_AVATAR_WIDTH_DEFAULT,
        height: PROFILE_AVATAR_HEIGHT_DEFAULT,
      },
    );
  }

  @computed get deckAuthor(): string {
    if (this.card.contentType !== CardType.DECK_REPOST) {
      return "";
    }
    return this.card.deckRepost.data.curator.name || "";
  }

  @computed get isAutoplayedCard(): boolean {
    return false;
  }

  @computed get isPersonalizeCard(): boolean {
    return false;
  }

  @computed get shouldRenderCardOrder(): boolean {
    if (this.isFeaturedDeck || !this.pageStore?.isLoggedinUser) {
      return false;
    }
    return this.featureFlagging.flags.enableSortCardInDeck;
  }

  @action.bound onCardOrderBlured = flow(function* onCardOrderBlured(
    this: CardDeckRepostModel,
  ) {
    if (
      this.sortNumberInputError ||
      !this.userSessionStore.user ||
      !this.pageStore?.isLoggedinUser
    ) {
      return;
    }

    try {
      const userVanityName = this.userSessionStore.user.vanityName;
      const deckId = this.card.deck?.id || "";
      const response = yield* toFlowGeneratorFunction(
        this.deckDetailApi.updateCardOrder,
      )(userVanityName, deckId, this.card.id, Number(this.cardOrderInput));
      const cardIds = response.data.map((item) => item.id.toString());

      if (this.pageStore && this.pageStore.updateOrderOfCards) {
        this.pageStore.updateOrderOfCards(cardIds);
      }
      this.closeContextMenu();
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
}
