/* eslint-disable import/namespace */
import {
  CardPromotionRepostResponseDto,
  CardStatus,
  PromotionDtoSeriesLimited,
  PromotionDtoSeriesUnlimited,
  ReserveBasicInfo,
} from "@earthtoday/contract";
import { action, computed, flow, makeObservable } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { ICreateCardApi } from "../../shared/apis/CreateCardApi";
import { IProfileApi } from "../../shared/apis/ProfileApi";
import { CardSize, CardType, MetaData } from "../../shared/models/Card";
import { DonateStore } from "../DonateStore/DonateStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { IModalStore } from "../ModalStore";
import { ProfilePagePresenter } from "../ProfilePagePresenter";
import { UserModel } from "../UserModel";
import {
  CardItemPromotionLocation,
  CardPromotionBaseModel,
  CardPromotionBaseModelDependencies,
} from "./CardPromotionBaseModel";
import { CardPromotionContextMenuStore } from "./CardPromotionContextMenuStore";

export interface CardPromotionRepostPresenterDependencies
  extends CardPromotionBaseModelDependencies {
  onCardOrderInputChanged(cardId: string, order: number): void;

  getCardOrder(cardId: string): number | null;
}

export class CardPromotionRepostModel extends CardPromotionBaseModel {
  constructor(
    promotionRepostDto: CardPromotionRepostResponseDto,
    public userSessionStore: IUserSessionStore,
    public donateStore: DonateStore,
    public modalStore: IModalStore,
    public userProfile: UserModel | null,
    public dependencies: CardPromotionRepostPresenterDependencies,
    public modalDeleteCardStore: IModalDeleteCardStore,
    public featureFlagStore: FeatureFlaggingData,
    public createCardAPI: ICreateCardApi,
    private profileApi: IProfileApi,
    private profilePagePresenter: ProfilePagePresenter | null,
    public theMessageStore: ITheMessageStore,
    public location: CardItemPromotionLocation,
  ) {
    super(
      promotionRepostDto.promotion,
      userSessionStore,
      donateStore,
      modalStore,
      userProfile,
      modalDeleteCardStore,
      featureFlagStore,
      location,
    );

    this.card = promotionRepostDto;
    this.contextMenuDriver = new CardPromotionContextMenuStore(
      this,
      this.userSessionStore,
      this.userProfile,
      this.modalStore,
      this.createCardAPI,
      this.dependencies,
      this.theMessageStore,
      this.location,
      this.profileApi,
      this.profilePagePresenter,
      featureFlagStore,
    );
    makeObservable(this);
  }

  card: CardPromotionRepostResponseDto;

  toJSON(): CardPromotionRepostResponseDto {
    return this.card;
  }

  isFeaturedDeck: boolean = false;
  layoutType = undefined;
  contentType: CardType.PROMOTION_REPOST = CardType.PROMOTION_REPOST;

  get id(): string {
    return this.card.id.toString();
  }

  @computed get componentKey(): string {
    return this.card.id.toString() + this.contentType.toString();
  }

  @computed get deckName(): string {
    return this.card.deck?.name || "";
  }

  @computed get deckId(): string {
    return this.card.deck?.id || "";
  }

  contextMenuDriver: CardPromotionContextMenuStore;

  @action.bound onEllipsisIconClicked(): void {
    if (!this.isContextMenuActivated) {
      const cardOrder = this.dependencies.getCardOrder(this.id);
      this.contextMenuDriver.cardOrder = cardOrder;
    }

    this.onToggleContextMenu();
  }

  @computed get cardSize(): CardSize {
    return this.dependencies?.pageFeatureCardId === this.id
      ? CardSize.HEADER
      : CardSize.SINGLE;
  }
  @computed get isCardDrafting(): boolean {
    return this.card.state === CardStatus.DRAFTING;
  }
  get isCollectButtonDisabled(): boolean {
    return this.isCardDrafting;
  }

  @action.bound onCardSizeChanged = flow(function* (
    this: CardPromotionRepostModel,
    size: CardSize,
  ) {
    if (size !== CardSize.HEADER && size !== CardSize.SINGLE) {
      return;
    }

    if (!this.dependencies?.onCardSizeChanged) {
      return;
    }

    if (size === this.cardSize) {
      return;
    }

    yield* toFlowGeneratorFunction(this.dependencies.onCardSizeChanged)(
      this.id,
      size === CardSize.HEADER,
    );
  });
  @action.bound onDelete = flow(function* (this: CardPromotionRepostModel) {
    if (!this.dependencies) {
      return;
    }

    yield* toFlowGeneratorFunction(this.dependencies.onDeleteCard)(
      this.id,
      this.promotionId,
    );
    yield* toFlowGeneratorFunction(this.modalStore.closeLazyModal)();
  });
  @computed get metadata(): MetaData {
    return {
      starred: false,
    };
  }
  updateFeaturedForProfile() {}
  updateStarCard() {}

  zIndex = 0;

  @computed get collectibleForReserve(): ReserveBasicInfo | undefined {
    return this.promotion.collectibleForReserve;
  }

  @computed get promotionSerie():
    | PromotionDtoSeriesLimited
    | PromotionDtoSeriesUnlimited {
    return this.promotion.series;
  }

  @computed get pathSharing(): string {
    return `${this.promotion.userId}?promoName=${this.promotion.collectionName}`;
  }
  @computed get isShareButtonDisabled(): boolean {
    return !this.featureFlagStore.flags.enableSharingPromoCard;
  }
}
