export const DECK_NAME_MAX_LENGTH: number = 27;
export const DECK_DESCRIPTION_MAX_LENGTH: number = 180;
export const DECK_NAME_MAX_LENGTH_SINGLE_SIZE: number = 26;
export const CARD_WHY_MAX_LENGTH: number = 520;
export const DATE_FORMAT_MMMM_DD_YYYY: string = "MMMM dd, yyyy";
export const DATE_FORMAT_DD_MMMM_YYYY: string = "dd MMMM yyyy";
export const DATE_FORMAT_DD_MM_YYYY: string = "dd/MM/yyyy";
export const DATE_FORMAT_MMMM_DD: string = "MMMM dd";
export const DATE_FORMAT_DD_MMM: string = "dd MMM";
export const m2Price = 1.2;
export const priceForVAT = 0.2;
export const CARD_DECK_PREVIEW_IMAGES_LIMIT: number = 6;
export const QUOTE_MAX_LENGTH = 80;
export const CARD_MUCSIC_WHY_COMMENT_LIMIT: number = 80;
export const CARD_SOURCE_MAX_LENGTH = 24;
export const ORIGINAL_MAX_NUMBER_OF_COMMENTS = 2;
export const ORIGINAL_MAX_NUMBER_OF_COMMENTS_ON_MOBILE = 4;
export const MAX_NUMBER_OF_COMMENTS_TO_RENDER = 4;
export const DECK_NAME_REGEX = /^#?[\sA-Za-z]+/;
export const UPTOTHOUSAND: number = 999;
export const UPTOMILLION: number = 999999;
export const UPTOBILLION: number = 999999999;
export const LIMIT_ORG_NAME: number = 280;
export const LIMIT_TEXT_NAME: number = 18;
export const MAIN_DECK_GROUP_NAME = "Sustainable Development Goals";
export const MOVED_PERMANENTLY = "MOVED_PERMANENTLY";
export const MOVED_PERMANENTLY_CODE = 301;
export const EDITOR_VANITY_NAME = "earthtoday";
export const TODAY_DECK_NAME = "today";

export const IMAGE_MAX_SIZE: number = 2 * 1024 * 1024;
export const GROUP_IMAGE_MAX_SIZE: number = 2 * 1024 * 1024;

// CAMPAIGN ZONE
export const CAMPAIGN_NAME_MAX_LENGTH: number = 27;
export const CAMPAIGN_PLEDGE_MAX_LENGTH: number = 27;
export const CAMPAIGN_GOAL_MAX_SIZE: number = 1000000;
export const CAMPAIGN_DESC_MAX_SIZE: number = 180;
export const CAMPAIGN_PREVIEW_MAX_SIZE: number = 90;
export const CAMPAIGN_ASSET_PREVIEW_TEXT_MAX_LENGTH: number = 90;

export const CAMPAIGN_DEFAULT_TITLE_MAX_LENGTH: number = 90;

export const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png"];

export const NOT_ALLOWED_MIME_TYPE = "image/webp";

export const USER_CLOSE_OVERLAY = "USER_CLOSE_OVERLAY";

export const USER_CLOSE_CARD_INTRO = "USER_CLOSE_CARD_INTRO";
export const USER_ACCEPT_COOKIE_CONSENT = "USER_ACCEPT_COOKIE_CONSENT";
export const AUTO_ACCEPT_COOKIE_CONSENT = "AUTO_ACCEPT_COOKIE_CONSENT";

export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN";

export const COOKIE_CONSENT = "cookieConsent";
export const COOKIE_CONSENT_CREATEDAT = "cookieConsentCreatedAt";
export const MAX_CARDS_IN_DECK = 52;
export const IMAGE_UPLOADS = "IMAGE_UPLOADS";
export const WHAT_IS_NEW_DECK_ID = "731451409555296257";
export const PAGE_SIZE_LEADERBOARD = 30;
export const PAGE_SIZE_DEFAULT = 50;

export const MAX_SIZE_IMAGE_CAMPAIGN_DEFAULT: number = 2 * 1024 * 1024;
export const GROUP_NAME_MAX_LENGTH: number = 36;
export const GROUP_VANITY_NAME_MAX_LENGTH: number = 280;
export const GROUP_DESC_MAX_SIZE: number = 180;
export const ALPHANUMERIC_REGEX = /(^[\dA-Za-z]*$)/;
export const NAME_REGEX = /(^[\u0020-\u007FÀ-ū]|[^\u0000-\u007F]*$)/g;
export const POSITIVE_INTEGER_REGEX = new RegExp("^[1-9]\\d*$");

export const POSITIVE_INTEGER_REGEX_GIVING = new RegExp("^\\d\\d*$");

export const LIST_PREFIX_KEYS = {
  leaderboardRange: "leaderboard-range-",
  uonCard: "uon-card-",
};

export const FEATURE_FLAGS_SESSION_KEY = "overrideFeatureFlagsJson";

export const EARTH_TODAY_VANITY_NAME = "earthtoday";

export const BATCH_NAME_MAX_LENGTH = 120;
export const DIVIDER_CHAR = "-";
export const PREFIX_SUBSCRIBE_CODE = "S";
export const GIFT_AMOUNT_LIMIT = 10000;
export const SUBSCRIBE_UON_PER_CODE = 1;
export const SUBSCRIBE_UON_SIZE = SUBSCRIBE_UON_PER_CODE * 12;
export const SUBSCRIBE_PRICE = m2Price * SUBSCRIBE_UON_SIZE;

export const CURRENT_YEAR = new Date().getFullYear();
export const LAST_ACTIVE_AT = "lastActiveAt";
export const USER_SESSION_ID = "userSessionId";
export const USER_SESSION_EXPIRE_TIME: number = 1000 * 60 * 60; // 1 hour
export const BROWSING_SESSION_ID = "browsingSessionId";
export const BROWSING_SESSION_EXPIRE_TIME: number = 1000 * 60 * 60; // 1 hour
export const ACTION_CARD_BUTTON_TEXT_MAX_LENGTH: number = 32;
export const SESSION_ID_ON_RELOAD = "sessionIdOnReload";
export const OVERLAY_CLOSED_AT_SESSION_ID = "overlayClosedAtSessionId";
export const CARD_HEADLINE_MAX_LENGTH = 90;
export const CARD_SUBHEAD_MAX_LENGTH = 90;
export const CARD_SUMMARY_MAX_LENGTH = 90;
export const CARD_BUTTON_TEXT_MAX_LENGTH = 28;
export const OPEN_CARD_SECTION_IMAGE_HEIGHT_DESKTOP = 350;
export const OPEN_CARD_SECTION_IMAGE_HEIGHT_MOBILE = 250;
export const REACT_SCROLL_DURATION = 500;
export const NAVBAR_TOP_HEIGHT = 64;
export const CARD_WIDTH_SINGLE = 328;
export const CARD_WIDTH_DOUBLE = 680;
export const CARD_WIDTH_TRIPLE = 1032;
export const CARD_HEIGHT = 496;
export const GRID_GAP = 24;
