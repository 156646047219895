/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { action, computed, flow, makeObservable, observable } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import {
  ActionResource,
  CardActionTypeOfAction,
  CardItemActionProperties,
} from "../../components/CardItemAction/CardItemAction";
import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { getCardDimensionsByCardSize } from "../../shared/helpers/getCardDimensionsByCardSize";
import { getResizeImageUrl } from "../../shared/helpers/getResizeImageUrl";
import {
  CardAction,
  CardSize,
  CardState,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import CardDetailPresenter from "../CardDetailPresenter";
import DeckDetailStore from "../DeckDetailStore/DeckDetailStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { handleDeepLinkClicked } from "../handleDeepLinkClicked";
import { IModalStore } from "../ModalStore";
import { ProfilePagePresenter } from "../ProfilePagePresenter";
import UONCardDetailPresenter from "../UonCardDetailPresenter/UonCardDetailPresenter";
import { CardActionContextMenuStore } from "./CardActionContextMenuStore";

export type PageStore =
  | DeckDetailStore
  | ProfilePagePresenter
  | UONCardDetailPresenter
  | CardDetailPresenter
  | null;
export class CardActionModel implements CardItemActionProperties {
  type = "action";
  @observable public card: CardAction;
  @observable isContextMenuActive: boolean = false;
  constructor(
    card: CardAction,
    private modalStore: IModalStore,
    private userSessionStore: IUserSessionStore,
    public deckDetailApi: IDeckDetailApi,
    public pageStore: PageStore,
    private theMessageStore: ITheMessageStore,
    private modalDeleteCardStore: IModalDeleteCardStore,
    private featureFlagging: FeatureFlaggingData,
    private deckDetailStore: DeckDetailStore | null,
    public onCardPublishedSuccessfully?: () => void,
  ) {
    this.card = card;
    this.cardContextMenuDriver = new CardActionContextMenuStore(
      this,
      this.modalStore,
      this.userSessionStore,
      this.deckDetailApi,
      this.theMessageStore,
      this.modalDeleteCardStore,
      this.pageStore,
      this.featureFlagging,
      this.deckDetailStore,
    );
    makeObservable(this);
  }
  toJSON(): CardAction {
    return this.card;
  }
  get layoutType(): undefined {
    return undefined;
  }
  @computed get id(): string {
    return this.card.id;
  }
  @computed get componentKey(): string {
    return this.id + this.contentType.toString();
  }
  get contentType(): CardType.ACTION {
    return CardType.ACTION;
  }
  get isFeaturedDeck(): boolean {
    return false;
  }
  get isCardDrafting(): boolean {
    return false;
  }
  @computed get cardState(): CardState {
    return this.card.state;
  }
  @computed get cardSize(): CardSize {
    return this.card.size;
  }
  @computed get isCardOwner(): boolean {
    if (!this.userSessionStore.user) return false;

    return this.userSessionStore.user.id === this.card.curator.id;
  }
  @computed get actionResource(): ActionResource {
    return this.card.resource;
  }
  cardContextMenuDriver: CardActionContextMenuStore;

  @computed get curatorAvatarUrl(): string {
    return this.card.curator.image.url;
  }
  @computed get deckName(): string {
    return this.card.deck?.name || "";
  }
  @computed get deckNameWithHashTag(): string {
    return this.card.deck ? `#${this.card.deck.name}` : "";
  }
  @computed get deckPath(): string {
    return this.card.deck ? `/${this.card.deck.path.join("/")}` : "";
  }
  @action.bound toggleContextMenu(): void {
    if (!this.isContextMenuActive) {
      this.cardContextMenuDriver.getCardOrder();
    }
    this.isContextMenuActive = !this.isContextMenuActive;
  }
  @action.bound closeContextMenu(): void {
    this.isContextMenuActive = false;
  }
  @action.bound onContextMenuEllipsisClicked(): void {
    this.toggleContextMenu();
  }
  @action.bound onContextMenuFullscreenOverlayClicked(): void {
    this.isContextMenuActive = false;
  }

  @action.bound onCardLinkClicked = flow(function* onCardLinkClicked(
    this: CardActionModel,
  ) {
    if (this.actionResource.type === CardActionTypeOfAction.SYSTEM_COMMAND) {
      return;
    }

    yield* toFlowGeneratorFunction(handleDeepLinkClicked)(
      {
        actionResource: this.actionResource,
        linkTarget:
          this.actionResource.type === CardActionTypeOfAction.URL_EXTERNAL
            ? "_blank"
            : undefined,
      },
      this.modalStore,
    );
  });

  @computed get title(): string | undefined {
    return this.card.title;
  }
  @computed get subtitle(): string | undefined {
    return this.card.subtitle;
  }
  @computed get backgroundImage(): string | undefined {
    if (!this.card.backgroundImage) return undefined;

    const cardDimensions = getCardDimensionsByCardSize(this.cardSize);
    return getResizeImageUrl(
      this.card.backgroundImage,
      cardDimensions,
      this.card.backgroundImage.url,
    );
  }
  @computed get textAlign(): CardTextAlignment {
    return this.card.textAlignment;
  }
  @computed get textColor(): CardTextColor {
    return this.card.textColor;
  }
  @computed get buttonText(): string {
    return this.card.buttonText || "";
  }
}
