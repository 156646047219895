import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  toJS,
} from "mobx";
import Router from "next/router";
import React from "react";

import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { ModalApiKeyGeneratedContentDriver } from "../components/ModalApiKeyGenerated/ModalApiKeyGeneratedContent";
import { ModalApiKeyRegenerateDriver } from "../components/ModalApiKeyRegenerate/ModalApiKeyRegenerateLayout";
import { UserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { OneFlowInitialState } from "../components/ModalOneFlow/ModalOneFlow";
import { branchIOHost } from "../shared/env";
import { isBrowser } from "../shared/helpers/isBrowser";
import { InitialState } from "./CreateCardStore/CreateCardStore";

export type ModalType =
  | "loginForm"
  | "loginSocial"
  | "signup"
  | "signupForm"
  | "cardReport"
  | "deckReport"
  | "profileReport"
  | "forgotPassWord"
  | "restorePassWord"
  | "passRestored"
  | "sendEmail"
  | "faq"
  | "cardVideo"
  | "claim"
  | "uploadPhoto"
  | "deleteAccount"
  | "publishProfile"
  | "donate"
  | "donateToPublishGroup"
  | "cardDelete"
  | "deckDelete"
  | "collectibleCardSave"
  | "acceptTermAndPolicy"
  | "info"
  | "editCampaignAsset"
  | "orderingUON"
  | "manageUONCodes"
  | "createPrePaidCodes"
  | "apiKeyGenerated"
  | "apiKeyRegenerate"
  | "ModalCreateCardCollectable"
  | "";

export type IOption = {
  keepPreviousModal?: boolean;
};

export type LazyModalType =
  | "cardCreate"
  | "changeQuoteNPO"
  | "uploadCoverNPO"
  | "unsaveChanges"
  | "changePassword"
  | "editDeck"
  | "contactConnect"
  | "leaderBoard"
  | "contactPartner"
  | "createCampaign"
  | "editCampaignAsset"
  | "donate"
  | "donateToPublishGroup"
  | "deleteCampaign"
  | "shareCampaignDefault"
  | "editCampaignDefault"
  | "publishGroup"
  | "createGroup"
  | "infoGroup"
  | "addGroupMember"
  | "editMemberGroup"
  | "groupRoleSelection"
  | "confirmGroupRole"
  | "startGiving"
  | "shareGift"
  | "orderingUON"
  | "manageUONCodes"
  | "createPrePaidCodes"
  | "autoPlayDeck"
  | "oneFlow"
  | "subscriptionsCollected"
  | "apiKeyGenerated"
  | "apiKeyRegenerate"
  | "ModalCreateCardCollectable"
  | "cardDelete"
  | "collectibleCardSave"
  | "systemDeck"
  | "ModalCardContentEditor"
  | "deleteSection"
  | "";

export type IModalCreateCard = {
  name: "cardCreate";
  driver: InitialState;
};

export type IModalApiKeyGenerated = {
  name: "apiKeyGenerated";
  driver: ModalApiKeyGeneratedContentDriver;
};

export type IModalApiKeyRegenerate = {
  name: "apiKeyRegenerate";
  driver: ModalApiKeyRegenerateDriver;
};

export type IModalOneFlow = {
  name: "oneFlow";
  initialState?: OneFlowInitialState;
};

export type LazyModal = {
  name: LazyModalType;
  component: React.ReactElement;
};

export type ModalData = {
  activeModals: (
    | ModalType
    | LazyModal
    | IModalCreateCard
    | IModalApiKeyRegenerate
  )[];
};

export function isLazyModal(
  m: ModalType | LazyModal | IModalCreateCard | IModalApiKeyRegenerate,
): m is LazyModal {
  return (m as LazyModal).name !== undefined;
}

export function isModalCreateCard(
  modal:
    | ModalType
    | LazyModalType
    | IModalCreateCard
    | IModalOneFlow
    | IModalApiKeyGenerated
    | IModalApiKeyRegenerate,
): modal is IModalCreateCard {
  return (
    modal.hasOwnProperty("name") &&
    (modal as IModalCreateCard).name === "cardCreate"
  );
}

export function isModalApiKeyGenerated(
  modal:
    | ModalType
    | LazyModalType
    | IModalCreateCard
    | IModalOneFlow
    | IModalApiKeyGenerated
    | IModalApiKeyRegenerate,
): modal is IModalApiKeyGenerated {
  return (
    modal.hasOwnProperty("name") &&
    (modal as IModalApiKeyGenerated).name === "apiKeyGenerated"
  );
}

export function isModalOneFlow(
  modal:
    | ModalType
    | LazyModalType
    | IModalOneFlow
    | IModalCreateCard
    | IModalApiKeyGenerated
    | IModalApiKeyRegenerate,
): modal is IModalOneFlow {
  return (
    modal.hasOwnProperty("name") && (modal as IModalOneFlow).name === "oneFlow"
  );
}

export function isModalApiKeyRegenerate(
  modal:
    | ModalType
    | LazyModalType
    | IModalCreateCard
    | IModalOneFlow
    | IModalApiKeyGenerated
    | IModalApiKeyRegenerate,
): modal is IModalApiKeyRegenerate {
  return (
    modal.hasOwnProperty("name") &&
    (modal as IModalApiKeyRegenerate).name === "apiKeyRegenerate"
  );
}

export interface IModalStore {
  previousModal: ModalType | LazyModalType;
  activeModals: (
    | ModalType
    | LazyModal
    | IModalCreateCard
    | IModalApiKeyRegenerate
  )[];
  openModal: (
    modalType: ModalType | LazyModalType | IModalCreateCard | IModalOneFlow,
    options?: IOption,
  ) => Promise<void>;
  openLazyModal: (modal?: LazyModal, options?: IOption) => void;
  goBack: () => void;
  closeLazyModal: () => void;
  lazyModalGoBack: () => void;
}

export class ModalStore implements IModalStore {
  @observable activeModals: IObservableArray<
    ModalType | LazyModal | IModalCreateCard | IModalApiKeyRegenerate
  > = observable<
    ModalType | LazyModal | IModalCreateCard | IModalApiKeyRegenerate
  >([]);

  @observable previousModal: ModalType | LazyModalType = "";

  constructor(private userSessionStore: UserSessionStore) {
    makeObservable(this);
  }

  public dehydrate(): ModalData {
    return {
      activeModals: toJS(this.activeModals),
    };
  }

  @action.bound public hydrate(data: ModalData): void {
    this.activeModals.replace(data.activeModals);
  }

  @computed get lazyModals(): LazyModal[] {
    const lms: LazyModal[] = [];
    for (const m of this.activeModals) {
      if (isLazyModal(m)) {
        lms.push(m);
      }
    }
    return lms;
  }

  @action openModal = async (
    modalType:
      | ModalType
      | LazyModalType
      | IModalCreateCard
      | IModalOneFlow
      | IModalApiKeyGenerated
      | IModalApiKeyRegenerate,
    options?: IOption,
  ): Promise<void> => {
    if (isModalCreateCard(modalType)) {
      this.openLazyModal({
        name: "cardCreate",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalCreateCard/ModalCreateCard")
            }
            driver={modalType.driver}
          />
        ),
      });
      return;
    }

    if (modalType === "cardCreate") {
      this.openLazyModal({
        name: "cardCreate",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalCreateCard/ModalCreateCard")
            }
          />
        ),
      });
      return;
    }

    if (modalType === "changePassword") {
      this.openLazyModal({
        name: "cardCreate",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalChangePassword/ModalChangePassword")
            }
          />
        ),
      });
      return;
    }

    if (modalType === "contactConnect") {
      this.openLazyModal({
        name: "contactConnect",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalContact/ModalContactConnect")
            }
          />
        ),
      });
      return;
    }

    if (modalType === "contactPartner") {
      this.openLazyModal({
        name: "contactPartner",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalContact/ModalContactPartner")
            }
          />
        ),
      });
      return;
    }

    if (modalType === "autoPlayDeck") {
      this.openLazyModal({
        name: "autoPlayDeck",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalAutoplayDeck/ModalAutoplayDeck")
            }
          />
        ),
      });
      return;
    }

    if (isModalApiKeyRegenerate(modalType)) {
      this.openLazyModal({
        name: "apiKeyRegenerate",
        component: (
          <DynamicLazyModalLoader
            driver={modalType.driver}
            loadComponent={() =>
              import(
                "../components/ModalApiKeyRegenerate/ModalApiKeyRegenerate"
              )
            }
          />
        ),
      });
      return;
    }

    if (isModalOneFlow(modalType)) {
      if (isBrowser() && !this.userSessionStore.user) {
        Router.push(
          branchIOHost +
            "/i?deeplink_path=" +
            encodeURIComponent("/?oneFlowType=protect"),
        );
        return;
      }
      this.openLazyModal({
        name: "oneFlow",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalOneFlow/ModalOneFlow")
            }
            initialState={modalType.initialState}
          />
        ),
      });
      return;
    }

    if (isModalApiKeyGenerated(modalType)) {
      this.openLazyModal({
        name: "apiKeyGenerated",
        component: (
          <DynamicLazyModalLoader
            loadComponent={() =>
              import("../components/ModalApiKeyGenerated/ModalApiKeyGenerated")
            }
            driver={modalType.driver}
          />
        ),
      });
      return;
    }

    if (
      modalType === "changeQuoteNPO" ||
      modalType === "uploadCoverNPO" ||
      modalType === "unsaveChanges" ||
      modalType === "leaderBoard" ||
      modalType === "editDeck" ||
      modalType === "createCampaign" ||
      modalType === "shareCampaignDefault" ||
      modalType === "editCampaignDefault" ||
      modalType === "deleteCampaign" ||
      modalType === "createGroup" ||
      modalType === "publishGroup" ||
      modalType === "infoGroup" ||
      modalType === "addGroupMember" ||
      modalType === "editMemberGroup" ||
      modalType === "groupRoleSelection" ||
      modalType === "confirmGroupRole" ||
      modalType === "startGiving" ||
      modalType === "shareGift" ||
      modalType === "oneFlow" ||
      modalType === "subscriptionsCollected" ||
      modalType === "systemDeck" ||
      modalType === "ModalCardContentEditor" ||
      modalType === "deleteSection"
    ) {
      throw new Error(`unsupport modalType: ${modalType}`);
    }

    if (modalType === "") {
      this.previousModal = "";
      // close current modal
      this.activeModals.splice(-1, 1);
      return;
    }

    if (options && options.keepPreviousModal) {
      this.activeModals.push(modalType);
      return;
    }

    if (this.activeModals.length === 0) {
      this.previousModal = "";
      this.activeModals.push(modalType);
    } else {
      const prevModal:
        | LazyModal
        | ModalType
        | IModalCreateCard
        | IModalApiKeyRegenerate =
        // eslint-disable-next-line unicorn/prefer-at
        this.activeModals[this.activeModals.length - 1];
      this.previousModal =
        isLazyModal(prevModal) ||
        isModalCreateCard(prevModal) ||
        isModalApiKeyRegenerate(prevModal)
          ? prevModal.name
          : prevModal;

      this.activeModals[this.activeModals.length - 1] = modalType;
    }
  };

  @action.bound goBack = (): void => {
    if (!this.previousModal) {
      this.openModal("");
      return;
    }
    this.openModal(this.previousModal);
  };

  @action openLazyModal = (modal?: LazyModal, options?: IOption): void => {
    if (!modal) {
      this.previousModal = "";
      // close current modal
      this.activeModals.splice(-1, 1);
      return;
    }

    if (options && options.keepPreviousModal) {
      this.activeModals.push(modal);
      return;
    }
    const prevModal:
      | LazyModal
      | ModalType
      | IModalCreateCard
      | IModalApiKeyRegenerate
      | undefined = this.activeModals[this.activeModals.length - 1];

    if (prevModal) {
      this.previousModal =
        isLazyModal(prevModal) ||
        isModalCreateCard(prevModal) ||
        isModalApiKeyRegenerate(prevModal)
          ? prevModal.name
          : prevModal;

      this.activeModals[this.activeModals.length - 1] = modal;
    } else {
      this.previousModal = "";
      this.activeModals.push(modal);
    }
  };

  @action.bound closeLazyModal(): void {
    this.openLazyModal();
  }

  @action.bound lazyModalGoBack(): void {
    if (!this.previousModal) {
      this.openModal("");
      return;
    }

    this.openModal(this.previousModal);
  }
}
