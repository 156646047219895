/* eslint-disable no-param-reassign */
/* eslint-disable import/no-default-export */
import {
  CardEditableResponseDto,
  CardItemEditableRepostResponseDto,
  CardSuggestionAlgorithm,
  RelatedCardsGetResponseDto,
} from "@earthtoday/contract";

import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { isBrowser } from "../helpers/isBrowser";
import { CardAction, CardInfo, CardRegular } from "../models/Card";
import { ApiOption } from "./options/ApiOption";
import { UserSessionApi } from "./UserSessionApi";

export interface ICardDetailApi {
  fetchCardDetail(
    vanityName: string,
    cardId: string,
    options?: ApiOption,
    timeStamp?: number,
  ): Promise<
    | CardRegular
    | CardInfo
    | CardAction
    | CardEditableResponseDto
    | CardItemEditableRepostResponseDto
  >;

  fetchCardReposts(cardId: string, options?: ApiOption): Promise<CardRegular[]>;

  fetchCardRelated(
    cardId: string,
    algorithm?: CardSuggestionAlgorithm,
    options?: ApiOption,
  ): Promise<RelatedCardsGetResponseDto>;
}

export class CardDetailApi implements ICardDetailApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private userSessionApi: UserSessionApi,
  ) {}

  // TODO: implement new endpoint to get cardmeta use for client side
  fetchCardDetail = async (
    vanityName: string,
    cardId: string,
    options?: ApiOption,
    timeStamp?: number,
  ): Promise<
    | CardRegular
    | CardInfo
    | CardAction
    | CardEditableResponseDto
    | CardItemEditableRepostResponseDto
  > => {
    const headers: Record<string, string> = {};
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${vanityName}/cards/${cardId}?metaTags=${!isBrowser()}${
        timeStamp ? "&timestamp=" + timeStamp : ""
      }`,
      headers,
    });

    return res.data;
  };

  fetchCardReposts = async (
    cardId: string,
    options?: ApiOption,
  ): Promise<CardRegular[]> => {
    const headers: Record<string, string> = {};
    if (options?.byBass) {
      headers["x-apicache-bypass"] = "true";
    }
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}/reposts?count=20`,
      headers,
    });

    return res.data.cards;
  };

  fetchCardRelated = async (
    cardId: string,
    algorithm?: CardSuggestionAlgorithm,
    options?: ApiOption,
  ): Promise<RelatedCardsGetResponseDto> => {
    const headers: Record<string, string> = {};
    if (options?.clearCache) {
      headers["cache-control"] = "max-age=0";
    }

    const response =
      await this.tokenInterceptorStore.tsRestClient.card.getRelatedCards({
        params: { cardId },
        query: { count: "4", algorithm },
        extraHeaders: headers as any,
      });

    if (response.status === 200) {
      return response.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };
}
