import styled from "styled-components";

export const IconSvgContainer = styled.div<{
  width: string;
  height: string;
  color: string;
  hoverColor: string;
  padding: string;
  position: string;
  opacity: number;
  cursor: string;
  backgroundColor: string;
  zIndex: number;
  rotate: number;
  margin: string;
  pointerEvents: string;
  strokeColor: string | null;
  fillColor: string | null;
}>`
  display: flex;
  position: ${(props) => props.position};
  opacity: ${(props) => props.opacity};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  min-width: ${(props) => props.width};
  min-height: ${(props) => props.height};
  max-width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  fill: ${(props) => props.color};
  cursor: ${(props) => props.cursor};
  background-color: ${(props) => props.backgroundColor};
  z-index: ${(props) => props.zIndex};
  transform: rotate(${(props) => props.rotate}deg);
  margin: ${(props) => props.margin};
  pointer-events: ${(props) => props.pointerEvents};

  svg {
    height: 100%;
    width: 100%;
    ${(props) =>
      props.strokeColor &&
      `& > g > g, & > path {stroke: ${props.strokeColor};}`};
    ${(props) =>
      props.fillColor &&
      `& > path, & > g > g, & > g > g > g path {fill: ${props.fillColor};}`};
    ${(props) =>
      props.color &&
      `& #Color-Update-1, #Color-Update-2 {fill: ${props.color};}`};
  }

  &:hover {
    fill: ${(props) => props.hoverColor};
  }
`;

export default {
  IconSvgContainer,
};
