export const etRedLight = "#feefee";
export const etAmaranth = "#dc3545";
export const etRed = "#ee220c";
export const etAbsoluteRed = "#ff0000";
export const etRedDark = "#a12618";
export const etRedFresh = "#f60561";

// BLUE ZONE
export const etSanMarino = "#414BB2";
export const sdgPartnerships = "#19486a";
export const etLiBlueWaterDark = "#006fa6";
export const etBlueDark = "#0071b3";
export const sdgPeace = "#00689d";
export const etBlueDefault = "#007bff";
export const sdgWater = "#0a97d9";
export const etLightBlue = "#00A2FF";
export const etBlue = "#00a2ff";
export const etTwBlueWater = "#00acee";
export const etBlueLowLight = "#00c7FB";
export const etBrightLightBlue = "#32c5ff";
export const etBrightLightBlueOpacity = "#32c5ff26";
export const etBrightTurquoise = "#00ccfa";
export const etBlueLight = "#A1DAFF";
export const etBlueOnahau = "#d1f5fe";
export const etBlueZiggurat = "#b2d7e0";
// END BLUE ZONE

export const etSunglow = "#FFC532";
export const etBlueFaded = "rgba(50, 197, 255, 0.1)";
export const etBluePattens = "#D5F3FF";
export const etBlueLighter = "#D3E6F1";
export const etOrangeLight = "#fff7ed";
export const etOrange = "#ff9300";
export const etOrangeAutumn = "#EB9301";
export const etOrangeDark = "#b37012";
export const etYellowLight = "#fffce9";
export const etYellow = "#fae232";
export const etYellowDark = "#ad9f34";
export const etYellowDeck = "#E6D02C";
export const etGreenLight = "#f4fcf1";
export const etGreenSquare = "#48a128";
export const etGreen = "#61d836";
export const etGreenDark = "#498c31";
export const etNiagara = "#0DA888";
export const etPinkLight = "#f4eefc";
export const etPink = "#6108d8";
export const etPinkDark = "#48148c";
export const etPinkHighLight = "#f6ebf7";
export const etLicoriceBlue = "#293540";
export const etHeatherBlue = "#b1b9c1";

export const etWhiteLight = "#f7f9fa";
export const etWhite = "#ffffff";
export const etGhostWhite = "#f3f3f4";
export const etWhiteOpacity = "#ffffffaa";
export const etWhiteDark = "#f4f5f5";
export const etSilverLight = "#d8d8d8";
export const etGrayLight = "#d4d7d9";
export const etGray = "#7f868e";
export const etGrayBlue = "#6a7179";
export const etGhostGray = "#ced4da";
export const etGrayBold = "#949a9f";
export const etGrayTuna = "#495057";
export const etGrayDark = "#2a3642";
export const etRegentGray = "#949a9f";
export const etDark = "#293540";
export const etDarkWithOpacity = "#2935403d";
export const etGrayExtraDark = "#161c22";
export const etIronGray = "#d6dadc";
export const etBlack = "#000000";
export const etTundora = "#494949";
export const etConcrete = "#F2F2F2";

export const etShadow = "rgba(0, 0, 0, 0.5)";
export const etShadowWhite = "rgba(225, 225, 225, 0.5)";
export const etShadowLight = "rgba(0, 0, 0, 0.3)";
export const etShadowLighter = "rgba(0, 0, 0, 0.1)";
export const etShadowBrighter = "rgba(0, 0, 0, 0.05)";
export const etShadowDarkBlueFaded = "rgba(42, 54, 66, 0.2)";
export const etFbPurple = "#4267b2";

export const etWaGreen = "#25D366";
export const etGreenBold = "#18360E";
export const etForestGreen = "#61D83640";
export const etPiRed = "#BD091D";
export const etYTDark = "#35383C";
export const etCeruleanFrost = "#7591cc";
export const etSolitude = "#E0E5EA";
export const etSolitudeLight = "#EBEEF0";
export const etPattensBlue = "#DEE2E6";
export const etIceBlue = "#ebeef0";
export const etScarletRed = "#ee220c";
export const etSolitudeBlue = "#ebeef0";

export const sdgPoverty = "#e5243b";
export const sdgHunger = "#dda63a";
export const sdgHealth = "#4c9f38";
export const sdgEducation = "#c5192d";
export const sdgEquality = "#ff3a21";
export const sdgSanitation = "#26bde2";
export const sdgEnergy = "#fcc30b";
export const sdgGrowth = "#a21942";
export const sdgInnovation = "#fd6925";
export const sdgInequality = "#dd1367";
export const sdgCommunities = "#fd9d24";
export const sdgConsumption = "#bf8b2e";
export const sdgClimate = "#3f7e44";

export const sdgLand = "#56c02b";

export const etRedBlood = "#E5233B";
export const etBlueSky = "#edf8ff";
export const etGrayDarkWithOpacity = "rgba(42, 54, 66, 0.05)";

export const etVoteGreen = "#6dd400";
export const etVoteRed = "#e02020";
export const etPurple = "#531B93";
export const etPurpleUonCard = "#a63cff";
export const etPurpleLogo = "#531A93";
export const etHeliotrope = "#A659FF";

export const etNevadaGrey = "#697179";
export const etStormGray = "#727377";
export const etGallery = "#ebebeb";
export const etVeryMarshmallow = "#ebeef0";

export const etGrayIconMenu = "#7F8674";
export const etDustyGray = "#959595";
export const etFrenchGray = "#cdcdcd";
export const etPaleSky = "#6C757D";
export const etSonicSilver = "#757575";
