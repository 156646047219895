/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable class-methods-use-this */
import { action, computed, flow, makeObservable, observable, toJS } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { CardItemLocation } from "../../components/CardItem/CardItem";
import { CardInfoContextMenuDriver } from "../../components/CardItemInfoContextMenu/CardItemInfoContextMenu";
import { DynamicLazyModalLoader } from "../../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { MAX_CARDS_IN_DECK } from "../../shared/constants";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import {
  CardSize,
  CardTextAlignment,
  CardTextColor,
  CardType,
} from "../../shared/models/Card";
import { PageStore } from "../CardActionModel/CardActionModel";
import DeckDetailStore from "../DeckDetailStore/DeckDetailStore";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { IModalStore } from "../ModalStore";
import { CardInfoModel } from "./CardInfoModel";

export class CardInfoContextMenuStore implements CardInfoContextMenuDriver {
  constructor(
    private parentStore: CardInfoModel,
    private modalStore: IModalStore,
    private deckDetailApi: IDeckDetailApi,
    public theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private pageStore: PageStore,
    private modalDeleteCardStore: IModalDeleteCardStore,
    private featureFlagging: FeatureFlaggingData,
    private deckDetailStore: DeckDetailStore | null,
    private location?: CardItemLocation,
  ) {
    makeObservable(this);
  }
  get contentType(): CardType.INFO {
    return CardType.INFO;
  }
  @computed get isGuestView(): boolean {
    if (!this.userSessionStore.user) return true;

    const cardOwnerId = this.parentStore.card.curator.id;
    const sessionUserId = this.userSessionStore.user.id;

    return (
      !this.userSessionStore.user.administrator && cardOwnerId !== sessionUserId
    );
  }

  @computed get isCardSizeVisible() {
    return this.location !== "card-detail";
  }

  @computed get cardSize(): CardSize {
    return this.parentStore.cardSize;
  }

  @action.bound onCardSizeChanged = flow(function* onCardSizeChange(
    this: CardInfoContextMenuStore,
    size: CardSize,
  ) {
    try {
      const card = yield* toFlowGeneratorFunction(
        this.deckDetailApi.resizeCard,
      )(this.parentStore.card.id, size);
      this.parentStore.card.size = card.size || size;
      this.parentStore.toggleContextMenu();
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.general.success",
        content: "toast-message.edit-card.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @computed get textAlign(): CardTextAlignment {
    return this.parentStore.textAlign;
  }
  @action.bound onTextAlignmentChanged = flow(function* onTextAlignmentChanged(
    this: CardInfoContextMenuStore,
    textAlign: CardTextAlignment,
  ) {
    try {
      const card = yield* toFlowGeneratorFunction(
        this.deckDetailApi.onEditCardInfo,
      )(this.parentStore.card.id, {
        type: CardType.INFO,
        textAlignment: textAlign,
      });
      this.parentStore.card.textAlignment = card.textAlignment;
      this.parentStore.toggleContextMenu();
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.general.success",
        content: "toast-message.edit-card.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @computed get textColor(): CardTextColor {
    return this.parentStore.textColor;
  }
  @action.bound onTextColorChanged = flow(function* onTextColorChanged(
    this: CardInfoContextMenuStore,
    textColor: CardTextColor,
  ) {
    try {
      const card = yield* toFlowGeneratorFunction(
        this.deckDetailApi.onEditCardInfo,
      )(this.parentStore.card.id, { type: CardType.INFO, textColor });
      this.parentStore.card.textColor = card.textColor;
      this.parentStore.toggleContextMenu();
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.general.success",
        content: "toast-message.edit-card.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @computed get backgroundColor(): string {
    return this.parentStore.backgroundColor;
  }
  @action.bound onBackgroundColorChanged = flow(
    function* onBackgroundColorChanged(
      this: CardInfoContextMenuStore,
      value: string,
    ) {
      try {
        const card = yield* toFlowGeneratorFunction(
          this.deckDetailApi.onEditCardInfo,
        )(this.parentStore.card.id, {
          type: CardType.INFO,
          backgroundColor: value,
        });
        this.parentStore.card.backgroundColor = card.backgroundColor;
        this.parentStore.toggleContextMenu();
        this.theMessageStore.showMessage({
          typeMessage: "Close",
          title: "toast-message.general.success",
          content: "toast-message.edit-card.content",
        });
      } catch (error) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    },
  );

  @computed get shouldRenderCardOrder(): boolean {
    if (
      this.parentStore.isFeaturedDeck ||
      !this.deckDetailStore?.isLoggedinUser
    ) {
      return false;
    }
    return this.featureFlagging.flags.enableSortCardInDeck;
  }
  @observable cardOrder: number | null = null;
  @computed get cardOrderInput(): string | null {
    return this.cardOrder?.toString() || null;
  }
  @computed get sortNumberInputError(): string | null {
    if (this.cardOrder === null) return null;

    return this.cardOrder > MAX_CARDS_IN_DECK || this.cardOrder < 1
      ? `Card's order should be in range from 1 to ${MAX_CARDS_IN_DECK}`
      : null;
  }
  onCardOrderChanged = (order: string): void => {
    this.updateCardOrder(order);
  };

  @action.bound updateCardOrder(order: string) {
    const orderNumber = Number(order);

    if (orderNumber > MAX_CARDS_IN_DECK) {
      this.cardOrder = MAX_CARDS_IN_DECK;
      return;
    }

    this.cardOrder = orderNumber;
  }
  @action.bound onCardOrderBlured = flow(function* onCardOrderBlured(
    this: CardInfoContextMenuStore,
  ) {
    if (
      this.sortNumberInputError ||
      !this.userSessionStore.user ||
      !this.deckDetailStore?.isLoggedinUser
    ) {
      return;
    }

    try {
      const userVanityName = this.userSessionStore.user.vanityName;
      const deckId = this.parentStore.card.deck?.id || "";
      const sortCardResponse = yield* toFlowGeneratorFunction(
        this.deckDetailApi.updateCardOrder,
      )(
        userVanityName,
        deckId,
        this.parentStore.card.id,
        Number(this.cardOrderInput),
      );
      const cardIds: string[] = sortCardResponse.data.map((item) =>
        item.id.toString(),
      );
      if (this.deckDetailStore) {
        this.deckDetailStore.updateOrderOfCards(cardIds);
      }
      this.parentStore.closeContextMenu();
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });
  @action.bound getCardOrder(): void {
    if (!this.deckDetailStore) {
      this.cardOrder = null;
      return;
    }
    const foundIndex = this.deckDetailStore.cards.findIndex(
      (card) => card.id === this.parentStore.id,
    );
    this.cardOrder = foundIndex > -1 ? foundIndex + 1 : null;
  }

  @action.bound onCardEditButtonClicked(): void {
    this.parentStore.toggleContextMenu();
    this.modalStore.openLazyModal({
      name: "cardCreate",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            // eslint-disable-next-line more/no-then
            import(
              "../../components/ModalCreateCardInfo/ModalCreateCardInfoSmart"
            ).then((component) => component.ModalCreateCardInfoSmart)
          }
          editCard={toJS(this.parentStore.card)}
        />
      ),
    });
  }
  @action.bound onCardDeleteButtonClicked(): void {
    this.modalDeleteCardStore.updateCardDelete(this.parentStore);
    this.parentStore.toggleContextMenu();
    this.modalStore.openModal("cardDelete");
  }

  @action.bound onDeleteCard(): void {
    this.pageStore && this.pageStore.onDeleteCard(this.parentStore.id);
  }

  @action.bound onCardReportButtonClicked(): void {
    this.parentStore.toggleContextMenu();
    this.modalStore.openModal("cardReport");
  }
}
