import { observer } from "mobx-react-lite";

import { etBlack } from "../../shared/colors";
import sc from "./IconSvg.styled";
import { IconSvgLoader } from "./IconSvgLoader";

interface IProps {
  dataCy?: string;
  icon: string;
  position?: string;
  pointer?: boolean;
  opacity?: number;
  width?: string;
  height?: string;
  padding?: string;
  color?: string;
  hoverColor?: string;
  backgroundColor?: string;
  zIndex?: number;
  rotate?: number;
  margin?: string;
  strokeColor?: string;
  fillColor?: string;
  pointerEvents?: string;
  onClick?(): void;
}

export const IconSvg = observer((props: IProps) => {
  const {
    dataCy,
    position = "relative",
    opacity = 1,
    width = "32px",
    height = "26.4px",
    padding = "4px",
    color = etBlack,
    pointer = false,
    backgroundColor = "transparent",
    pointerEvents = "auto",
    zIndex = 0,
    rotate = 0,
    margin = "0",
    strokeColor = null,
    fillColor = null,
    onClick = () => {},
  } = props;

  return (
    <sc.IconSvgContainer
      data-cy={dataCy}
      position={position}
      opacity={opacity}
      width={width}
      height={height}
      padding={padding}
      color={color}
      zIndex={zIndex}
      cursor={pointer ? "pointer" : "inherit"}
      backgroundColor={backgroundColor}
      hoverColor={props.hoverColor || color}
      rotate={rotate}
      margin={margin}
      strokeColor={strokeColor}
      fillColor={fillColor}
      pointerEvents={pointerEvents}
      onClick={() => onClick()}
    >
      <IconSvgLoader name={props.icon} />
    </sc.IconSvgContainer>
  );
});
