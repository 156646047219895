/* eslint-disable consistent-return */
/* eslint-disable unicorn/no-array-push-push */
/* eslint-disable unicorn/consistent-function-scoping */
import {
  CardPromotionRepostResponseDto,
  CardStandardResponseDto,
  DeckDetailResponseDto,
  ShortcutType,
} from "@earthtoday/contract";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { CancellablePromise } from "mobx/dist/internal";
import { SingletonRouter } from "next/router";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { PromotionCount } from "../../../__generated__/ts-gql/@schema";
import { LayoutDriver } from "../../components/CardItemArticle/CardItemArticle";
import { DynamicLazyModalLoader } from "../../components/DynamicModalLoader/DynamicLazyModalLoader";
import { LeaderboardVisibleTab } from "../../components/Leaderboard/LeaderboardContent";
import { IModalDeleteCardStore } from "../../components/ModalDeleteCard/ModalDeleteCardStore";
import { IModalDeleteDeckStore } from "../../components/ModalDeleteDeck/ModalDeleteDeckStore";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  ErrorMessage,
  ITheMessageStore,
  ToastMessageStatus,
} from "../../components/TheMessage/TheMessageStore";
import { QrCodeOverlayPresenter } from "../../components/TheNavbarTop/TheNavbarStore/QrCodeOverlayPresenter";
import { TheNavbarStore } from "../../components/TheNavbarTop/TheNavbarStore/TheNavbarStore";
import {
  AutoplayDeckApi,
  SourceDeckItemResponse,
} from "../../shared/apis/AutoplayDeckApi";
import { ICardApi } from "../../shared/apis/CardApi";
import { ICreateCardApi } from "../../shared/apis/CreateCardApi";
import { IDeckDetailApi } from "../../shared/apis/DeckDetailApi";
import { IFollowingApi } from "../../shared/apis/FollowingApi";
import { IGroupApi } from "../../shared/apis/GroupApi";
import { NavbarApi } from "../../shared/apis/NavbarApi";
import { ApiOption } from "../../shared/apis/options/ApiOption";
import { IProfileApi } from "../../shared/apis/ProfileApi";
import { UnsubscribeFn } from "../../shared/apis/UnsubscribeFn";
import {
  TokenSwitchPayload,
  TokenType,
  UserSessionApi,
} from "../../shared/apis/UserSessionApi";
import {
  EARTH_TODAY_VANITY_NAME,
  MAX_CARDS_IN_DECK,
} from "../../shared/constants";
import { buildNewConsumers } from "../../shared/helpers/buildNewConsumers";
import { isDeckNameChangedError } from "../../shared/helpers/DeckNameChangedError";
import { isBrowser } from "../../shared/helpers/isBrowser";
import {
  getAPIErrorStatusCode,
  isAxiosError,
  translateAPIError,
} from "../../shared/helpers/translateApiError";
import { isVanityNameChangedError } from "../../shared/helpers/VanityNameChangedError";
import { first } from "../../shared/lodash";
import {
  Card,
  CardAction,
  CardArticleLayoutType,
  CardInfo,
  CardRegular,
  CardSize,
  CardState,
  CardType,
} from "../../shared/models/Card";
import { CustomConsumer, IConsumer } from "../../shared/models/Consumer";
import { UonReserve } from "../../shared/models/Uon";
import { IUserCountsCustom, User } from "../../shared/models/User";
import { snowplowETPageViewEvent } from "../../shared/snowplow";
import { AutoPlayDeckStore, AutoplayStatus } from "../AutoplayDeckStore";
import { CardActionModel } from "../CardActionModel/CardActionModel";
import { CardDeckHeaderModel } from "../CardDeckHeaderModel";
import CardDeckOverviewModel from "../CardDeckOverviewModel";
import {
  CardDeckRepostModel,
  isCardDeckRepostModel,
} from "../CardDeckRepostModel";
import { CardInfoModel } from "../CardInfoModel/CardInfoModel";
import { CardItemEditablePresenter } from "../CardItemEditablePresenter/CardItemEditablePresenter";
import { CardItemEditableRepostPresenter } from "../CardItemEditablePresenter/CardItemEditableRepostPresenter";
import { CardProfileRepostModel } from "../CardProfileRepostModel";
import { CardItemPromotionLocation } from "../CardPromotionModel/CardPromotionBaseModel";
import {
  CardPromotionRepostModel,
  CardPromotionRepostPresenterDependencies,
} from "../CardPromotionModel/CardPromotionRepostModel";
import { CardRegularModel } from "../CardRegularModel";
import { IDeviceStore } from "../DeviceStore";
import { Currency, DonateStore } from "../DonateStore/DonateStore";
import { FeatureFlaggingData, FlagNames } from "../FeatureFlaggingStore";
import { IFollowingRequest } from "../FollowingStore";
import { LeaderboardModel, LeaderTab } from "../LeaderBoardModel";
import { LeaderBoardRangeModel, UonRangeParam } from "../LeaderBoardRangeModel";
import { MetaStore } from "../MetaStore";
import { IModalStore, ModalType } from "../ModalStore";
import { OverlaySystemPagePresenter } from "../OverlaySystemPagePresenter";
import { ITokenStore } from "../TokenStore";
import { UserModel } from "../UserModel";

export enum ChannelPage {
  PEOPLE = "people",
  CREATION = "creation",
  WORLD = "world",
  NATURE = "nature",
  KEYS = "keys",
  SPIRIT = "spirit",
}

export enum HeaderComponentName {
  RESERVE_CARD = "RESERVE_CARD",
  FEATURED_CARD = "FEATURED_CARD",
  DECK_HEADER_CARD = "DECK_HEADER_CARD",
}

export type DeckCardItem = Card | CardPromotionRepostResponseDto;

export type HeaderComponentDriver =
  | {
      component: HeaderComponentName.RESERVE_CARD;
      driver: DeckDetailStore;
      userDriver: UserModel | null;
      stateDriver: DeckDetailStore;
    }
  | {
      component: HeaderComponentName.FEATURED_CARD;
      driver:
        | CardRegularModel
        | CardDeckRepostModel
        | CardProfileRepostModel
        | CardPromotionRepostModel;
      layoutDriver: LayoutDriver;
    }
  | {
      component: HeaderComponentName.DECK_HEADER_CARD;
      driver: CardDeckHeaderModel;
      featureFlaggingDriver: FlagNames;
    };

export interface LeaderboardsRange {
  [UonRangeParam.ONE_TO_FOUR]: LeaderBoardRangeModel;
  [UonRangeParam.FIVE_TO_NINE]: LeaderBoardRangeModel;
  [UonRangeParam.TEN_TO_FOURTY_NINE]: LeaderBoardRangeModel;
  [UonRangeParam.FIFTY_TO_NINETY_NINE]: LeaderBoardRangeModel;
}
export interface DeckDetailData {
  userProfile: User | null;
  deckDetail: DeckDetailResponseDto | null;
  cards: Array<DeckCardItem>;
  fetchPageDataError: ErrorMessage;
  errorStatus: null | number;

  fetchLeaderboardRecentError: ErrorMessage;
  fetchLeaderboardTopError: ErrorMessage;
  isServerLoading: boolean;
  isBrowserLoading: boolean;
  featureCard: CardStandardResponseDto | CardPromotionRepostResponseDto | null;
}

export type DeckDetailCardItem =
  | CardRegularModel
  | CardDeckRepostModel
  | CardProfileRepostModel
  | CardActionModel
  | CardInfoModel
  | CardPromotionRepostModel
  | CardItemEditablePresenter
  | CardItemEditableRepostPresenter;

// eslint-disable-next-line import/no-default-export
export default class DeckDetailStore {
  @observable deckDetail: CardDeckHeaderModel | null = null;
  @observable reserveCount: number = 0;
  @observable cards: IObservableArray<DeckDetailCardItem> =
    observable<DeckDetailCardItem>([]);
  @observable userProfile: UserModel | null = null;
  @observable chosenCardId: string = "";
  @observable chosenDeckId: string = "";
  @observable shareId: string = "";
  @observable starId: string = "";

  @observable fetchPageDataError: ErrorMessage = null;
  @observable errorStatus: number | null = null;
  @observable isServerLoading: boolean = false;
  @observable isBrowserLoading: boolean = false;
  @observable isRequestingPublishGroup = false;
  @observable userCounts: IUserCountsCustom = {
    curatedDecks: 0,
    draftingCards: 0,
    followingDecks: 0,
    starredCards: 0,
    publishedCards: 0,
    notifications: 0,
    campaignCards: 0,
    uonCount: 0,
    groupCount: 0,
    groupMemberCount: 0,
  };

  @observable visibleTabs: LeaderboardVisibleTab[] = [
    LeaderboardVisibleTab.RECENT,
    LeaderboardVisibleTab.TOP,
  ];

  @observable currentTabLeaderboard: LeaderTab = "recent";
  @observable topConsumers: IObservableArray<CustomConsumer> =
    observable<CustomConsumer>([]);
  @observable recentConsumers: IObservableArray<CustomConsumer> =
    observable<CustomConsumer>([]);
  @observable loadingProtectedCount: boolean = false;
  @observable fetchLeaderboardRecentError: ErrorMessage = null;
  @observable fetchLeaderboardTopError: ErrorMessage = null;
  @observable fetchReserveCountDataError: ErrorMessage = null;
  @observable fetchCardsMetadataError: ErrorMessage = null;
  @observable fetchProtectedCountError: ErrorMessage = null;

  constructor(
    private modalStore: IModalStore,
    private theMessageStore: ITheMessageStore,
    private userSessionStore: IUserSessionStore,
    private modalDeleteDeckStore: IModalDeleteDeckStore,
    private autoplayDeckStore: AutoPlayDeckStore,
    private autoplayDeckApi: AutoplayDeckApi,
    private modalDeleteCardStore: IModalDeleteCardStore,
    private metaStore: MetaStore,
    private tokenStore: ITokenStore,
    private donateStore: DonateStore,
    public deckDetailApi: IDeckDetailApi,
    public profileApi: IProfileApi,
    private followingApi: IFollowingApi,
    private cardApi: ICardApi,
    private userSessionApi: UserSessionApi,
    private groupApi: IGroupApi,
    private Router: SingletonRouter,
    private featureFlagging: FeatureFlaggingData,
    private deviceStore: IDeviceStore,
    public createCardAPI: ICreateCardApi,
    public overlaySystemPagePresenter: OverlaySystemPagePresenter,
    private theNavbarStore: TheNavbarStore,
  ) {
    makeObservable(this);
  }

  public dehydrate(): DeckDetailData {
    return {
      userProfile: this.userProfile?.toJSON() || null,
      deckDetail: this.deckDetail?.toJSON() || null,
      cards: this.cards.map((card) => card.toJSON()),
      fetchLeaderboardRecentError: this.fetchLeaderboardRecentError,
      fetchLeaderboardTopError: this.fetchLeaderboardTopError,
      fetchPageDataError: this.fetchPageDataError,
      errorStatus: this.errorStatus,
      isServerLoading: this.isServerLoading,
      isBrowserLoading: this.isBrowserLoading,
      featureCard: this.featureCard,
    };
  }

  @computed get currency(): Currency {
    return this.donateStore.currency;
  }

  @action.bound public hydrate(data: DeckDetailData): void {
    this.userProfile = data.userProfile
      ? new UserModel(data.userProfile, this.userSessionStore, this.tokenStore)
      : data.userProfile;
    if (data.deckDetail) {
      this.deckDetail = new CardDeckHeaderModel(
        this.modalStore,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteDeckStore,
        this.autoplayDeckStore,
        this,
        data.deckDetail,
        this.deviceStore,
        this.featureFlagging,
        this.deckDetailApi,
      );
    }

    this.featureCard = data.featureCard;

    this.cards.replace(
      data.cards.map((card) => this.parseCardResponseToCardItem(card)),
    );

    this.fetchLeaderboardRecentError = data.fetchLeaderboardRecentError;
    this.fetchLeaderboardTopError = data.fetchLeaderboardTopError;

    this.fetchPageDataError = data.fetchPageDataError;
    this.errorStatus = data.errorStatus;
    this.isServerLoading = data.isServerLoading;
    this.isBrowserLoading = data.isBrowserLoading;
  }

  @observable featureCard:
    | CardStandardResponseDto
    | CardPromotionRepostResponseDto
    | null = null;

  @computed get cardDeckOverView(): CardDeckOverviewModel | null {
    if (!this.deckDetail || !this.deckDetail.deck) {
      return null;
    }
    return new CardDeckOverviewModel(
      this.modalStore,
      this.modalDeleteDeckStore,
      this.autoplayDeckStore,
      this.theMessageStore,
      this.userSessionStore,
      this,
      this.deckDetail.deck.data,
      this.featureFlagging,
      this.deckDetailApi,
    );
  }

  @computed get isLoading(): boolean {
    if (this.isServerLoading) {
      return true;
    }

    if (!!this.userProfile) {
      return false;
    }

    return this.isBrowserLoading;
  }

  getDeckId = (): string => this.deckDetail?.id || "";

  @computed get groupId(): string {
    return this.userProfile?.group?.id || "";
  }

  @computed get cardItems(): DeckDetailCardItem[] {
    return this.cards.filter((card) => !card.isFeaturedDeck);
  }

  @computed get shouldRenderCardProfile(): boolean {
    if (this.deckDetail?.reserves && this.deckDetail.reserves.length > 0) {
      return false;
    }

    return !this.featureCard;
  }

  @action.bound openModalOneFlowOrDonate(): void {
    if (
      this.featureFlagging.flags.enableOneFlow &&
      !this.userSessionStore.user?.isGroupUnpublished
    ) {
      this.modalStore.openModal({ name: "oneFlow" });
    } else {
      this.modalStore.openModal("donate");
    }
  }

  @action onProtect = flow(function* (this: DeckDetailStore) {
    if (this.reserveId && !this.userProfile?.isDisabledProtect) {
      this.donateStore.updateReserveId(this.reserveId);
      this.openModalOneFlowOrDonate();
      return;
    }

    this.donateStore.updateReserveId("");
    this.openModalOneFlowOrDonate();
  });

  @action updateCurrentTab = (tab: LeaderTab): void => {
    this.currentTabLeaderboard = tab;
  };

  @action updateTopConsumers = (consumers: IConsumer[]): void => {
    const newConsumers = buildNewConsumers(consumers);
    this.topConsumers.replace(newConsumers);
  };

  @action updateRecentConsumers = (consumers: IConsumer[]): void => {
    const newConsumers = buildNewConsumers(consumers);
    this.recentConsumers.replace(newConsumers);
  };

  @action chosenDeckIdUpdate = (id: string): void => {
    this.chosenDeckId = id;
  };

  @action.bound reserveCountUpdate = (reserveCount: number): void => {
    this.reserveCount = reserveCount;
  };

  @action deckDetailUpdate = (deckDetail: CardDeckHeaderModel): void => {
    this.deckDetail = deckDetail;
  };

  @action setChosenCardId = (id: string): void => {
    this.chosenCardId = id;
  };

  @action onChosenCardIdUpdate = (id: string): void => {
    this.chosenCardId = id;
  };

  @action toggleShare = (id: string): void => {
    this.shareId = id;
  };

  @action setStarId = (id: string): void => {
    this.starId = id;
  };

  @action updateLoadingReserve = (b: boolean): void => {
    this.loadingProtectedCount = b;
  };

  @computed get isChannelPage(): boolean {
    const deckDetailVanityName =
      this.deckDetail?.deck.data.path &&
      this.deckDetail?.deck.data.path.length > 1
        ? this.deckDetail?.deck.data.path[1]
        : "";

    return Object.values(ChannelPage)
      .map((name) => name.toString())
      .includes(deckDetailVanityName);
  }

  @action.bound fetchPageDataBrowser = flow(function* fetchPageDataBrowser(
    this: DeckDetailStore,
    vanityName: string,
    deckName: string,
    isServerRender: boolean,
    redirect: (newPath: string) => void,
  ) {
    this.fetchPageDataError = null;
    this.errorStatus = null;
    this.isBrowserLoading = true;

    try {
      const promises: Array<() => CancellablePromise<void>> = [];

      if (!this.userProfile) {
        promises.push(() => this.fetchProfile(vanityName));
      }

      if (
        !this.deckDetail ||
        (isServerRender && this.isChannelPage) // refresh with token to get pesonalized cards
      ) {
        promises.push(() => this.fetchDeckDetail(vanityName, deckName));
      }

      if (this.isLoggedinUser) {
        promises.push(() => this.fetchDeckShortcuts());
      }

      yield Promise.all(promises.map((fn) => fn()));

      this.onFetchUserCounts();

      snowplowETPageViewEvent({
        category: this.deckDetail?.deck.data.categoryPrinciples?.length
          ? this.deckDetail?.deck.data.categoryPrinciples[0]
          : "",
        deckName: this.deckDetail?.deck.data.name || "",
        deckId: this.deckDetail?.deck.data.id || "",
        cardId: "", //not has cardId on deck detail page
      });
    } catch (error) {
      if (
        isDeckNameChangedError(error) &&
        error.name === "DeckNameChangedError"
      ) {
        const path = `${error.getNewVanityName()}/${error.getNewDeckname()}`;
        redirect(path);
        return;
      }

      if (
        isVanityNameChangedError(error) &&
        error.name === "VanityNameChangedError"
      ) {
        redirect(`${error.getNewVanityName()}/${deckName}`);
        return;
      }

      if (isAxiosError(error)) {
        this.fetchPageDataError = translateAPIError(error);
        this.errorStatus = getAPIErrorStatusCode(error);
      }
    } finally {
      this.isBrowserLoading = false;
    }

    yield this.fetchUserCardsMetadata();

    if (!this.deckDetail) {
      return;
    }

    if (this.deckDetail.reserves && this.deckDetail.reserves[0]) {
      const reserveId = this.deckDetail.reserves[0].id;
      this.updateLoadingReserve(true);
      yield Promise.all([
        this.fetchRecentConsumers(reserveId),
        this.fetchTopConsumers(reserveId),
        this.fetchReserveCount(reserveId),
      ]);
      this.updateLoadingReserve(false);
    }
  });

  @action.bound fetchPageDataServer = flow(function* fetchPageDataServer(
    this: DeckDetailStore,
    vanityName: string,
    deckName: string,
    redirect: (newPath: string) => void,
  ) {
    // Just fetch deck detail for sharing
    this.isServerLoading = true;
    this.fetchPageDataError = null;
    this.errorStatus = null;
    try {
      yield Promise.all([
        this.fetchDeckDetail(vanityName, deckName),
        this.fetchProfile(vanityName),
      ]);
      this.onFetchUserCounts();
    } catch (error) {
      if (
        isDeckNameChangedError(error) &&
        error.name === "DeckNameChangedError"
      ) {
        const path = `${error.getNewVanityName()}/${error.getNewDeckname()}`;
        redirect(path);
        return;
      }

      if (
        isVanityNameChangedError(error) &&
        error.name === "VanityNameChangedError"
      ) {
        redirect(`${error.getNewVanityName()}/${deckName}`);
        return;
      }

      if (isAxiosError(error)) {
        this.fetchPageDataError = translateAPIError(error);
        this.errorStatus = getAPIErrorStatusCode(error);
      }
    } finally {
      this.isServerLoading = false;
    }
  });

  @action.bound fetchProfile = flow(function* fetchProfile(
    this: DeckDetailStore,
    vanityName: string,
  ) {
    const api = this.deckDetailApi;
    const res = yield api.fetchProfile(vanityName);
    if (res) {
      this.userProfile = new UserModel(
        res,
        this.userSessionStore,
        this.tokenStore,
      );
    }
  });

  @action.bound fetchDeckDetail = flow(function* fetchDeckDetail(
    this: DeckDetailStore,
    vanityName: string,
    deckName: string,
  ) {
    const api = this.deckDetailApi;
    const res = yield* toFlowGeneratorFunction(api.fetchDeckDetail)(
      vanityName,
      deckName,
    );
    if (!res) return;
    this.featureCard = res.meta.featureCard || null;
    this.deckDetailUpdate(
      new CardDeckHeaderModel(
        this.modalStore,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteDeckStore,
        this.autoplayDeckStore,
        this,
        res,
        this.deviceStore,
        this.featureFlagging,
        this.deckDetailApi,
      ),
    );

    this.cards.replace(
      res.data.cards.map((card) =>
        this.parseCardResponseToCardItem(card as Card),
      ),
    );
    yield* toFlowGeneratorFunction(this.fetchPromotionCounts)();
    this.metaStore.updateMetaTags(res.meta.metaTags || {});
  });

  @action.bound fetchUserCardsMetadata = flow(function* fetchUserCardsMetadata(
    this: DeckDetailStore,
  ) {
    if (!this.userSessionStore.user) {
      return;
    }

    const cardIds: string[] = [];
    for (const card of this.cards) {
      if (
        card.contentType === CardType.ACTION ||
        card.contentType === CardType.INFO ||
        card.contentType === CardType.PROMOTION_REPOST
      ) {
        continue;
      }
      if (card.contentType !== CardType.REMOVEDCONTENT) {
        cardIds.push(card.newestId);
      }
    }

    if (cardIds.length === 0) {
      return;
    }

    try {
      const res = yield this.userSessionApi.fetchUserCardsMetadata(cardIds);
      if (res.length === 0) {
        return;
      }
      const starredCards: string[] = [];
      for (const card of res) {
        card.starred && starredCards.push(card.id);
      }

      for (const card of this.cards) {
        if (
          card.contentType === CardType.ACTION ||
          card.contentType === CardType.INFO ||
          card.contentType === CardType.PROMOTION_REPOST
        ) {
          continue;
        }
        if (starredCards.includes(card.newestId)) {
          card.updateStarCard(true);
        }
      }
    } catch (error) {
      this.fetchCardsMetadataError = translateAPIError(error);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound fetchRecentConsumers = flow(function* fetchRecentConsumers(
    this: DeckDetailStore,
    reserveId,
  ) {
    const { deckDetailApi: deckdetailAPI } = this;
    try {
      const res = yield deckdetailAPI.fetchLeaderboardRecent(`${reserveId}`);
      this.updateRecentConsumers(res);
      if (this.fetchLeaderboardRecentError)
        this.fetchLeaderboardRecentError = null;
    } catch (error) {
      this.fetchLeaderboardRecentError = translateAPIError(error);
    }
  });

  @action.bound fetchTopConsumers = flow(function* fetchTopConsumers(
    this: DeckDetailStore,
    reserveId,
  ) {
    const { deckDetailApi: deckdetailAPI } = this;

    try {
      const res = yield deckdetailAPI.fetchLeaderboardTop(`${reserveId}`);
      this.updateTopConsumers(res);
      if (this.fetchLeaderboardTopError) this.fetchLeaderboardTopError = null;
    } catch (error) {
      this.fetchLeaderboardTopError = translateAPIError(error);
    }
  });

  @action.bound fetchReserveCount = flow(function* fetchReserveCount(
    this: DeckDetailStore,
    reserveId,
  ) {
    const { deckDetailApi: deckdetailAPI } = this;

    try {
      const resReserveCount = yield deckdetailAPI.fetchReserveCount(
        `${reserveId}`,
      );
      this.reserveCountUpdate(resReserveCount.uonPreserved);
    } catch (error) {
      this.fetchReserveCountDataError = translateAPIError(error);
    }
  });

  @action.bound updateFeatureCard = flow(function* updateFeatureCard(
    this: DeckDetailStore,
    cardId: string,
    featured: boolean,
  ) {
    if (!this.deckDetail?.id) {
      return;
    }

    const api = this.deckDetailApi;

    try {
      const data: DeckDetailResponseDto = yield api.updateFeatureCard(
        this.deckDetail.id,
        cardId,
        featured,
      );
      const cards = data.data.cards.map((card) => {
        const size: string = (() => {
          if (card.contentType === CardType.PROMOTION_REPOST) {
            return CardSize.SINGLE;
          }

          return card.size.toLowerCase();
        })();

        return {
          ...card,
          size,
          state: card.state.toLowerCase(),
        };
      });

      this.featureCard = data.meta.featureCard || null;

      this.cards.replace(
        cards.map((card) => this.parseCardResponseToCardItem(card as Card)),
      );
      yield* toFlowGeneratorFunction(this.fetchPromotionCounts)();
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
    yield this.fetchUserCardsMetadata();
  });

  @action.bound toggleFollowing = flow(function* toggleFollowing(
    this: DeckDetailStore,
    data: IFollowingRequest,
  ) {
    if (!this.userSessionStore.user) {
      this.onOpenLoginModal();
      return;
    }

    const api = this.followingApi;
    try {
      this.optimisticFollowing(data);
      const res = yield api.toggleFollowingDecks(data);
      this.optimisticFollowing(data, res.count);
      return true;
    } catch (error) {
      this.optimisticFollowing({ ...data, following: !data.following });
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound updateStarCard = flow(function* updateStarCard(
    this: DeckDetailStore,
    id: string,
    starred: boolean,
  ) {
    try {
      this.optimisticStarCard(id, this.cards, starred);
      yield this.cardApi.starCard(id, starred);
    } catch (error) {
      this.optimisticStarCard(id, this.cards, !starred);
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onCardSizeChanged = flow(function* onCardSizeChanged(
    this: DeckDetailStore,
    cardId: string,
    size: CardSize,
  ) {
    try {
      const res = yield* toFlowGeneratorFunction(this.deckDetailApi.resizeCard)(
        cardId,
        size,
      );
      if (res.contentType === CardType.AUTOPLAY && res.autoplaySourceCard) {
        yield this.optimisticUpdateSize(res.autoplaySourceCard.id, res.size);
      }
      yield this.optimisticUpdateSize(cardId, res.size);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onChangeCardLayout = flow(function* onChangeCardLayout(
    this: DeckDetailStore,
    id: string,
    layoutType: CardArticleLayoutType,
  ) {
    try {
      const cardIndex = this.cards.findIndex((card) => card.id === id);
      if (cardIndex === -1) {
        return;
      }
      const res: CardRegular = yield this.deckDetailApi.onChangeCardLayout(
        id,
        layoutType,
      );
      if (res.contentType === CardType.AUTOPLAY && res.autoplaySourceCard) {
        yield this.optimisticUpdateLayout(
          res.autoplaySourceCard.id,
          new CardRegularModel(
            {
              ...res.autoplaySourceCard,
              cardAutoplayId: res.id,
              size: res.size,
              layoutType: res.layoutType,
            } as CardRegular,
            this.modalStore,
            this.theMessageStore,
            this.userSessionStore,
            this.modalDeleteCardStore,
            this,
            this.featureFlagging,
            this.deviceStore,
            this.deckDetailApi,
          ),
        );
      }

      yield this.optimisticUpdateLayout(
        id,
        new CardRegularModel(
          res,
          this.modalStore,
          this.theMessageStore,
          this.userSessionStore,
          this.modalDeleteCardStore,
          this,
          this.featureFlagging,
          this.deviceStore,
          this.deckDetailApi,
        ),
      );
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onDeleteDeck = flow(function* onDeleteDeck(
    this: DeckDetailStore,
    deckId: string,
  ) {
    const api = this.profileApi;
    try {
      yield api.deleteDeck(deckId);

      if (isBrowser() && !!this.Router) {
        this.Router.back();
      }

      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "Your Deck has been deleted",
        content: "Your Deck has been deleted just as you wanted.",
      });
      return true;
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound onDeleteCard = flow(function* onDeleteCard(
    this: DeckDetailStore,
    cardId: string,
    promotionId?: string,
    isAutoplayedCard?: boolean,
  ) {
    const api = this.profileApi;

    try {
      yield api.deleteCard(
        cardId,
        isAutoplayedCard ? this.deckDetail?.id : undefined,
      );
      this.optimisticDelete(cardId);
      this.deckDetail?.updateCardsCount(this.cards.length);

      if (!this.featureFlagging.flags.enableWebsocketCardEvents) {
        this.theMessageStore.showMessage({
          typeMessage: "Close",
          title: "Your Card has been deleted",
          content: "Your Card has been deleted just as you wanted",
        });
      }
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound optimisticUpdateSize(id: string, cardSize?: CardSize) {
    const foundCard = this.cards.find((item) => item.id === id);
    if (
      foundCard &&
      foundCard.contentType !== CardType.ACTION &&
      foundCard.contentType !== CardType.INFO &&
      foundCard.contentType !== CardType.PROMOTION_REPOST &&
      foundCard.contentType !== CardType.EDITABLE
    ) {
      foundCard.updateCardSize(cardSize || CardSize.SINGLE);
    }
  }
  @action.bound optimisticEditActionCard(card: CardAction) {
    const foundCard = this.cards.find((item) => item.id === card.id);
    if (foundCard && foundCard.contentType === CardType.ACTION) {
      foundCard.card = card;
    }
  }
  @action.bound optimisticEditInfoCard(card: CardInfo) {
    const foundCard = this.cards.find((item) => item.id === card.id);
    if (foundCard && foundCard.contentType === CardType.INFO) {
      foundCard.card = card;
    }
  }

  @action
  optimisticUpdateLayout(
    this: DeckDetailStore,
    id: string,
    card: CardRegularModel,
  ): void {
    const foundCard = this.cards.find((item) => item.id === id);
    if (
      foundCard &&
      foundCard.contentType !== CardType.ACTION &&
      foundCard.contentType !== CardType.INFO &&
      foundCard.contentType !== CardType.PROMOTION_REPOST &&
      foundCard.contentType !== CardType.EDITABLE
    ) {
      foundCard.updateChageLayout(
        card.layoutType || CardArticleLayoutType.EDITORIAL,
      );
    }
  }

  @action
  optimisticDelete(this: DeckDetailStore, id: string): void {
    const foundIndex = this.cards.findIndex((card) => card.id === id);

    if (foundIndex !== -1) {
      this.cards.splice(foundIndex, 1);
    }
  }

  @action optimisticStarCard = (
    id: string,
    data: IObservableArray<DeckDetailCardItem>,
    starred: boolean,
  ): void => {
    const foundCard = data.find((card) => card.id === id);
    if (
      foundCard &&
      foundCard.contentType !== CardType.ACTION &&
      foundCard.contentType !== CardType.INFO &&
      foundCard.contentType !== CardType.PROMOTION_REPOST
    ) {
      foundCard.updateStarCard(starred);
    }
  };

  @action optimisticFollowing = (
    { following, deckId }: IFollowingRequest,
    count?: number,
  ): void => {
    if (!this.deckDetail || !this.userSessionStore.user?.emailAddressVerified)
      return;

    const foundIndex = this.cards.findIndex((card) => {
      if (
        card.contentType === CardType.PROMOTION_REPOST ||
        card.card.contentType !== CardType.DECK_REPOST
      ) {
        return false;
      }
      return card.card.deckRepostId === deckId;
    });

    if (foundIndex !== -1) {
      const foundCard = this.cards[foundIndex];
      if (!isCardDeckRepostModel(foundCard)) {
        return;
      }

      const newCounts =
        count === undefined
          ? following
            ? (foundCard.deckRepostModel?.followingCounts || 0) + 1
            : (foundCard.deckRepostModel?.followingCounts || 0) - 1
          : count;

      foundCard.deckRepostModel?.updateFollowCounts(newCounts, following);

      return;
    }

    const newCounts =
      count === undefined
        ? following
          ? this.deckDetail.followingCounts + 1
          : this.deckDetail.followingCounts - 1
        : count;

    this.deckDetail.updateFollowCounts(newCounts, following);
  };

  @computed get reserveId(): string {
    if (this.deckDetail?.reserves && this.deckDetail.reserves[0]) {
      const reserveId = this.deckDetail.reserves[0].id.toString();
      return reserveId;
    }
    return "";
  }

  unsubscribeFns: UnsubscribeFn[] = [];

  @action.bound subscribe = flow(function* subscribe(this: DeckDetailStore) {
    if (!this.reserveId) {
      return;
    }

    this.unsubscribeFns.push(
      this.deckDetailApi.subscribeReserveCount(
        this.reserveId,
        (error, count) => {
          if (error) {
            this.fetchReserveCountDataError = translateAPIError(error);
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            this.reserveCountUpdate(count);
            this.fetchReserveCountDataError = null;
          });
        },
      ),
    );

    this.unsubscribeFns.push(
      this.deckDetailApi.subscribeReserveLeaderboard(
        this.reserveId,
        (error, reserveLeaderboardUpdated: IConsumer[] | null) => {
          if (error) {
            this.fetchLeaderboardRecentError = translateAPIError(error);
            this.theMessageStore.showMessage({
              typeMessage: "Error",
              title: "toast-message.general.error",
              content: translateAPIError(error),
            });
            return;
          }

          runInAction(() => {
            if (reserveLeaderboardUpdated) {
              this.updateRecentConsumers(reserveLeaderboardUpdated);
            }
            this.fetchLeaderboardRecentError = null;
          });
        },
      ),
    );
  });
  @action.bound unsubscribe = (): void => {
    for (const unsubscribe of this.unsubscribeFns) unsubscribe();
  };

  @action.bound addNewCard(card: Card): void {
    const newCard = this.parseCardResponseToCardItem(card);
    this.cards.unshift(newCard);
    this.deckDetail?.updateCardsCount(this.cards.length);
    this.filterArchivedCard();
  }

  @action.bound filterArchivedCard(): void {
    if (this.cards.length > MAX_CARDS_IN_DECK) {
      this.cards.replace(this.cards.slice(0, MAX_CARDS_IN_DECK));
    }
  }

  @computed get isGroupToken(): boolean {
    return !!this.userSessionStore.isGroupToken;
  }

  @computed get isLoggedinUser(): boolean {
    if (!this.userSessionStore.user?.id || !this.userProfile?.id) {
      return false;
    }
    return this.userProfile.id === this.userSessionStore.user.id;
  }

  @action.bound openModalInfoGroup(): void {
    this.modalStore.openLazyModal({
      name: "infoGroup",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../../components/ModalInfoGroup/ModalInfoGroup")
          }
          driver={this}
        />
      ),
    });
  }

  @action.bound openModalCreateGroup(): void {
    if (!this.userSessionStore.user?.emailAddressVerified) {
      this.theMessageStore.showMessage(
        {
          typeMessage: "Action",
          status: ToastMessageStatus.WARNING,
          title: "toast-message.email-not-verified.title",
          content: "toast-message.email-not-verified.content",
          actions: [
            {
              key: "close",
              name: "Close",
              action: () => this.theMessageStore.close(),
            },
            {
              key: "resend",
              name: "toast-message.general.action-resend",
              action: () => this.userSessionStore.resendVerificationEmail(),
            },
          ],
        },
        { closeDuration: "never" },
      );
      return;
    }

    this.modalStore.openLazyModal({
      name: "createGroup",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../../components/ModalCreateGroup/ModalCreateGroup")
          }
          callbacks={this}
        />
      ),
    });
  }

  @action.bound openModalPublishGroup(): void {
    this.modalStore.openLazyModal({
      name: "publishGroup",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../../components/ModalPublishGroup/ModalPublishGroup")
          }
          driver={this}
        />
      ),
    });
    this.isRequestingPublishGroup = true;
  }

  @action.bound publishGroup = flow(function* (this: DeckDetailStore) {
    try {
      // * publish group
      yield this.groupApi.publishGroup(this.userProfile?.group?.id || "");

      // * update UI
      if (this.isGroupToken) {
        this.userProfile?.setPublishStatus(true);
        this.userSessionStore.user?.setPublishStatus(true);
      }

      // * show toast message
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.group-published.title",
        content: "toast-message.group-published.content",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.modalStore.openModal("");
      this.isRequestingPublishGroup = true;
    }
  });

  @action.bound openModalPublishGroupDonation(): void {
    this.modalStore.openLazyModal({
      name: "donateToPublishGroup",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../../components/ModalDonation/ModalPublishGroupDonation")
          }
          driver={null}
        />
      ),
    });
  }

  @action.bound onFetchUserCounts = flow(function* onFetchUserCounts(
    this: DeckDetailStore,
    options?: ApiOption,
  ) {
    try {
      const res = yield this.deckDetailApi.fetchUserCountsById(
        this.userProfile?.id || "",
        {
          clearCache: options?.clearCache,
        },
      );

      this.userCounts = res;

      if (this.userProfile) {
        this.userProfile.updateUserCounts(res);
      }
    } catch (error) {
      this.fetchPageDataError = translateAPIError(error);
    }
  });

  @action.bound switchToken = flow(function* (
    this: DeckDetailStore,
    redirect: () => void,
    groupId?: string,
  ) {
    const payload: TokenSwitchPayload = {
      targetType: this.isGroupToken ? TokenType.USER : TokenType.GROUP,
      refreshToken: this.tokenStore.getRefreshToken(),
      groupId,
    };

    this.userSessionStore.switchToken(payload, redirect);
  });

  @action.bound updateAfterEditCard(spliceId: string, newCard: Card): void {
    const foundCardIndex = this.cards.findIndex((item) => item.id === spliceId);
    if (foundCardIndex === -1) {
      return;
    }
    if (
      newCard.state?.toLowerCase() === CardState.DRAFTING ||
      this.cards[foundCardIndex].deckName !== newCard.deck?.name
    ) {
      this.cards.splice(foundCardIndex, 1);
      this.deckDetail?.updateCardsCount(this.cards.length);
      return;
    }
    const updatedCard =
      newCard.contentType === CardType.EDITABLE
        ? new CardItemEditablePresenter(
            newCard,
            this.modalStore,
            this.deckDetailApi,
            this,
            this.theMessageStore,
            this.userSessionStore,
            this.modalDeleteCardStore,
            this.featureFlagging,
            this,
            "deck-detail",
          )
        : newCard.contentType === CardType.EDITABLE_REPOST
        ? new CardItemEditableRepostPresenter(
            newCard,
            this.modalStore,
            this.deckDetailApi,
            this,
            this.theMessageStore,
            this.userSessionStore,
            this.modalDeleteCardStore,
            this.featureFlagging,
            this,
            "deck-detail",
          )
        : newCard.contentType === CardType.ACTION
        ? new CardActionModel(
            newCard,
            this.modalStore,
            this.userSessionStore,
            this.deckDetailApi,
            this,
            this.theMessageStore,
            this.modalDeleteCardStore,
            this.featureFlagging,
            this,
          )
        : newCard.contentType === CardType.INFO
        ? new CardInfoModel(
            newCard,
            this.modalStore,
            this.deckDetailApi,
            this,
            this.theMessageStore,
            this.userSessionStore,
            this.modalDeleteCardStore,
            this.featureFlagging,
            this,
            "deck-detail",
          )
        : newCard.contentType === CardType.PROMOTION_REPOST
        ? new CardPromotionRepostModel(
            newCard,
            this.userSessionStore,
            this.donateStore,
            this.modalStore,
            this.userProfile,
            this.cardPromotionConnector,
            this.modalDeleteCardStore,
            this.featureFlagging,
            this.createCardAPI,
            this.profileApi,
            null,
            this.theMessageStore,
            CardItemPromotionLocation.PROFILE,
          )
        : new CardRegularModel(
            newCard,
            this.modalStore,
            this.theMessageStore,
            this.userSessionStore,
            this.modalDeleteCardStore,
            this,
            this.featureFlagging,
            this.deviceStore,
            this.deckDetailApi,
          );

    this.cards.splice(foundCardIndex, 1, updatedCard);
  }

  @action.bound submitAutoplay = flow(function* submitAutoplay(
    this: DeckDetailStore,
    sourceDeckId: string,
    targetDeckId: string,
    numberOfCards: number,
    status: AutoplayStatus,
  ) {
    try {
      yield this.autoplayDeckApi.submitAutoplay(
        sourceDeckId,
        targetDeckId,
        numberOfCards,
        status,
      );
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "Success",
        content: "Start autoplay deck successfully.",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });
  @action.bound getAutoplayItem = flow(function* getAutoplayItem(
    this: DeckDetailStore,
    sourceDeckId: string,
  ) {
    if (!this.userSessionStore.user) {
      return;
    }
    try {
      const res: SourceDeckItemResponse[] =
        yield this.autoplayDeckApi.getAutoplayItem(sourceDeckId);
      this.deckDetail?.updateAutoplayStatus(res[0].autoplayStatus);
      this.deckDetail?.updateAutoplayTargetDeckId(res[0].targetId || "");
      this.cardDeckOverView?.updateAutoplayStatus(res[0].autoplayStatus);
      this.cardDeckOverView?.updateAutoplayTargetDeckId(res[0].targetId || "");
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound cancelAutoplay = flow(function* cancelAutoplay(
    this: DeckDetailStore,
    sourceDeckId: string,
    targetDeckId: string,
  ) {
    try {
      yield this.autoplayDeckApi.cancelAutoplay(sourceDeckId, targetDeckId);
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "Success",
        content: "Cancel autoplay deck successfully.",
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @computed get isCurrentUser(): boolean {
    return !!this.deckDetail?.currentUser;
  }

  @computed get isDeckShortcutEnabled(): boolean {
    return this.featureFlagging.flags.enableDeckShortcuts;
  }

  @action.bound fetchDeckShortcuts = flow(function* fetchDeckShortcuts(
    this: DeckDetailStore,
  ) {
    if (!this.isLoggedinUser || !this.isDeckShortcutEnabled) {
      return;
    }

    try {
      const shortcutsResponse = yield* toFlowGeneratorFunction(
        this.profileApi.getDeckShortcuts,
      )(this.userProfile?.vanityName || "");
      const index = shortcutsResponse.data.findIndex((shortcut) => {
        if (shortcut.type !== ShortcutType.DECK) {
          return false;
        }

        return shortcut.id.toString() === this.deckDetail?.id.toString();
      });
      if (index > -1) {
        this.deckDetail?.updateShortcut(true); // update shortcut for deck header card
        this.cardDeckOverView?.updateShortcut(true); // update shortcut for card deck overview
      }
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound saveDeckShortcut = flow(function* saveDeckShortcut(
    this: DeckDetailStore,
    deckId: string,
    isShortcut: boolean,
  ) {
    if (!this.isLoggedinUser) {
      return;
    }

    try {
      const shortcut = yield this.profileApi.saveDeckShortcut(
        this.userProfile?.vanityName || "",
        deckId,
        isShortcut,
        ShortcutType.DECK,
      );
      this.deckDetail?.updateShortcut(shortcut.isShortcut);

      const message = shortcut.isShortcut
        ? "Your deck is now pinned to the top of your profile page."
        : "Your deck is no longer pinned to the top of your profile page.";
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "Success",
        content: message,
      });
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "Error",
        content: translateAPIError(error),
      });
    }
  });

  @computed.struct private get leaderboardInfo(): LeaderboardModel {
    return new LeaderboardModel(this.modalStore, this);
  }

  @computed get leaderboard(): LeaderboardModel {
    return this.leaderboardInfo;
  }

  @computed get reserve(): UonReserve | null {
    if (
      this.deckDetail &&
      this.deckDetail.reserves &&
      this.deckDetail.reserves.length > 0
    ) {
      return this.deckDetail.reserves[0];
    }
    return null;
  }

  @computed get loading(): boolean {
    return this.loadingProtectedCount;
  }

  @action.bound fetchFeaturedPromotionRepostCardCount = flow(function* (
    this: DeckDetailStore,
    cardPromotionRepost: CardPromotionRepostModel,
  ) {
    const promotionId = cardPromotionRepost.promotionId;
    const result = yield* toFlowGeneratorFunction(
      this.profileApi.fetchPromotionCounts,
    )([promotionId]);

    const count = first(result.data)?.count || 0;
    cardPromotionRepost.updateCounter(count);

    // subscribe counter
    this.unsubscribeFns.push(
      this.profileApi.subscribePromotionCount(
        cardPromotionRepost.promotionId,
        (data: Omit<PromotionCount, "__typename">) => {
          runInAction(() => {
            if (!data.count || data.id !== cardPromotionRepost.promotionId) {
              return;
            }
            cardPromotionRepost.updateCounter(data.count);
          });
        },
      ),
    );
  });

  @computed.struct
  private get localHeaderComponentDriver(): HeaderComponentDriver | null {
    if (this.deckDetail?.isChannelPage) {
      return null;
    }

    if (
      this.deckDetail &&
      this.deckDetail.reserves &&
      this.deckDetail.reserves.length > 0
    ) {
      return {
        component: HeaderComponentName.RESERVE_CARD,
        driver: this,
        userDriver: this.userProfile,
        stateDriver: this,
      };
    }

    if (this.featureCard) {
      if (this.featureCard.contentType === CardType.PROMOTION_REPOST) {
        const cardPromotionRepost = new CardPromotionRepostModel(
          this.featureCard as CardPromotionRepostResponseDto,
          this.userSessionStore,
          this.donateStore,
          this.modalStore,
          this.userProfile,
          this.cardPromotionConnector,
          this.modalDeleteCardStore,
          this.featureFlagging,
          this.createCardAPI,
          this.profileApi,
          null,
          this.theMessageStore,
          CardItemPromotionLocation.DECK,
        );

        this.fetchFeaturedPromotionRepostCardCount(cardPromotionRepost);

        return {
          component: HeaderComponentName.FEATURED_CARD,
          driver: cardPromotionRepost,
          layoutDriver: {
            cardSize: CardSize.TRIPLE,
          },
        };
      }

      const featureCardModel = new CardRegularModel(
        this.featureCard as CardRegular,
        this.modalStore,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this,
        this.featureFlagging,
        this.deviceStore,
        this.deckDetailApi,
      );

      return {
        component: HeaderComponentName.FEATURED_CARD,
        driver: featureCardModel,
        layoutDriver: {
          cardSize: CardSize.TRIPLE,
          cardLayout: featureCardModel.layoutType || undefined,
        },
      };
    }

    if (this.deckDetail) {
      return {
        component: HeaderComponentName.DECK_HEADER_CARD,
        driver: this.deckDetail,
        featureFlaggingDriver: this.featureFlagging.flags,
      };
    }

    return null;
  }

  @action.bound openModal(modalType: ModalType): void {
    this.modalStore.openModal(modalType);
  }

  @computed get headerComponentDriver(): HeaderComponentDriver | null {
    return this.localHeaderComponentDriver;
  }

  // action below is used for unit testing
  @action updateCards(cards: DeckDetailCardItem[]): void {
    this.cards.replace(cards);
  }

  parseCardResponseToCardItem(card: DeckCardItem): DeckDetailCardItem {
    if (card.contentType === CardType.PROMOTION_REPOST) {
      return new CardPromotionRepostModel(
        card,
        this.userSessionStore,
        this.donateStore,
        this.modalStore,
        this.userProfile,
        this.cardPromotionConnector,
        this.modalDeleteCardStore,
        this.featureFlagging,
        this.createCardAPI,
        this.profileApi,
        null,
        this.theMessageStore,
        CardItemPromotionLocation.DECK,
      );
    }
    if (card.contentType === CardType.EDITABLE) {
      return new CardItemEditablePresenter(
        card,
        this.modalStore,
        this.deckDetailApi,
        this,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this.featureFlagging,
        this,
        "deck-detail",
      );
    }

    if (card.contentType === CardType.EDITABLE_REPOST) {
      return new CardItemEditableRepostPresenter(
        card,
        this.modalStore,
        this.deckDetailApi,
        this,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this.featureFlagging,
        this,
        "deck-detail",
      );
    }

    if (card.contentType === CardType.ACTION) {
      return new CardActionModel(
        card,
        this.modalStore,
        this.userSessionStore,
        this.deckDetailApi,
        this,
        this.theMessageStore,
        this.modalDeleteCardStore,
        this.featureFlagging,
        this,
      );
    }

    if (card.contentType === CardType.INFO) {
      return new CardInfoModel(
        card,
        this.modalStore,
        this.deckDetailApi,
        this,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this.featureFlagging,
        this,
        "deck-detail",
      );
    }
    if (card.contentType === CardType.PROFILE_REPOST && !!card.profileRepost) {
      return new CardProfileRepostModel(
        card,
        this.modalStore,
        this.theMessageStore,
        this.modalDeleteCardStore,
        this.userSessionStore,
        this.tokenStore,
        this,
        this.featureFlagging,
        this.deviceStore,
        this.deckDetailApi,
      );
    }

    if (card.contentType === CardType.DECK_REPOST && !!card.deckRepost) {
      return new CardDeckRepostModel(
        card,
        this.modalStore,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this.autoplayDeckStore,
        this,
        this.featureFlagging,
        this.deviceStore,
        this.deckDetailApi,
      );
    }

    if (card.contentType === CardType.AUTOPLAY && !!card.autoplaySourceCard) {
      if (card.autoplaySourceCard.contentType === CardType.PROFILE_REPOST) {
        return new CardProfileRepostModel(
          card.autoplaySourceCard as CardRegular,
          this.modalStore,
          this.theMessageStore,
          this.modalDeleteCardStore,
          this.userSessionStore,
          this.tokenStore,
          this,
          this.featureFlagging,
          this.deviceStore,
          this.deckDetailApi,
        );
      }
      return new CardRegularModel(
        {
          ...card.autoplaySourceCard,
          cardAutoplayId: card.id,
          size: card.size,
          layoutType: card.layoutType,
        } as CardRegular,
        this.modalStore,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this,
        this.featureFlagging,
        this.deviceStore,
        this.deckDetailApi,
      );
    }

    if (
      card.contentType === CardType.AUTOPLAY_FOLLOWING_PERSONALIZATION &&
      !!card.autoplayFollowingPersonalization
    ) {
      return new CardRegularModel(
        {
          ...card.autoplayFollowingPersonalization,
        } as CardRegular,
        this.modalStore,
        this.theMessageStore,
        this.userSessionStore,
        this.modalDeleteCardStore,
        this,
        this.featureFlagging,
        this.deviceStore,
        this.deckDetailApi,
      );
    }

    return new CardRegularModel(
      card,
      this.modalStore,
      this.theMessageStore,
      this.userSessionStore,
      this.modalDeleteCardStore,
      this,
      this.featureFlagging,
      this.deviceStore,
      this.deckDetailApi,
    );
  }

  updateOrderOfCards(cardIds: string[]): void {
    const cards: DeckDetailCardItem[] = [];

    cardIds.map((id) => {
      const card = this.cards.find((c) => c.id === id);
      if (card) {
        cards.push(card);
      }
    });

    this.cards.replace(cards);
  }

  updateUserProfile(user: User): void {
    this.userProfile = new UserModel(
      user,
      this.userSessionStore,
      this.tokenStore,
    );
  }

  @action.bound fetchPromotionCounts = flow(function* fetchPromotionCounts(
    this: DeckDetailStore,
  ) {
    const promotions: CardPromotionRepostModel[] = this.cards.filter(
      (card) => card.contentType === CardType.PROMOTION_REPOST,
    ) as CardPromotionRepostModel[];

    if (promotions.length === 0) return;

    const promotionIds = promotions.map((promo) => promo.promotionId);

    const result = yield* toFlowGeneratorFunction(
      this.profileApi.fetchPromotionCounts,
    )(promotionIds);
    const promotionCounts: Record<string, number> = {};

    for (const promo of result.data) {
      promotionCounts[promo.id] = promo.count;
    }

    for (const promo of promotions) {
      const count = promotionCounts[promo.promotionId];
      if (!count) {
        continue;
      }

      // update counter
      promo.updateCounter(count);

      // subscribe counter
      this.unsubscribeFns.push(
        this.profileApi.subscribePromotionCount(
          promo.promotionId,
          (data: Omit<PromotionCount, "__typename">) => {
            runInAction(() => {
              if (!data.count || data.id !== promo.promotionId) {
                return;
              }
              promo.updateCounter(data.count);
            });
          },
        ),
      );
    }
  });

  @action.bound onCardOrderInputChanged = flow(function* updateCardOrder(
    this: DeckDetailStore,
    cardId: string,
    order: number,
  ) {
    const response = yield* toFlowGeneratorFunction(
      this.deckDetailApi.updateCardOrder,
    )(
      this.userSessionStore.user?.vanityName || "",
      this.getDeckId(),
      cardId,
      order,
    );

    const cardIds = response.data.map((item) => item.id.toString());
    this.updateOrderOfCards(cardIds);
  });

  @action.bound getCardOrder(cardId: string): number | null {
    const foundIndex = this.cards.findIndex((card) => card.id === cardId);
    return foundIndex > -1 ? foundIndex + 1 : null;
  }

  @computed.struct
  get localCardPromotionConnector(): CardPromotionRepostPresenterDependencies {
    return {
      pageFeatureCardId: this.featureCard?.id.toString(),
      onDeleteCard: this.onDeleteCard,
      onCardSizeChanged: this.updateFeatureCard,
      onDraftingCardPromotionPublished: () => {},
      onCardOrderInputChanged: this.onCardOrderInputChanged,
      getCardOrder: this.getCardOrder,
    };
  }

  @computed get shouldShowLoadingScreen(): boolean {
    return this.isLoading || (!!this.fetchPageDataError && !isBrowser());
  }
  @computed get shouldShowErrorScreen(): boolean {
    return !!this.fetchPageDataError && isBrowser();
  }

  @computed get shouldRenderSwitcherBanner(): boolean {
    return this.isGroupToken && this.isLoggedinUser;
  }

  @computed get vanityName() {
    return this.userProfile?.vanityName || "";
  }

  @computed get shouldRenderOverlaySystemPage(): boolean {
    return this.vanityName === EARTH_TODAY_VANITY_NAME;
  }

  @computed get shouldRenderSystemPageTabs(): boolean {
    return (
      this.vanityName === EARTH_TODAY_VANITY_NAME &&
      this.overlaySystemPagePresenter.isSystemPageTabsActive
    );
  }

  @computed get shouldRenderDeckPageSubHeader(): boolean {
    if (!this.deckDetail) {
      return false;
    }
    return (
      !Object.values(ChannelPage).includes(
        this.deckDetail.linkName as ChannelPage,
      ) && this.vanityName !== EARTH_TODAY_VANITY_NAME
    );
  }

  @computed
  get cardPromotionConnector(): CardPromotionRepostPresenterDependencies {
    return this.localCardPromotionConnector;
  }

  @action.bound onOpenLoginModal(): void {
    this.theNavbarStore.onOpenLoginModal();
  }
}
