/* eslint-disable no-param-reassign */
import { UonMarkerResponseDtoData } from "@earthtoday/contract";
import { AxiosResponse } from "axios";
import { LngLat } from "mapbox-gl";

import { ICardSystem } from "../../stores/CardSystemStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { isBrowser } from "../helpers/isBrowser";
import { Card } from "../models/Card";
import { UonCard } from "../models/UonCard";
import { User } from "../models/User";
import { UserSessionApi } from "./UserSessionApi";

export interface IUonCardDetailApi {
  fetchCardDetail(vanityName: string, transactionId: string): Promise<UonCard>;
  fetchCardReposts(transactionId: string): Promise<Card[]>;
  fetchCardRelated(
    transactionId: string,
  ): Promise<{ cards: Card[]; singleDeck: boolean }>;
  fetchCardDonatorBrand(transactionId: string): Promise<ICardSystem>;
  fetchCardProfileNPO(uonCardId: string): Promise<User>;
  findUonMarkersByViewport(
    uonId: string,
    reserveId: string,
    viewport: LngLat[],
  ): Promise<UonMarkerResponseDtoData[]>;
}

export class UonCardDetailApi implements IUonCardDetailApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private userSessionApi: UserSessionApi,
  ) {}

  fetchCardDetail = async (
    vanityName: string,
    transactionId: string,
  ): Promise<UonCard> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/${vanityName}/cards/uon-${transactionId}?metaTags=${!isBrowser()}`,
    });

    return res.data;
  };

  fetchCardReposts = async (transactionId: string): Promise<Card[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/uon-${transactionId}/reposts?count=52`,
    });

    return res.data.cards;
  };

  fetchCardRelated = async (
    transactionId: string,
  ): Promise<{ cards: Card[]; singleDeck: boolean }> => {
    const res: AxiosResponse = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/uon-${transactionId}/related?count=52`,
    });

    return res.data;
  };

  // for collector User only
  fetchCardDonatorBrand = async (
    transactionId: string,
  ): Promise<ICardSystem> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/donatorBrand/uon-${transactionId}`,
    });

    return res.data;
  };

  fetchCardProfileNPO = async (uonCardId: string): Promise<User> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/uons/cards/${uonCardId}/npo`,
    });

    return res.data;
  };

  findUonMarkersByViewport = async (
    uonId: string,
    reserveId: string,
    viewport: LngLat[],
  ): Promise<UonMarkerResponseDtoData[]> => {
    const viewportString = JSON.stringify(
      viewport.map((item) => [item.lat, item.lng]),
    );
    const response =
      await this.tokenInterceptorStore.tsRestClient.uonMarkerContract.findUonMarkersByViewport(
        {
          params: { reserveId },
          query: { viewport: viewportString, excludeUonId: uonId },
        },
      );

    if (response.status === 200) {
      return response.body.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };
}
