import { parseResourceByInternalUrl } from "@earthtoday/fe-shared-code";
import Router from "next/router";

import {
  ActionCardUrlExternal,
  ActionCardUrlInternal,
  CardActionTypeOfAction,
} from "../components/CardItemAction/CardItemAction";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { OneFlowInitialState } from "../components/ModalOneFlow/ModalOneFlow";
import { deepLinkHost, host } from "../shared/env";
import { getValidHttpUrl } from "../shared/helpers/getValidHttpUrl";
import { initDataFromURLSpecificOneFlow } from "../shared/helpers/initDataFromURLSpecificOneFlow";
import { isValidOneFlowType } from "../shared/helpers/isValidOneFlowType";
import { OneFlowActAs } from "../shared/models/OneFlow";
import { IModalStore } from "./ModalStore";

export function parseAppUrl(url: string) {
  const parts = url.split("/");
  const pathAndQueryParams = parts
    .slice(url.startsWith("http") ? 3 : 2)
    .join("/");

  if (!pathAndQueryParams) {
    const queryParts = url.split("?");
    if (queryParts[1]) {
      return {
        pathAndQueryParams: "/?" + queryParts[1],
      };
    }

    return {
      pathAndQueryParams: "/",
    };
  }

  return {
    pathAndQueryParams: "/" + pathAndQueryParams,
  };
}

export type UrlInternalParsed = {
  path: string[];
  queryParams: Record<string, string>;
  oneFlowInitialState: OneFlowInitialState | null;
};

export function parseOneFlowParams(
  urlPath: string,
  query: URLSearchParams,
): {
  oneFlowInitialState?: OneFlowInitialState;
  remainingQueryParams: URLSearchParams;
} {
  const oneFlowType = query.get("oneFlowType")?.toString().toUpperCase();
  if (!isValidOneFlowType(oneFlowType)) {
    return {
      remainingQueryParams: query,
    };
  }

  const actAs = query.get("actAs")
    ? (query.get("actAs")?.toString().toUpperCase() as OneFlowActAs)
    : undefined;
  const giftsCount = query.get("giftsCount")
    ? Number.parseInt(query.get("giftsCount")?.toString() || "")
    : undefined;
  const protectSize = query.get("protectSize")
    ? Number.parseInt(query.get("protectSize")?.toString() || "")
    : undefined;
  const gifting = query.get("gifting")?.toString().toLowerCase() === "true";

  const initData = initDataFromURLSpecificOneFlow({
    urlPath,
    oneFlowType,
    actAs,
    gifting,
    giftsCount,
    protectSize,
    redirectPath: query.get("redirectPath")?.toString(),
    redirectMode:
      query.get("redirectMode")?.toString() === "onModalOpen"
        ? "onModalOpen"
        : "onPaymentSuccessAndClose",
  });

  query.delete("oneFlowType");
  query.delete("actAs");
  query.delete("giftsCount");
  query.delete("protectSize");
  query.delete("gifting");
  query.delete("redirectPath");
  query.delete("redirectMode");

  return {
    remainingQueryParams: query,
    oneFlowInitialState: initData,
  };
}

export function parseUrlInternal(url: string): UrlInternalParsed {
  const baseUrl = "http://example.com"; // can be any domain
  const urlObj = new URL(url, baseUrl);

  // Extracting the pathname and removing leading slash
  const path = urlObj.pathname
    .slice(1)
    .split("/")
    .filter((item) => item.length > 0);

  const { searchParams } = urlObj;

  const query: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) {
    query[key] = value;
  }

  const { oneFlowInitialState } = parseOneFlowParams(
    urlObj.pathname,
    searchParams,
  );

  return {
    path,
    queryParams: query,
    oneFlowInitialState: oneFlowInitialState || null,
  };
}

export function parseUrlToActionResource(data: {
  url: string;
}): ActionCardUrlInternal | ActionCardUrlExternal {
  const url = getValidHttpUrl(data.url);
  if (
    !url.startsWith(host) &&
    !url.startsWith(deepLinkHost) &&
    !url.startsWith("https://et.urbn8.io") // NOTE: small cheat so that staging data works on local because host would be http://localhost:4200
  ) {
    return {
      type: CardActionTypeOfAction.URL_EXTERNAL,
      url,
    };
  }

  const { nextjsAsPath, nextjsHrefPath } = parseResourceByInternalUrl({
    internalUrl: url,
  });

  return {
    type: CardActionTypeOfAction.URL_INTERNAL,
    nextjsHrefPath,
    nextjsAsPath,
  };
}

export async function handleDeepLinkClicked(
  data: {
    actionResource: ActionCardUrlInternal | ActionCardUrlExternal;
    linkTarget?: "_blank" | "_parent";
  },
  modalStore: IModalStore,
) {
  if (data.actionResource.type === CardActionTypeOfAction.URL_EXTERNAL) {
    if (data.linkTarget === "_blank") {
      window.open(data.actionResource.url, "_blank");
    } else {
      window.location.href = data.actionResource.url;
    }
    return;
  }

  const pathAndQueryParams = data.actionResource.nextjsAsPath;

  const urlInternalParsed = parseUrlInternal(pathAndQueryParams);

  if (urlInternalParsed.oneFlowInitialState) {
    const initData = urlInternalParsed.oneFlowInitialState;
    modalStore.openLazyModal({
      name: "oneFlow",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalOneFlow/ModalOneFlow")
          }
          initialState={initData}
        />
      ),
    }); // oneflow modal would handle the redirection
    return;
  }

  const { path } = urlInternalParsed;

  Router.push("/" + path.join("/"));
}
