import { action, makeObservable, observable } from "mobx";

import { CardType } from "../../shared/models/Card";
import { CardActionModel } from "../../stores/CardActionModel/CardActionModel";
import { CardDeckRepostModel } from "../../stores/CardDeckRepostModel";
import { CardHomeModel } from "../../stores/CardHomeModel";
import { CardInfoModel } from "../../stores/CardInfoModel/CardInfoModel";
import { CardItemEditablePresenter } from "../../stores/CardItemEditablePresenter/CardItemEditablePresenter";
import { CardItemEditableRepostPresenter } from "../../stores/CardItemEditablePresenter/CardItemEditableRepostPresenter";
import { CardModel } from "../../stores/CardModel";
import { CardProfilePageModel } from "../../stores/CardProfilePageModel";
import { CardProfileRepostModel } from "../../stores/CardProfileRepostModel";
import { CardRegularModel } from "../../stores/CardRegularModel";
import { OpenCardTypeEditablePreseter } from "../../stores/OpenCardTypeEditablePresenter/OpenCardTypeEditablePresenter";
import { RootStore } from "../../stores/rootStore";

export interface IModalDeleteCardStore {
  card:
    | CardModel
    | CardHomeModel
    | CardRegularModel
    | CardProfilePageModel
    | CardActionModel
    | CardInfoModel
    | CardItemEditablePresenter
    | CardItemEditableRepostPresenter
    | OpenCardTypeEditablePreseter
    | null;
  updateCardDelete(
    card:
      | CardModel
      | CardHomeModel
      | CardRegularModel
      | CardProfilePageModel
      | CardDeckRepostModel
      | CardProfileRepostModel
      | CardActionModel
      | CardInfoModel
      | CardItemEditablePresenter
      | CardItemEditableRepostPresenter
      | OpenCardTypeEditablePreseter
      | null,
  ): void;
  onDelete(): void;
  onClose(): void;
}

export class ModalDeleteCardStore implements IModalDeleteCardStore {
  @observable card:
    | CardModel
    | CardHomeModel
    | CardRegularModel
    | CardProfilePageModel
    | CardActionModel
    | CardInfoModel
    | CardItemEditablePresenter
    | CardItemEditableRepostPresenter
    | OpenCardTypeEditablePreseter
    | null = null;

  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  @action updateCardDelete = (
    card:
      | CardModel
      | CardHomeModel
      | CardRegularModel
      | CardProfilePageModel
      | CardActionModel
      | CardItemEditablePresenter
      | CardItemEditableRepostPresenter
      | OpenCardTypeEditablePreseter
      | null,
  ): void => {
    this.card = card;
  };

  @action onDelete = (): void => {
    if (this.card && this.card.contentType === CardType.ACTION) {
      this.card.cardContextMenuDriver.onDeleteCard();
      this.onClose();
      return;
    }
    if (this.card && this.card.contentType === CardType.INFO) {
      this.card.cardContextMenuDriver.onDeleteCard();
      this.onClose();
      return;
    }
    if (this.card && this.card.contentType === CardType.EDITABLE) {
      this.card.cardContextMenuDriver.onDeleteCard();
      this.onClose();
      return;
    }

    this.card?.onDeleteCard();
    this.onClose();
  };

  @action onClose = (): void => {
    this.rootStore.modalStore.openModal("");
  };
}
