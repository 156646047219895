/* eslint-disable @typescript-eslint/no-throw-literal */
import {
  OneFlowFinalizeResponseDto,
  OneFlowFinalizeResponseErrorDto,
  OneFlowPaymentPreparePayloadDto,
  OneFlowPaymentPrepareSuccessResponseDto,
  PaymentMethods,
  PaymentResponse,
  PaymentResponseDto,
  PaymentResponseWithoutUonsDto,
} from "@earthtoday/contract";

import { Bank } from "../../stores/DonateStore/DonateStore";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { ITokenStore } from "../../stores/TokenStore";
import { getAPIBaseUrl } from "../env";
import { getGraphqlClient } from "../graphqlClient";
import { isBrowser } from "../helpers/isBrowser";
import {
  OneFlowAccountPreparePayload,
  OneFlowAccountPrepareResponse,
  OneFlowPaymentPreparePayload,
  OneFlowPaymentPrepareResponse,
  OneFlowPaymentRetryPayload,
} from "../models/OneFlow";
import { PaymentResponseStripe } from "../models/Payment";
import { snowplowCaptureUonProtectedEvent } from "../snowplow";
import { userUonCardCreatedSubscription } from "./profileApiQueries";
import { UnsubscribeFn } from "./UnsubscribeFn";

export enum PaymentProvider {
  STRIPE = "STRIPE",
  MOLLIE = "MOLLIE",
}
export enum PaymentMethodName {
  MOLLIE_IDEAL = "MOLLIE_IDEAL",
  MOLLIE_CREDITCARD = "MOLLIE_CREDITCARD",
  STRIPE_CREDITCARD = "STRIPE_CREDITCARD",
}
export enum PaymentMethodHealthStatus {
  OK = "OK",
  ERROR = "ERROR",
}

export enum SourceType {
  CHARITY = "CHARITY",
  CAMPAIGN = "CAMPAIGN",
  PROTECT = "PROTECT",
  GROUP_PUBLISH = "GROUP_PUBLISH",
  NPO = "NPO",
  RESERVE = "RESERVE",
}

type SourcePayloadCampaign = {
  sourceType: SourceType.CAMPAIGN;
  campaignName: string;
  sourceId?: string;
};
type SourcePayloadCharity = {
  sourceType: SourceType.CHARITY;
  charityName: string;
  sourceId?: string;
};

type SourcePayloadFounder = {
  sourceId?: string;
  sourceType:
    | SourceType.PROTECT
    | SourceType.GROUP_PUBLISH
    | SourceType.NPO
    | SourceType.RESERVE;
};

export type SourcePayload =
  | SourcePayloadCampaign
  | SourcePayloadCharity
  | SourcePayloadFounder;

export type PaymentMethodResponse = {
  provider: PaymentProvider;
  paymentMethodName: PaymentMethodName;
  healthStatus: PaymentMethodHealthStatus;
};

export type OneFlowFinalizePartialSuccessResponse = {
  status: 206;
  body: OneFlowFinalizeResponseErrorDto;
};
export function isOneFlowFinalizePartialSuccessResponse(
  response: any,
): response is OneFlowFinalizePartialSuccessResponse {
  return (
    response &&
    response.status === 206 &&
    response.body &&
    response.body.hasOwnProperty("errorResponse")
  );
}

export interface IPaymentApi {
  preparePaymentMollieProtect: (
    numberOfUon: number,
    currentUrl: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;
  preparePaymentMollieNpo: (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;
  preparePaymentMollieReserve: (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
  ) => Promise<PaymentResponse>;
  preparePaymentStripeProtect: (
    numberOfUon: number,
    promotionId?: string,
  ) => Promise<PaymentResponse>;
  preparePaymentMollieCharity: (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;
  preparePaymentStripeNpo: (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;
  preparePaymentStripeReserve: (
    numberOfUon: number,
    sourceId: string,
  ) => Promise<PaymentResponse>;
  preparePaymentStripeCharity: (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;
  preparePaymentStripeCampaign: (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;

  preparePaymentStripePublishGroup: (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ) => Promise<PaymentResponse>;

  getPaymentResponseStripe: (id: string) => Promise<PaymentResponseStripe>;
  getPaymentResponseMollie: (
    id: string,
    token: string,
  ) => Promise<{
    res: PaymentResponseWithoutUonsDto | PaymentResponseDto;
    authorization: string;
  }>;
  getListPaymentMollie: () => Promise<Bank[]>;

  preparePaymentMollieCampaign(
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse>;

  preparePaymentMollieToPublishGroup(
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse>;

  prepareAccountOneFlow(
    payload: OneFlowAccountPreparePayload,
  ): Promise<OneFlowAccountPrepareResponse>;

  preparePaymentOneFlow(
    oneFlowId: string,
    payload: OneFlowPaymentPreparePayload,
  ): Promise<OneFlowPaymentPrepareSuccessResponseDto>;
  retryPreparePaymentOneFlow(
    oneFlowId: string,
    payload: OneFlowPaymentRetryPayload,
  ): Promise<OneFlowPaymentPrepareResponse>;
  getPaymentResponseMollieOneFlow(
    oneFlowId: string,
    token: string,
  ): Promise<OneFlowFinalizeResponseDto>;

  getPaymentResponseStripeOneFlow(
    oneFlowId: string,
  ): Promise<OneFlowFinalizeResponseDto>;
  getPaymentMethods(): Promise<PaymentMethodResponse[]>;
  subscribeOnceUonCardCreated(userId: string): void;
}

export class PaymentApi implements IPaymentApi {
  constructor(
    private tokenInterceptorStore: TokenInterceptorStore,
    private tokenStore: ITokenStore,
  ) {}

  preparePaymentMollieProtect = async (
    numberOfUon: number,
    currentUrl: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const molliePayload = {
      numberOfUon,
      method: PaymentMethods.IDEAL,
      redirectUrl: currentUrl,
      sourceType: SourceType.PROTECT,
      promotionId,
    };
    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: molliePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentStripeProtect = async (
    numberOfUon: number,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const stripePayload = {
      numberOfUon,
      method: PaymentMethods.CREDIT_CARD,
      sourceType: SourceType.PROTECT,
      promotionId,
    };
    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: stripePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentMollieCampaign = async (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const molliePayload = {
      numberOfUon,
      method: PaymentMethods.IDEAL,
      redirectUrl: currentUrl,
      sourceId,
      sourceType: SourceType.CAMPAIGN,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: molliePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentMollieNpo = async (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const molliePayload = {
      numberOfUon,
      method: PaymentMethods.IDEAL,
      redirectUrl: currentUrl,
      sourceId,
      sourceType: SourceType.NPO,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: molliePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentMollieReserve = async (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
  ): Promise<PaymentResponse> => {
    const molliePayload = {
      numberOfUon,
      method: PaymentMethods.IDEAL,
      redirectUrl: currentUrl,
      sourceId,
      sourceType: SourceType.RESERVE,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: molliePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentMollieToPublishGroup = async (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const molliePayload = {
      numberOfUon,
      method: PaymentMethods.IDEAL,
      redirectUrl: currentUrl,
      sourceId,
      sourceType: SourceType.GROUP_PUBLISH,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: molliePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentStripeNpo = async (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const stripePayload = {
      numberOfUon,
      method: PaymentMethods.CREDIT_CARD,
      sourceId,
      sourceType: SourceType.NPO,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: stripePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentStripeReserve = async (
    numberOfUon: number,
    sourceId: string,
  ): Promise<PaymentResponse> => {
    const stripePayload = {
      numberOfUon,
      method: PaymentMethods.CREDIT_CARD,
      sourceId,
      sourceType: SourceType.RESERVE,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: stripePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentStripeCharity = async (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const stripePayload = {
      numberOfUon,
      method: PaymentMethods.CREDIT_CARD,
      sourceId,
      sourceType: SourceType.CHARITY,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: stripePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentStripeCampaign = async (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const stripePayload = {
      numberOfUon,
      method: PaymentMethods.CREDIT_CARD,
      sourceId,
      sourceType: SourceType.CAMPAIGN,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: stripePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentStripePublishGroup = async (
    numberOfUon: number,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const stripePayload = {
      numberOfUon,
      method: PaymentMethods.CREDIT_CARD,
      sourceId,
      sourceType: SourceType.GROUP_PUBLISH,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: stripePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  preparePaymentMollieCharity = async (
    numberOfUon: number,
    currentUrl: string,
    sourceId: string,
    promotionId?: string,
  ): Promise<PaymentResponse> => {
    const molliePayload = {
      numberOfUon,
      method: PaymentMethods.IDEAL,
      redirectUrl: currentUrl,
      sourceId,
      sourceType: SourceType.CHARITY,
      promotionId,
    };

    const response =
      await this.tokenInterceptorStore.tsRestClient.payment.preparePayment({
        body: molliePayload,
      });

    if (response.status === 200) {
      return response.body;
    }

    throw response;
  };

  getPaymentResponseStripe = async (
    id: string,
  ): Promise<PaymentResponseStripe> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/payments/stripe/${id}`,
    });

    return res.data;
  };

  getPaymentResponseMollie = async (
    id: string,
    token: string,
    npoId?: string,
  ): Promise<{
    res: PaymentResponseWithoutUonsDto | PaymentResponseDto;
    authorization: string;
  }> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/payments/mollie/${id}`,
      data: { token, npoId },
    });
    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return {
      res: response.data,
      authorization: response.headers.authorization || "",
    };
  };

  getListPaymentMollie = async (): Promise<Bank[]> => {
    const res = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/payments/mollie/ideal/issuers`,
      data: {},
    });

    return res.data.issuers;
  };

  prepareAccountOneFlow = async (
    payload: OneFlowAccountPreparePayload,
  ): Promise<OneFlowAccountPrepareResponse> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/oneflow/accounts/prepare`,
      data: payload,
    });
    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });

    return response.data;
  };

  preparePaymentOneFlow = async (
    oneFlowId: string,
    payload: OneFlowPaymentPreparePayloadDto,
  ): Promise<OneFlowPaymentPrepareSuccessResponseDto> => {
    const res =
      await this.tokenInterceptorStore.tsRestClient.oneFlow.preparePayment({
        params: {
          oneFlowId,
        },
        body: payload,
      });

    if (res.status == 200) {
      return res.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };

  retryPreparePaymentOneFlow = async (
    oneFlowId: string,
    payload: OneFlowPaymentRetryPayload,
  ): Promise<OneFlowPaymentPrepareResponse> => {
    const response = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/oneflow/${oneFlowId}/payments/retry`,
      data: payload,
    });

    const respHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(response.headers)) {
      respHeaders[key] = value.toString();
    }

    this.tokenStore.setTokensFromResponse({ headers: respHeaders });
    return response.data;
  };

  getPaymentResponseStripeOneFlow = async (
    oneFlowId: string,
  ): Promise<OneFlowFinalizeResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.oneFlow.oneFlowFinalize({
        params: {
          oneFlowId,
          provider: "stripe",
        },
        body: { token: "" },
      });

    if (response.status === 200) {
      return response.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };

  getPaymentResponseMollieOneFlow = async (
    oneFlowId: string,
    token: string,
  ): Promise<OneFlowFinalizeResponseDto> => {
    const response =
      await this.tokenInterceptorStore.tsRestClient.oneFlow.oneFlowFinalize({
        params: {
          oneFlowId,
          provider: "mollie",
        },
        body: {
          token,
        },
      });

    if (response.status === 200) {
      return response.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw response;
  };

  getPaymentMethods = async (): Promise<PaymentMethodResponse[]> => {
    const res = await this.tokenInterceptorStore.call({
      method: "GET",
      url: `${getAPIBaseUrl()}/payment-providers`,
    });
    return res.data;
  };

  subscribeOnceUonCardCreated = (userId: string): void => {
    if (!isBrowser()) return;

    let disconnect: UnsubscribeFn = () => {};

    const observable = getGraphqlClient()
      .subscribe<{
        userUonCardCreated: {
          id: string;
          paymentMethod: string;
          numberOfUon: number;
          protectedBy: string;
          registeredTo: string;
          sponsoredBy: string;
        };
      }>({
        query: userUonCardCreatedSubscription() as any,
        variables: {
          userId,
        },
        context: {
          tokenInterceptorStore: this.tokenInterceptorStore,
        },
      })
      .subscribe((result) => {
        if (Array.isArray(result.errors) && result.errors.length > 0) {
          return;
        }
        const userUonCardCreated = result.data?.userUonCardCreated;
        if (userUonCardCreated) {
          const {
            numberOfUon,
            paymentMethod,
            protectedBy,
            registeredTo,
            sponsoredBy,
          } = userUonCardCreated;
          const dataEvent = {
            uonValue: numberOfUon,
            paymentMethod,
            wallet: userId,
            protectedBy,
            sponsoredBy,
            registeredTo,
          };
          snowplowCaptureUonProtectedEvent(dataEvent);
        }
        disconnect();
      });

    disconnect = () => observable.unsubscribe();
  };
}
