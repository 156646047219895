/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SplitFactory } from "@splitsoftware/splitio";
import { Treatments } from "@splitsoftware/splitio/types/splitio";
import axios from "axios";
import { action, makeObservable, observable } from "mobx";

import { UonEarthLandingPageMode } from "../../pages/welcome";
import { UserSessionStore } from "../components/ModalLogin/UserSessionStore";
import { FEATURE_FLAGS_SESSION_KEY } from "../shared/constants";
import { featureFlaggingEnabled, splitIOJavascriptSDKKey } from "../shared/env";
import { FlagKey } from "../shared/FeatureFlagKey";
import { isBrowser } from "../shared/helpers/isBrowser";
import { isCypress } from "../shared/helpers/isCypress";
import { wait } from "../shared/helpers/wait";
import { attempt, isError } from "../shared/lodash";
import { Logger } from "../shared/models/Logger";
import { CookieConsent } from "../shared/models/User";
import { logger } from "../shared/requireCrossEnv";
import { AuthUserModel } from "./AuthUserModel";
type FeatureFlaggingClient = {
  ready: boolean;
  getTreatment(
    key: SplitIO.SplitKey,
    splitName: string,
    attributes?: SplitIO.Attributes,
  ): SplitIO.Treatment;
  getTreatments(
    splitNames: string[],
    attributes?: SplitIO.Attributes,
  ): SplitIO.Treatments;
  destroy(): Promise<void>;
};

const MAX_WAIT_TRY = 30;

export type FlagNames = {
  startCampaignEnabled: boolean;
  changePledgePhotoEnabled: boolean;
  charityProfileLearnMoreBtnEnabled: boolean;
  giveUonCardEnabled: boolean;
  enableUONCollectCodeFlow: boolean;
  enableNewHomePage: boolean;
  enableMemberGetsMemberFeature: boolean;
  excludedNpoLogo: string;
  counterFlapEffectDuration: number;
  enableCharitiesTab: boolean;
  enableShareBtnOnCardSystem: boolean;
  leaderboardEffectDuration: number;
  enableFirstContentCardInHomePage: boolean;
  enablePlayPage: boolean;
  enableCardOnboarding: boolean;
  enableAutoplayDeck: boolean;
  enableEmailGiveButton: boolean;
  enableNpoRole: boolean;
  enableCharityRole: boolean;
  enableWholesaleUonOrder: boolean;
  enableWebsocketCardEvents: boolean;
  counterFlipEffectSetInterval: number;
  counterFlipEffectTimeReroll: number;
  enableDeckShortcuts: boolean;
  enableNewProfilePage: boolean;
  enableNewCollectPage: boolean;
  enableNewEtProfile: boolean;
  enableOneFlow: boolean;
  enableAllSubscriptionCard: boolean;
  vanityNameHideSubscriptionCard: string[];
  enableApiKey: boolean;
  enableNewHomeHeaderCard: boolean;
  enableNewSearchPanel: boolean;
  enableLanguageSwitcher: boolean;
  enable2023MayCtaProtectAndSubscribeModal: boolean;
  enableCreateActionCard: boolean;
  enableCreateInfoCard: boolean;
  enableCollectibleUon: boolean;
  enableSortCardInDeck: boolean;
  enableViewGallery: boolean;
  enableMultiCurrency: boolean;
  enableGlobalCounterHeaderCardInProfilePage: boolean;
  enableNewCardMenuItemForMobile: boolean;
  uonEarthLandingPageMode: UonEarthLandingPageMode;
  uonEarthLandingPageDownloadButtons: boolean;
  enableSetBackgroundImageForDeck: boolean;
  enableSetSystemDeck: boolean;
  enableCookieBannerAtBottom: boolean;
  enableLoginWithQRCode: boolean;
  enableGroup: boolean;
  gridLandZoomThreshold: number;
  gridMarineZoomThreshold: number;
  enableUpdateDeckCardsDesign: boolean;
  enableLandingPageMobileWeb: boolean;
  enableReserveDefaultUonCollectible: boolean;
  enableCardAndContentEditor: boolean;
  enableMapboxViewAndZoom: boolean;
  enableSharingPromoCard: boolean;
};

export type FeatureFlaggingData = {
  flags: FlagNames;
  isLoadingFlags: boolean;
};

// eslint-disable-next-line import/no-default-export
export default class FeatureFlaggingStore {
  public client: FeatureFlaggingClient | null = null;

  @observable flags: FlagNames = {
    startCampaignEnabled: false,
    changePledgePhotoEnabled: false,
    charityProfileLearnMoreBtnEnabled: false,
    giveUonCardEnabled: true,
    enableUONCollectCodeFlow: true,
    enableNewHomePage: true, //set default for all user
    enableMemberGetsMemberFeature: true,
    excludedNpoLogo: "",
    counterFlapEffectDuration: 30,
    enableCharitiesTab: false, // default value for bug reload page
    enableShareBtnOnCardSystem: true,
    leaderboardEffectDuration: 0,
    enableFirstContentCardInHomePage: true,
    enablePlayPage: false,
    enableCardOnboarding: true,
    enableAutoplayDeck: true,
    enableEmailGiveButton: false,
    enableNpoRole: true,
    enableCharityRole: true,
    enableWholesaleUonOrder: true,
    enableWebsocketCardEvents: false,
    counterFlipEffectSetInterval: 20000,
    counterFlipEffectTimeReroll: 3,
    enableDeckShortcuts: false,
    enableNewProfilePage: true,
    enableNewCollectPage: false,
    enableNewEtProfile: false,
    enableOneFlow: true,
    enableAllSubscriptionCard: false,
    vanityNameHideSubscriptionCard: [""],
    enableApiKey: false,
    enableNewHomeHeaderCard: true,
    enableNewSearchPanel: true,
    enableLanguageSwitcher: false,
    enable2023MayCtaProtectAndSubscribeModal: true,
    enableCreateActionCard: true,
    enableCreateInfoCard: true,
    enableCollectibleUon: true,
    enableSortCardInDeck: true,
    enableViewGallery: true,
    enableMultiCurrency: true,
    enableGlobalCounterHeaderCardInProfilePage: true,
    enableNewCardMenuItemForMobile: true,
    uonEarthLandingPageMode: UonEarthLandingPageMode.NONE,
    uonEarthLandingPageDownloadButtons: true,
    enableSetBackgroundImageForDeck: true,
    enableSetSystemDeck: true,
    enableCookieBannerAtBottom: true,
    enableLoginWithQRCode: true,
    enableGroup: false,
    gridLandZoomThreshold: 20,
    gridMarineZoomThreshold: 17,
    enableUpdateDeckCardsDesign: true,
    enableLandingPageMobileWeb: false,
    enableReserveDefaultUonCollectible: true,
    enableCardAndContentEditor: true,
    enableMapboxViewAndZoom: true,
    enableSharingPromoCard: true,
  };

  @observable isLoadingFlags = false;

  flagsNodeJs: Treatments = {};

  dehydrate(): FeatureFlaggingData {
    return {
      flags: this.flags,
      isLoadingFlags: this.isLoadingFlags,
    };
  }

  @action.bound public hydrate(data: FeatureFlaggingData) {
    this.flags = data.flags;
    this.isLoadingFlags = data.isLoadingFlags;
  }

  constructor(private logger: Logger, featureFlagsBase64: string) {
    makeObservable(this);
    if (!featureFlagsBase64) {
      return;
    }

    const errorOrTreatments = attempt(() =>
      JSON.parse(
        isBrowser()
          ? atob(featureFlagsBase64)
          : Buffer.from(featureFlagsBase64, "base64").toString(),
      ),
    );
    if (isError(errorOrTreatments)) {
      this.logger.error(
        { err: errorOrTreatments },
        "parse featureFlagsBase64 failed",
      );
      return;
    }

    this.flagsNodeJs = errorOrTreatments;
    this.loadTreatmentsInfoFlags(errorOrTreatments);
  }

  private async init(
    userSessionStore: UserSessionStore,
    forceInitClient: boolean,
  ) {
    const key = await this.getSplitIOKey(userSessionStore.user);

    if (this.client !== null && !forceInitClient) {
      return;
    }

    if (!isBrowser()) {
      this.client = {
        ready: true,
        getTreatment: (splitName: string): SplitIO.Treatment => {
          return this.flagsNodeJs[splitName] || "off";
        },
        getTreatments: (splitNames: string[]): SplitIO.Treatments => {
          // eslint-disable-next-line unicorn/no-array-reduce, unicorn/prefer-object-from-entries
          return splitNames.reduce(
            (treatments: Treatments, splitName: string) => {
              return {
                ...treatments,
                [splitName]: this.flagsNodeJs[splitName] || "off",
              };
            },
            {},
          );
        },
        destroy: async () => {},
      };
      return;
    }

    /* clean up if client exists */
    if (this.client) {
      await this.client.destroy();
    }

    const factory: SplitIO.ISDK = SplitFactory({
      core: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        authorizationKey: splitIOJavascriptSDKKey, // Javascript SDK
        key,
        trafficType: "user",
      },
    });

    const client = factory.client();
    const { getTreatment, getTreatments } = client;

    const container: FeatureFlaggingClient = {
      ready: false,
      getTreatment,
      getTreatments,
      destroy: client.destroy,
    };
    client.on(client.Event.SDK_READY, function () {
      container.ready = true;
    });
    client.on(client.Event.SDK_UPDATE, () => {
      this.fetchFlags(userSessionStore);
    });

    this.client = container;
  }

  public async getSplitIOKey(
    user: AuthUserModel | null,
  ): Promise<"anonymous" | string> {
    if (!user) {
      const resp = await axios
        .get<{ ip: string }>("https://api.ipify.org?format=json")
        .catch((error) => {
          logger.warn(
            { err: error.message, errStack: error.stack },
            "failed to get ip address",
          );
          return { data: { ip: "" } };
        });

      if (resp?.data?.ip) {
        return "ip:" + resp?.data?.ip;
      }

      return "anonymous";
    }

    return user.vanityName;
  }

  private async waitForReady(): Promise<FeatureFlaggingClient | false> {
    if (this.client && this.client.ready) {
      return this.client;
    }

    for (let i = 0; i < MAX_WAIT_TRY; i++) {
      console.log("flag retry", this.client?.ready);
      if (this.client?.ready) {
        return this.client;
      }
      // eslint-disable-next-line no-await-in-loop
      await wait(100);
    }

    console.error("waitForReady exceeds max number of tries");
    return false;
  }
  async fetchFlags(
    userSessionStore: UserSessionStore,
    isSwitchedUser = false,
  ): Promise<void> {
    if (!featureFlaggingEnabled()) {
      this.isLoadingFlags = false;
      return;
    }

    this.isLoadingFlags = true;

    await this.init(userSessionStore, isSwitchedUser);
    const client = await this.waitForReady();
    if (!client) {
      return;
    }

    const { user } = userSessionStore;

    const splitIOUserKey = await this.getSplitIOKey(user);

    const attributes: SplitIO.Attributes = user
      ? {
          key: splitIOUserKey,
          id: user.id,
          type: user.userType,
          vanityName: user.vanityName,
          fullName: user.fullName,
          darkMode: user.darkMode,
          provider: user.provider,
          emailAddress: user.emailAddress,
          emailAddressVerified: user.emailAddressVerified,
          administrator: user.administrator,
          termsAccepted: user.termsAccepted,
          termsExpired: user.termsExpired,
          cookieConsent: user.cookieConsent || CookieConsent.BASIC,
          curator: user.curator,
          joined: user.joined.toString(),
          isPublished: !!user.isPublished,
        }
      : {
          id: splitIOUserKey,
        };

    const flagKeys = Object.values(FlagKey).map((key) => key);

    const flagTreatments = client.getTreatments(flagKeys, attributes);

    logger.info("FeatureFlaggingStore :: fetchFlags", {
      key: splitIOUserKey,
      flagTreatments,
    });

    const mockFlags = this.resolveOverrideFlags();

    (() => {
      if (!mockFlags) return;

      for (const [key, val] of Object.entries(mockFlags)) {
        flagTreatments[key] = val;
      }
    })();

    this.loadTreatmentsInfoFlags(flagTreatments);

    // ************** end loading flags
    this.isLoadingFlags = false;
  }

  @action.bound private loadTreatmentsInfoFlags(flagTreatments: Treatments) {
    if (Object.keys(flagTreatments).length === 0) {
      return;
    }

    const {
      [FlagKey.ENABLE_EARTHTODAY_CHANGE_PLEDGE_PHOTO]:
        changePledgePhotoEnabledTreatment,
      [FlagKey.ENABLE_CHARITY_PROFILE_LEARN_MORE_BTN]:
        charityProfileLearnMoreBtnEnabledTreatment,
      [FlagKey.ENABLE_GIVE_UON_CARD]: giveUonCardEnabled,
      [FlagKey.ENABLE_UON_COLLECT_CODE_FLOW]: enableUONCollectCodeFlow,
      [FlagKey.ENABLE_NEW_HOMEPAGE]: enableNewHomePage,
      [FlagKey.ENABLE_MEMBER_GETS_MEMBER_FEATURE]:
        enableMemberGetsMemberFeature,
      [FlagKey.EXCLUDED_NPO_LOGO]: excludedNpoLogo,
      [FlagKey.COUNTER_FLAP_EFFECT_DURATION]: counterFlapEffectDuration,
      [FlagKey.ENABLE_CHARITIES_TAB]: enableCharitiesTab,
      [FlagKey.ENABLE_SHARE_BTN_ON_CARD_SYSTEM]: enableShareBtnOnCardSystem,
      [FlagKey.LEADERBOARD_EFFECT_DURATION]: leaderboardEffectDuration,
      [FlagKey.ENABLE_FIRST_CONTENT_CARD_IN_HOME_PAGE]:
        enableFirstContentCardInHomePage,
      [FlagKey.ENABLE_PLAY_PAGE]: enablePlayPage,
      [FlagKey.ENABLE_CARD_ONBOARDING]: enableCardOnboarding,
      [FlagKey.ENABLE_EMAIL_GIVE_BUTTON]: enableEmailGiveButton,
      [FlagKey.ENABLE_AUTO_PLAY_DECK]: enableAutoplayDeck,
      [FlagKey.ENABLE_NPO_GROUP_ROLE]: enableNpoRole,
      [FlagKey.ENABLE_CHARITY_GROUP_ROLE]: enableCharityRole,
      [FlagKey.ENABLE_WHOLESALE_UON_ORDER]: enableWholesaleUonOrder,
      [FlagKey.ENABLE_WEBSOCKET_CARD_EVENTS]: enableWebsocketCardEvents,
      [FlagKey.COUNTER_FLIP_EFFECT_SETTIMEOUT]: counterFlipEffectSetInterval,
      [FlagKey.COUNTER_FLIP_EFFECT_TIMEREROLL]: counterFlipEffectTimeReroll,
      [FlagKey.ENABLE_DECK_SHORTCUTS]: enableDeckShortcuts,
      [FlagKey.ENABLE_NEW_PROFILE_PAGE]: enableNewProfilePage,
      [FlagKey.ENABLE_NEW_COLLECT_PAGE]: enableNewCollectPage,
      [FlagKey.ENABLE_NEW_ET_PROFILE]: enableNewEtProfile,
      [FlagKey.ENABLE_ONE_FLOW]: enableOneFlow,
      [FlagKey.ENABLE_ALL_SUBSCRIPTION_CARD]: enableAllSubscriptionCard,
      [FlagKey.VANITY_NAME_HIDE_SUBSCRIPTION_CARD]:
        vanityNameHideSubscriptionCard,
      [FlagKey.ENABLE_API_KEY]: enableApiKey,
      [FlagKey.ENABLE_NEW_HOME_HEADER_CARD]: enableNewHomeHeaderCard,
      [FlagKey.ENABLE_NEW_SEARCH_PANEL]: enableNewSearchPanel,
      [FlagKey.ENABLE_LANGUAGE_SWITCHER]: enableLanguageSwitcher,
      [FlagKey.ENABLE_2023MAY_CTA_PROTECT_AND_SUBSCRIBE_MODAL]:
        enable2023MayCtaProtectAndSubscribeModal,
      [FlagKey.ENABLE_CREATE_ACTION_CARD]: enableCreateActionCard,
      [FlagKey.ENABLE_CREATE_INFO_CARD]: enableCreateInfoCard,
      [FlagKey.ENABLE_COLLECTIBLE_UON]: enableCollectibleUon,
      [FlagKey.ENABLE_SORT_CARDS_IN_DECK]: enableSortCardInDeck,
      [FlagKey.ENABLE_VIEW_GALLERY]: enableViewGallery,
      [FlagKey.ENABLE_MULTI_CURRENCY]: enableMultiCurrency,
      [FlagKey.ENABLE_GLOBAL_COUNTER_HEADER_CARD_IN_PROFILE_PAGE]:
        enableGlobalCounterHeaderCardInProfilePage,
      [FlagKey.ENABLE_NEW_CARD_MENU_ITEM_FOR_MOBILE]:
        enableNewCardMenuItemForMobile,
      [FlagKey.UON_EARTH_LANDING_PAGE_MODE]: uonEarthLandingPageMode,
      [FlagKey.UON_EARTH_LANDING_PAGE_DOWNLOAD_APP_BUTTONS]:
        uonEarthLandingPageDownloadButtons,
      [FlagKey.ENABLE_SET_BACKGROUND_IMAGE_FOR_DECK]:
        enableSetBackgroundImageForDeck,
      [FlagKey.ENABLE_SET_SYSTEM_DECK]: enableSetSystemDeck,
      [FlagKey.ENABLE_COOKIE_BANNER_AT_BOTTOM]: enableCookieBannerAtBottom,
      [FlagKey.ENABLE_LOGIN_WITH_QR_CODE]: enableLoginWithQRCode,
      [FlagKey.ENABLE_GROUP]: enableGroup,
      [FlagKey.GRID_LAND_ZOOM_THRESHOLD]: gridLandZoomThreshold,
      [FlagKey.GRID_MARINE_ZOOM_THRESHOLD]: gridMarineZoomThreshold,
      [FlagKey.ENABLE_UPDATED_DECK_CARDS_DESIGN]: enableUpdateDeckCardsDesign,
      [FlagKey.ENABLE_LANDING_PAGE_MOBILE_WEB]: enableLandingPageMobileWeb,
      [FlagKey.ENABLE_RESERVE_DEFAULT_UON_COLLECTIBLE]:
        enableReserveDefaultUonCollectible,
      [FlagKey.ENABLE_CARD_AND_CONTENT_EDITOR]: enableCardAndContentEditor,
      [FlagKey.ENABLE_MAPBOX_VIEW_AND_ZOOM]: enableMapboxViewAndZoom,
      [FlagKey.ENABLE_SHARING_PROMO_CARD]: enableSharingPromoCard,
    } = flagTreatments;

    this.flags.changePledgePhotoEnabled =
      changePledgePhotoEnabledTreatment === "on";
    this.flags.charityProfileLearnMoreBtnEnabled =
      charityProfileLearnMoreBtnEnabledTreatment === "on";
    this.flags.giveUonCardEnabled = giveUonCardEnabled === "on";
    this.flags.enableUONCollectCodeFlow = enableUONCollectCodeFlow === "on";
    this.flags.enableNewHomePage = enableNewHomePage === "on";
    this.flags.enableMemberGetsMemberFeature =
      this.flags.enableMemberGetsMemberFeature =
        enableMemberGetsMemberFeature === "on";
    this.flags.excludedNpoLogo = excludedNpoLogo;
    this.flags.counterFlapEffectDuration = Number.isNaN(
      Number.parseInt(counterFlapEffectDuration),
    )
      ? 30
      : Number.parseInt(counterFlapEffectDuration);
    this.flags.enableCharitiesTab = enableCharitiesTab === "on";
    this.flags.enableShareBtnOnCardSystem = enableShareBtnOnCardSystem === "on";
    this.flags.leaderboardEffectDuration = Number(leaderboardEffectDuration);
    this.flags.enableFirstContentCardInHomePage =
      enableFirstContentCardInHomePage === "on";
    this.flags.enablePlayPage = enablePlayPage === "on";
    this.flags.enableCardOnboarding = enableCardOnboarding === "on";
    this.flags.enableAutoplayDeck = enableAutoplayDeck === "on";
    this.flags.enableEmailGiveButton = enableEmailGiveButton === "on";
    this.flags.enableNpoRole = enableNpoRole === "on";
    this.flags.enableCharityRole = enableCharityRole === "on";
    this.flags.enableWholesaleUonOrder = enableWholesaleUonOrder === "on";
    this.flags.enableWebsocketCardEvents = enableWebsocketCardEvents === "on";
    this.flags.counterFlipEffectSetInterval = Number.isNaN(
      Number.parseInt(counterFlipEffectSetInterval),
    )
      ? 15000
      : Number.parseInt(counterFlipEffectSetInterval);
    this.flags.counterFlipEffectTimeReroll = Number.isNaN(
      Number.parseInt(counterFlipEffectTimeReroll),
    )
      ? 3
      : Number.parseInt(counterFlipEffectTimeReroll);
    this.flags.enableDeckShortcuts = enableDeckShortcuts === "on";
    this.flags.enableNewProfilePage = enableNewProfilePage === "on";
    this.flags.enableNewCollectPage = enableNewCollectPage === "on";
    this.flags.enableNewEtProfile = enableNewEtProfile === "on";
    this.flags.enableOneFlow = enableOneFlow === "on";
    this.flags.enableAllSubscriptionCard = enableAllSubscriptionCard === "on";
    this.flags.vanityNameHideSubscriptionCard = vanityNameHideSubscriptionCard
      ?.toString()
      ?.split("__");
    this.flags.enableApiKey = enableApiKey === "on";
    this.flags.enableNewHomeHeaderCard = enableNewHomeHeaderCard === "on";
    this.flags.enableNewSearchPanel = enableNewSearchPanel === "on";
    this.flags.enableLanguageSwitcher = enableLanguageSwitcher === "on";
    this.flags.enable2023MayCtaProtectAndSubscribeModal =
      enable2023MayCtaProtectAndSubscribeModal === "on";
    this.flags.enableCreateActionCard = enableCreateActionCard === "on";
    this.flags.enableCreateInfoCard = enableCreateInfoCard === "on";
    this.flags.enableCollectibleUon = enableCollectibleUon === "on";
    this.flags.enableSortCardInDeck = enableSortCardInDeck === "on";
    this.flags.enableViewGallery = enableViewGallery === "on";
    this.flags.enableMultiCurrency = enableMultiCurrency === "on";
    this.flags.enableGlobalCounterHeaderCardInProfilePage =
      enableGlobalCounterHeaderCardInProfilePage === "on";
    this.flags.enableNewCardMenuItemForMobile =
      enableNewCardMenuItemForMobile === "on";
    this.flags.uonEarthLandingPageMode = Object.values(
      UonEarthLandingPageMode,
    ).includes(<UonEarthLandingPageMode>uonEarthLandingPageMode)
      ? <UonEarthLandingPageMode>uonEarthLandingPageMode
      : UonEarthLandingPageMode.NONE;
    this.flags.uonEarthLandingPageDownloadButtons =
      uonEarthLandingPageDownloadButtons === "on";
    this.flags.enableSetBackgroundImageForDeck =
      enableSetBackgroundImageForDeck === "on";
    this.flags.enableSetSystemDeck = enableSetSystemDeck === "on";
    this.flags.enableCookieBannerAtBottom = enableCookieBannerAtBottom === "on";
    this.flags.enableLoginWithQRCode = enableLoginWithQRCode === "on";
    this.flags.enableGroup = enableGroup === "on";
    this.flags.gridLandZoomThreshold = Number.isNaN(
      Number.parseInt(gridLandZoomThreshold),
    )
      ? 20
      : Number.parseInt(gridLandZoomThreshold);
    this.flags.gridMarineZoomThreshold = Number.isNaN(
      Number.parseInt(gridMarineZoomThreshold),
    )
      ? 17
      : Number.parseInt(gridMarineZoomThreshold);
    this.flags.enableUpdateDeckCardsDesign =
      enableUpdateDeckCardsDesign === "on";
    this.flags.enableLandingPageMobileWeb = enableLandingPageMobileWeb === "on";
    this.flags.enableReserveDefaultUonCollectible =
      enableReserveDefaultUonCollectible === "on";
    this.flags.enableCardAndContentEditor = enableCardAndContentEditor === "on";
    this.flags.enableMapboxViewAndZoom = enableMapboxViewAndZoom === "on";
    this.flags.enableSharingPromoCard = enableSharingPromoCard === "on";
  }

  private resolveOverrideFlags = (): Record<FlagKey, string> | null => {
    if (!isBrowser() || !isCypress()) return null;

    const flagsSessionBySplitName: Record<FlagKey, string> = JSON.parse(
      window.sessionStorage.getItem(FEATURE_FLAGS_SESSION_KEY) || "{}",
    );

    return flagsSessionBySplitName;
  };
}
