import {
  BackgroundImageMutationNoop,
  BackgroundImageMutationUpload,
  CardCreateResponseDto,
  CardCreationType,
  CardEditableContent,
  CardEditableRepostContent,
  CardEditableResponseDto,
  CardEditableUpdatePayloadDto,
  CardItemEditableRepostResponseDto,
  CardPromotionUpdateStatePayloadDto,
  CardResponseStandard,
  CardStatus,
  CardType,
  CardUpdateResponseDto,
  ImageTarget,
  UploadPresignResponseDto,
} from "@earthtoday/contract";
import { AxiosResponse } from "axios";

import { NewCategoryType } from "../../components/ModalCreateCard/ModalCreateCardCategories";
import { ICreateCardType } from "../../stores/CreateCardStore/CreateCardStore";
import { CardDraftEditData } from "../../stores/ProfilePagePresenter";
import { TokenInterceptorStore } from "../../stores/TokenInterceptorStore";
import { getAPIBaseUrl } from "../env";
import { formatUrl } from "../helpers/formatUrl";
import { hashFile } from "../helpers/hashedFile";
import {
  CardAction,
  CardArticleLayoutType,
  CardInfo,
  CardSize,
  CardState,
  ICardItem,
  ICardSimpleContent,
} from "../models/Card";
import { Deck } from "../models/Deck";

export type CreateCardPayload = {
  data: {
    state: "DRAFTING" | "PUBLISHED";
    content: {
      type: string;
      comment: string;
      url?: string;
      principleName?: string;
      id?: string; // card repost
      layoutType?: CardArticleLayoutType;
    };
    deckId?: string;
  };
};

export type CreateDeckPayload = {
  name: string;
  description: string;
  principleId: string;
};

export enum BackgroundImageMutationType {
  UPLOAD = "UPLOAD",
  REMOVAL = "REMOVAL",
  NOOP = "NOOP",
}

type BackgroundImageMutationUploadDto = {
  type: BackgroundImageMutationType.UPLOAD;
  uploadToken: string;
};
type BackgroundImageMutationRemovalDto = {
  type: BackgroundImageMutationType.REMOVAL;
};
type BackgroundImageMutationNoopDto = {
  type: BackgroundImageMutationType.NOOP;
};

export type BackgroundImageMutation =
  | BackgroundImageMutationUploadDto
  | BackgroundImageMutationRemovalDto
  | BackgroundImageMutationNoopDto;

type CardActionUpdateContentPayload = {
  type: typeof CardType.ACTION;
  url: string;
  title: string;
  subtitle: string;
  backgroundImageMutation: BackgroundImageMutation;
};

export enum HtmlBundleMutationType {
  UPLOAD = "UPLOAD",
  NOOP = "NOOP",
}

type HtmlBundleMutationUploadDto = {
  type: HtmlBundleMutationType.UPLOAD;
  uploadToken: string;
};
type HtmlBundleMutationNoopDto = {
  type: HtmlBundleMutationType.NOOP;
};

export type HtmlBundleMutation =
  | HtmlBundleMutationUploadDto
  | HtmlBundleMutationNoopDto;

type CardInfoUpdateContentPayload = {
  type: typeof CardType.INFO;
  title: string;
  subtitle: string;
  backgroundImageMutation: BackgroundImageMutation;
  backgroundColor: string;
  htmlBundleMutation: HtmlBundleMutation;
  enableOpenCardView: boolean;
};

// TODO: refactor enum bellow with enum CardState
export enum CardStateUpperCase {
  PUBLISHED = "PUBLISHED",
  DRAFTING = "DRAFTING",
}
export type UpdateCardActionPayload = {
  content: CardActionUpdateContentPayload;
  deckId?: string;
  state?: CardStateUpperCase;
};

export type UpdateCardInfoPayload = {
  content: CardInfoUpdateContentPayload;
  deckId?: string;
  state?: CardStateUpperCase;
};

export interface ICreateCardApi {
  fetchNewPrincipleCategories: () => Promise<NewCategoryType[]>;
  fetchCardPreview: (url: string) => Promise<ICardSimpleContent>;
  fetchDecks: () => Promise<Deck[]>;
  createDeck: (
    name: string,
    description: string,
    principleId: string,
  ) => Promise<{ deckId: string; linkName: string }>;
  createCard: (
    url: string,
    why: string,
    type: ICreateCardType,
    deckId?: string,
    draft?: boolean,
    principleId?: string,
  ) => Promise<AxiosResponse>;
  editCard: (cardId: string, data: CardDraftEditData) => Promise<ICardItem>;
  createRepostCard: (
    cardId: string,
    why: string,
    cardLayout: CardArticleLayoutType,
    deckId?: string,
    draft?: boolean,
    principleId?: string,
  ) => Promise<AxiosResponse>;

  editRepostCard: (
    cardId: string,
    deck: Deck | null,
    why: string,
    state: CardState,
    principleId?: string,
  ) => Promise<AxiosResponse>;
  updateCard: (
    cardId: string,
    body: CardPromotionUpdateStatePayloadDto,
  ) => Promise<CardResponseStandard | CardUpdateResponseDto>;
  saveCardPromotion: (
    promotionId: string,
    state: typeof CardStatus.DRAFTING | typeof CardStatus.PUBLISHED,
    deckId?: string,
  ) => Promise<CardCreateResponseDto>;
  createCardEditable: (
    deckId: string | undefined,
    state: CardStatus,
    content: CardEditableContent,
  ) => Promise<CardEditableResponseDto>;

  updateCardEditable: (
    cardId: string,
    payload: CardEditableUpdatePayloadDto,
  ) => Promise<CardEditableResponseDto>;
  createCardEditableRepost: (
    deckId: string | undefined,
    state: CardStatus,
    content: CardEditableRepostContent,
  ) => Promise<CardItemEditableRepostResponseDto>;
  updateCardEditableRepost: (
    cardId: string,
    deckId: string | undefined,
    state: CardStatus,
  ) => Promise<CardItemEditableRepostResponseDto>;
}

export class CreateCardApi implements ICreateCardApi {
  constructor(private tokenInterceptorStore: TokenInterceptorStore) {}

  fetchNewPrincipleCategories = async (): Promise<NewCategoryType[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/principles`,
    });

    return res.data;
  };

  fetchCardPreview = async (url: string): Promise<ICardSimpleContent> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/links/preview?url=${url}`,
    });

    return res.data;
  };

  fetchDecks = async (): Promise<Deck[]> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/users/me/decks/curating`,
    });

    return res.data.decks;
  };

  createDeck = async (
    name: string,
    description: string,
    principleId: string,
  ): Promise<{ deckId: string; linkName: string }> => {
    const data: CreateDeckPayload = {
      name,
      description,
      principleId,
    };

    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/decks`,
      data,
    });
    return res.data;
  };

  createCard = async (
    url: string,
    why: string,
    type: ICreateCardType = ICreateCardType.LINK,
    deckId?: string,
    draft?: boolean,
    principleName?: string,
  ): Promise<AxiosResponse> => {
    const data: CreateCardPayload = {
      data: {
        deckId,
        state: draft ? "DRAFTING" : "PUBLISHED",
        content: {
          url: formatUrl(url),
          comment: why.trim(),
          type: type.toUpperCase(),
          principleName,
        },
      },
    };

    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/cards`,
      data,
    });
    return res.data.data;
  };

  editCard = async (
    cardId: string,
    data: CardDraftEditData,
  ): Promise<ICardItem> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      method: "PUT",
      data,
    });

    return res.data;
  };

  createRepostCard = async (
    cardId: string,
    why: string,
    layoutType: CardArticleLayoutType,
    deckId?: string,
    draft?: boolean,
    principleName?: string,
  ): Promise<AxiosResponse> => {
    const data: CreateCardPayload = {
      data: {
        deckId,
        state: draft ? "DRAFTING" : "PUBLISHED",
        content: {
          type: "LINK_REPOST",
          id: cardId,
          principleName,
          comment: why.trim(),
          layoutType,
        },
      },
    };

    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/cards/`,
      data,
    });
    return res.data.data;
  };
  editRepostCard = async (
    cardId: string,
    deck: Deck | null,
    why: string,
    state: CardState,
    principleId?: string,
  ): Promise<AxiosResponse> => {
    const data = {
      comment: why,
      content: {
        type: CardType.LINK_REPOST,
      },
      deck: deck && deck.id,
      state,
      principleId,
    };

    const res = await this.tokenInterceptorStore.call({
      method: "PUT",
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      data,
    });
    return res.data;
  };

  createCardAction = async (
    deckId: string | undefined,
    state: CardState,
    url: string | null,
    title: string | null,
    subtitle: string | null,
    backgroundImageUploadToken: string | null,
  ): Promise<CardAction> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/cards`,
      data: {
        data: {
          deckId,
          state: state.toUpperCase(),
          content: {
            type: "ACTION",
            url,
            title,
            subtitle,
            backgroundImageMutation: backgroundImageUploadToken
              ? {
                  type: BackgroundImageMutationType.UPLOAD,
                  uploadToken: backgroundImageUploadToken,
                }
              : undefined,
          },
        },
      },
    });
    const card = res.data.data;
    return {
      ...card,
      size: card.size.toLowerCase(),
      state: card.state.toLowerCase(),
    };
  };

  createCardInfo = async (
    deckId: string | undefined,
    state: CardState,
    title: string | null,
    subtitle: string | null,
    backgroundImageUploadToken: string | null,
    backgroundColor: string,
    htmlBundleUploadToken: string | null,
    enableOpenCardView: boolean,
  ): Promise<CardInfo> => {
    const res = await this.tokenInterceptorStore.call({
      method: "POST",
      url: `${getAPIBaseUrl()}/cards`,
      data: {
        data: {
          deckId,
          state: state.toUpperCase(),
          content: {
            type: CardType.INFO,
            title,
            subtitle,
            backgroundColor,
            enableOpenCardView,
            htmlBundleMutation: htmlBundleUploadToken
              ? {
                  type: HtmlBundleMutationType.UPLOAD,
                  uploadToken: htmlBundleUploadToken,
                }
              : undefined,
            backgroundImageMutation: backgroundImageUploadToken
              ? {
                  type: BackgroundImageMutationType.UPLOAD,
                  uploadToken: backgroundImageUploadToken,
                }
              : undefined,
          },
        },
      },
    });
    const card = res.data.data;
    return {
      ...card,
      size: card.size.toLowerCase(),
      state: card.state.toLowerCase(),
    };
  };

  updateCard = async (
    cardId: string,
    body: CardPromotionUpdateStatePayloadDto,
  ): Promise<CardResponseStandard | CardUpdateResponseDto> => {
    const res = await this.tokenInterceptorStore.tsRestClient.card.updateCard({
      params: {
        cardId,
      },
      body,
    });

    if (res.status === 200) {
      return res.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };
  presignCardActionBackgroundImage = async (
    photo: File,
  ): Promise<UploadPresignResponseDto> => {
    const fileName = photo.name;
    const hashedFile = await hashFile(photo);

    const resPresignImage =
      await this.tokenInterceptorStore.tsRestClient.image.presignUpload({
        body: {
          fileName,
          contentType: photo.type,
          target: ImageTarget.CARD_ACTION_BACKGROUND,
          hashedFile,
        },
      });

    if (resPresignImage.status == 201) {
      return resPresignImage.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resPresignImage;
  };

  presignCardInfoBackgroundImage = async (
    photo: File,
    dimensions: { width: number; height: number },
  ): Promise<UploadPresignResponseDto> => {
    const fileName = photo.name;
    const hashedFile = await hashFile(photo);
    const resPresignImage =
      await this.tokenInterceptorStore.tsRestClient.image.presignUpload({
        body: {
          fileName,
          contentType: photo.type,
          target: ImageTarget.CARD_INFO_BACKGROUND,
          hashedFile,
          dimentions: dimensions,
        },
      });

    if (resPresignImage.status == 201) {
      return resPresignImage.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resPresignImage;
  };

  presignCardHtmlBundle = async (
    file: File,
  ): Promise<UploadPresignResponseDto> => {
    const fileName = file.name;
    const hashedFile = await hashFile(file);
    const resPresignImage =
      await this.tokenInterceptorStore.tsRestClient.image.presignUpload({
        body: {
          fileName,
          contentType: file.type,
          target: ImageTarget.CARD_INFO_HTML_BUNDLE,
          hashedFile,
        },
      });

    if (resPresignImage.status == 201) {
      return resPresignImage.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw resPresignImage;
  };

  deletePresignUpload = async (
    uploadToken: string,
    reason: string,
  ): Promise<AxiosResponse> => {
    const res = await this.tokenInterceptorStore.call({
      method: "DELETE",
      url: `${getAPIBaseUrl()}/images/presign`,
      data: {
        uploadToken,
        reason,
      },
    });

    return res;
  };

  uploadToS3 = async (url: string, photo: File): Promise<Response> => {
    const hashedFile = await hashFile(photo);
    const resUploadToS3 =
      await this.tokenInterceptorStore.callWithoutExtraConfigs({
        method: "PUT",
        headers: {
          "Content-Type": photo.type,
          "Content-Encoding": "utf8",
          "Content-MD5": hashedFile,
        },
        data: photo,
        url,
      });
    return resUploadToS3;
  };

  onEditCardAction = async (
    cardId: string,
    payload: UpdateCardActionPayload,
  ): Promise<CardAction> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      method: "PUT",
      data: { data: { ...payload, state: payload.state?.toUpperCase() } },
    });
    return {
      ...res.data.data,
      state: res.data.data.state?.toLocaleLowerCase(),
      size: res.data.data.size?.toLowerCase() as CardSize,
    };
  };

  onEditCardInfo = async (
    cardId: string,
    payload: UpdateCardInfoPayload,
  ): Promise<CardInfo> => {
    const res = await this.tokenInterceptorStore.call({
      url: `${getAPIBaseUrl()}/cards/${cardId}`,
      method: "PUT",
      data: { data: { ...payload, state: payload.state?.toUpperCase() } },
    });
    const card = res.data.data;
    return {
      ...card,
      size: card.size.toLowerCase(),
      state: card.state.toLowerCase(),
    };
  };

  saveCardPromotion = async (
    promotionId: string,
    state: typeof CardStatus.DRAFTING | typeof CardStatus.PUBLISHED,
    deckId?: string,
  ): Promise<CardCreateResponseDto> => {
    const res = await this.tokenInterceptorStore.tsRestClient.card.createCard({
      body: {
        data: {
          deckId,
          state,
          content: {
            type: CardCreationType.PROMOTION_REPOST_BY_PROMOTION,
            promotionId,
          },
        },
      },
    });

    if (res.status === 201) {
      return res.body;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };

  createCardEditable = async (
    deckId: string | undefined,
    state: CardStatus,
    content: CardEditableContent,
  ): Promise<CardEditableResponseDto> => {
    const res = await this.tokenInterceptorStore.tsRestClient.card.createCard({
      body: {
        data: {
          deckId,
          state,
          content,
        },
      },
    });

    if (res.status === 201 && res.body.data.contentType === CardType.EDITABLE) {
      return res.body.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };

  createCardEditableRepost = async (
    deckId: string | undefined,
    state: CardStatus,
    content: CardEditableRepostContent,
  ): Promise<CardItemEditableRepostResponseDto> => {
    const res = await this.tokenInterceptorStore.tsRestClient.card.createCard({
      body: {
        data: {
          deckId,
          state,
          content,
        },
      },
    });

    if (
      res.status === 201 &&
      res.body.data.contentType === CardType.EDITABLE_REPOST
    ) {
      return res.body.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };

  updateCardEditableRepost = async (
    cardId: string,
    deckId: string | undefined,
    state: CardStatus,
  ): Promise<CardItemEditableRepostResponseDto> => {
    const res = await this.tokenInterceptorStore.tsRestClient.card.updateCard({
      params: {
        cardId,
      },
      body: {
        data: {
          deckId,
          state,
          content: {
            type: CardType.EDITABLE_REPOST,
          },
        },
      },
    });

    if (
      res.status === 200 &&
      "data" in res.body &&
      "rootSourceCard" in res.body.data &&
      res.body.data.contentType === CardType.EDITABLE_REPOST
    ) {
      return res.body.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };

  updateCardEditable = async (
    cardId: string,
    payload: CardEditableUpdatePayloadDto,
  ): Promise<CardEditableResponseDto> => {
    const res = await this.tokenInterceptorStore.tsRestClient.card.updateCard({
      params: {
        cardId,
      },
      body: payload,
    });

    if (
      res.status === 200 &&
      "data" in res.body &&
      "cardOpenSections" in res.body.data &&
      res.body.data.contentType === CardType.EDITABLE
    ) {
      return res.body.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw res;
  };
}
